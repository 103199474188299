import { gql } from '@apollo/client';

export const INGREDIENTS_SUBSCRIPTION = gql`
    subscription ingredientsSubscription {
        ingredientsSubscription
    }
`;

export const INGREDIENTS = gql`
    query ingredients($search: String, $languageId: Int) {
        ingredients(
            search: $search
            languageId: $languageId
        ) {
            id
            units
            translations {
                title
                language {
                    id
                    title
                    languageCode
                }
            }
        }
    }
`;

export const INGREDIENT = gql`
    query ingredient($id: Int!) {
        ingredient(id: $id) {
            id
            units
            translations {
                title
                language {
                    id
                    title
                    languageCode
                }
            }
            conversions {
                fromUnit
                toUnit
                multiplier
            }
        }
    }
`;

export const ADD_INGREDIENT = gql`
    mutation addIngredient(
        $units: [IngredientUnitEnum!]!
        $conversions: [IngredientConversionInput!]!
        $translations: [IngredientTranslationInput!]!
    ) {
        addIngredient(
            units: $units
            conversions: $conversions
            translations: $translations
        ) {
            id
        }
    }
`;

export const UPDATE_INGREDIENT = gql`
    mutation updateIngredient(
        $id: Int!
        $units: [IngredientUnitEnum!]!
        $conversions: [IngredientConversionInput!]
        $translations: [IngredientTranslationInput!]
    ) {
        updateIngredient(
            id: $id
            units: $units
            conversions: $conversions
            translations: $translations
        ) {
            id
        }
    }
`;

export const DELETE_INGREDIENT = gql`
    mutation deleteIngredient($id: Int!) {
        deleteIngredient(id: $id) {
            id
        }
    }
`;
