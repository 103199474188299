import { gql } from '@apollo/client';

export const PRODUCTS_SUBSCRIPTION = gql`
    subscription productsSubscription($storeId: Int) {
        productsSubscription(storeId: $storeId)
    }
`;

export const PRODUCTS = gql`
    query storeProducts(
        $storeId: Int!
        $page: Int
        $limit: Int
        $filter: String
        $languageId: Int
    ) {
        storeProducts(
            storeId: $storeId
            page: $page
            limit: $limit
            filter: $filter
            languageId: $languageId
        ) {
            count
            products {
                id
                translations {
                    title
                    language {
                        id
                        languageCode
                    }
                }
                categories {
                    id
                    translations {
                        id
                        language {
                            id
                            languageCode
                        }
                        title
                    }
                }
            }
        }
    }
`;

export const GET_PRODUCT = gql`
    query productDetail($id: Int!) {
        productDetail(id: $id) {
            id
            available
            unit
            min
            max
            price
            priceWithoutVAT
            iconPath
            categories {
                id
                translations {
                    id
                    language {
                        id
                    }
                    title
                }
            }
            translations {
                title
                description
                allergens
                language {
                    id
                    title
                    languageCode
                }
            }
        amount
        energyPerServing
        energyPerUnit
        fatsPerServing
        fatsPerUnit
        ingredient {
            id
            translations {
            language {
                id
                languageCode
            }
            title
            }
        }
        proteinPerServing
        proteinPerUnit
        saccharidesPerServing
        saccharidesPerUnit
        saltsPerServing
        saltsPerUnit
        saturatedFatsPerServing
        saturatedFatsPerUnit
        sugarsPerServing
        sugarsPerUnit
        unitsPerServing
        unitsPerStandardAmount
        }
    }
`;

export const ADD_PRODUCT = gql`
    mutation addProduct(
        $storeId: Int
        $ingredientId: Int
        $available: Boolean
        $unit: UnitEnum
        $min: Float
        $max: Float
        $price: Float!
        $priceWithoutVAT: Float!
        $amount: Float!
        $icon: String

        $unitsPerServing: String
        $energyPerServing: String
        $fatsPerServing: String
        $saturatedFatsPerServing: String
        $saccharidesPerServing: String
        $sugarsPerServing: String
        $proteinPerServing: String
        $saltsPerServing: String

        $unitsPerStandardAmount: String
        $energyPerUnit: String
        $fatsPerUnit: String
        $saturatedFatsPerUnit: String
        $saccharidesPerUnit: String
        $sugarsPerUnit: String
        $proteinPerUnit: String
        $saltsPerUnit: String

        $categories: [Int!]!
        $translations: [ProductTranslationInput!]!
    ) {
        addProduct(
            categories: $categories
            price: $price
            priceWithoutVAT: $priceWithoutVat
            storeId: $storeId
            available: $available
            unit: $unit
            min: $min
            max: $max
            icon: $icon
            translations: $translations
            ingredientId: $ingredientId
            amount: $amount
            unitsPerServing: $unitsPerServing
            energyPerServing: $energyPerServing
            fatsPerServing: $fatsPerServing
            saturatedFatsPerServing: $saturatedFatsPerServing
            saccharidesPerServing: $saccharidesPerServing
            sugarsPerServing: $sugarsPerServing
            proteinPerServing: $proteinPerServing
            saltsPerServing: $saltsPerServing
            unitsPerStandardAmount: $unitsPerStandardAmount
            energyPerUnit: $energyPerUnit
            fatsPerUnit: $fatsPerUnit
            saturatedFatsPerUnit: $saturatedFatsPerUnit
            saccharidesPerUnit: $saccharidesPerUnit
            sugarsPerUnit: $sugarsPerUnit
            proteinPerUnit: $proteinPerUnit
            saltsPerUnit: $saltsPerUnit
        ) {
            id
        }
    }
`;

export const UPDATE_PRODUCT = gql`
    mutation updateProduct(
        $id: Int!
        $ingredientId: Int
        $available: Boolean
        $unit: UnitEnum
        $min: Float
        $max: Float
        $price: Float
        $priceWithoutVAT: Float
        $amount: Float
        $icon: String   

        $unitsPerServing: String
        $energyPerServing: String
        $fatsPerServing: String
        $saturatedFatsPerServing: String
        $saccharidesPerServing: String
        $sugarsPerServing: String
        $proteinPerServing: String
        $saltsPerServing: String    

        $unitsPerStandardAmount: String
        $energyPerUnit: String
        $fatsPerUnit: String
        $saturatedFatsPerUnit: String
        $saccharidesPerUnit: String
        $sugarsPerUnit: String
        $proteinPerUnit: String
        $saltsPerUnit: String   

        $categories: [Int!]
        $translations: [ProductTranslationInput!]
    ) {
        updateProduct(
            id: $id
            categories: $categories
            available: $available
            unit: $unit
            min: $min
            max: $max
            price: $price
            priceWithoutVAT: $priceWithoutVat
            icon: $icon
            translations: $translations
            ingredientId: $ingredientId
            amount: $amount
            unitsPerServing: $unitsPerServing
            energyPerServing: $energyPerServing
            fatsPerServing: $fatsPerServing
            saturatedFatsPerServing: $saturatedFatsPerServing
            saccharidesPerServing: $saccharidesPerServing
            sugarsPerServing: $sugarsPerServing
            proteinPerServing: $proteinPerServing
            saltsPerServing: $saltsPerServing
            unitsPerStandardAmount: $unitsPerStandardAmount
            energyPerUnit: $energyPerUnit
            fatsPerUnit: $fatsPerUnit
            saturatedFatsPerUnit: $saturatedFatsPerUnit
            saccharidesPerUnit: $saccharidesPerUnit
            sugarsPerUnit: $sugarsPerUnit
            proteinPerUnit: $proteinPerUnit
            saltsPerUnit: $saltsPerUnit
        ) {
            id
        }
    }
`;

export const DELETE_PRODUCT = gql`
    mutation deleteProduct($id: Int!) {
        deleteProduct(id: $id) {
            id
        }
    }
`;
