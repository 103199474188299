import LocationInput from 'components/locationInput';
import React, { useState } from 'react';
import { Table, Input, Button, Row, Col } from 'reactstrap';

let fakeID = -1;

export default function ShopAddresses(props) {
    const {
        addresses,
        onCreateAddress,
        onUpdateAddress,
        onRemoveAddress,
    } = props;

    const [addAddress, setAddAddress] = useState(false);
    const [newAddress, setNewAddress] = useState({
        id: fakeID--,
        current: true,
        address: '',
        latitude: null,
        longitude: null,
        title: '',
    });

    const [editedAddresses, setEditedAddresses] = useState(
        []
    );

    const editedAddressesIds = editedAddresses.map(
        (address) => address.id
    );

    return (
        <>
            <Table hover>
                <thead>
                    <tr>
                        <th width={'5%'}>Predvolená</th>
                        <th width={'35%'}>Názov</th>
                        <th width={'35%'}>Adresa</th>
                        <th width={'25%'}>Akcia</th>
                    </tr>
                </thead>
                <tbody>
                    {!addAddress && (
                        <tr key="add-address-btn">
                            <td
                                colSpan={4}
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    setAddAddress(true);
                                }}
                            >
                                + Adresa
                            </td>
                        </tr>
                    )}
                    {addAddress && (
                        <tr key="add-address-row">
                            <td>
                                <Input
                                    id="add-address-current"
                                    type="checkbox"
                                    value={
                                        newAddress.current
                                    }
                                    onChange={(e) => {
                                        setNewAddress({
                                            ...newAddress,
                                            current:
                                                !newAddress.current,
                                        });
                                    }}
                                />
                            </td>
                            <td>
                                <Input
                                    id="add-address-custom-name"
                                    placeholder="Názov adresy"
                                    value={newAddress.title}
                                    onChange={(e) => {
                                        setNewAddress({
                                            ...newAddress,
                                            title: e.target
                                                .value,
                                        });
                                    }}
                                />
                            </td>
                            <td>
                                <LocationInput
                                    location={newAddress}
                                    setLocation={(data) => {
                                        setNewAddress({
                                            ...newAddress,
                                            ...data,
                                        });
                                    }}
                                />
                            </td>
                            <td>
                                <Row xs="2">
                                    <Col>
                                        <Button
                                            color="primary"
                                            block
                                            disabled={
                                                newAddress
                                                    .address
                                                    .length ===
                                                    0 ||
                                                !newAddress.latitude ||
                                                !newAddress.longitude
                                            }
                                            onClick={() => {
                                                onCreateAddress(
                                                    newAddress
                                                );
                                                setNewAddress(
                                                    {
                                                        id: fakeID--,
                                                        title: '',
                                                        current: true,
                                                        address:
                                                            '',
                                                        latitude:
                                                            null,
                                                        longitude:
                                                            null,
                                                    }
                                                );
                                                setAddAddress(
                                                    false
                                                );
                                            }}
                                        >
                                            Pridať
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button
                                            color="secondary"
                                            block
                                            onClick={() => {
                                                setNewAddress(
                                                    {
                                                        id: fakeID,
                                                        title: '',
                                                        current: true,
                                                        address:
                                                            '',
                                                        latitude:
                                                            null,
                                                        longitude:
                                                            null,
                                                    }
                                                );
                                                setAddAddress(
                                                    false
                                                );
                                            }}
                                        >
                                            Zrušiť
                                        </Button>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                    )}

                    {addresses
                        .sort((c1, c2) =>
                            c1.title > c2.title ? -1 : 1
                        )
                        .map((address) => {
                            const isBeingEdited =
                                editedAddressesIds.includes(
                                    address.id
                                );
                            return (
                                <tr
                                    key={address.id}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                >
                                    <td>
                                        <Input
                                            id={`address-current-${address.id}`}
                                            type="checkbox"
                                            disabled={
                                                !isBeingEdited ||
                                                address.current
                                            }
                                            checked={
                                                isBeingEdited
                                                    ? editedAddresses.find(
                                                          (
                                                              a
                                                          ) =>
                                                              a.id ===
                                                              address.id
                                                      )
                                                          .current
                                                    : address.current
                                            }
                                            onChange={() => {
                                                if (
                                                    !address.current
                                                ) {
                                                    let editedAddress =
                                                        editedAddresses.find(
                                                            (
                                                                a
                                                            ) =>
                                                                a.id ===
                                                                address.id
                                                        );

                                                    setEditedAddresses(
                                                        [
                                                            ...editedAddresses,
                                                        ].map(
                                                            (
                                                                a
                                                            ) =>
                                                                a.id ===
                                                                address.id
                                                                    ? {
                                                                          ...editedAddress,
                                                                          current: true,
                                                                      }
                                                                    : a
                                                        )
                                                    );
                                                }
                                            }}
                                        />
                                    </td>
                                    {isBeingEdited && (
                                        <td>
                                            <Input
                                                id={`address-custom-name-${address.id}`}
                                                placeholder="Názov adresy"
                                                value={
                                                    editedAddresses.find(
                                                        (
                                                            a
                                                        ) =>
                                                            a.id ===
                                                            address.id
                                                    ).title
                                                }
                                                onChange={(
                                                    e
                                                ) => {
                                                    let editedAddress =
                                                        editedAddresses.find(
                                                            (
                                                                a
                                                            ) =>
                                                                a.id ===
                                                                address.id
                                                        );

                                                    setEditedAddresses(
                                                        [
                                                            ...editedAddresses,
                                                        ].map(
                                                            (
                                                                a
                                                            ) =>
                                                                a.id ===
                                                                address.id
                                                                    ? {
                                                                          ...editedAddress,
                                                                          title: e
                                                                              .target
                                                                              .value,
                                                                      }
                                                                    : a
                                                        )
                                                    );
                                                }}
                                            />
                                        </td>
                                    )}
                                    {!isBeingEdited && (
                                        <td>
                                            {address.title}
                                        </td>
                                    )}
                                    {isBeingEdited && (
                                        <td>
                                            <LocationInput
                                                showAddress={
                                                    true
                                                }
                                                location={
                                                    address
                                                }
                                                setLocation={(
                                                    data
                                                ) => {
                                                    let editedAddress =
                                                        editedAddresses.find(
                                                            (
                                                                a
                                                            ) =>
                                                                a.id ===
                                                                address.id
                                                        );

                                                    setEditedAddresses(
                                                        [
                                                            ...editedAddresses,
                                                        ].map(
                                                            (
                                                                a
                                                            ) =>
                                                                a.id ===
                                                                address.id
                                                                    ? {
                                                                          ...editedAddress,
                                                                          ...data,
                                                                      }
                                                                    : a
                                                        )
                                                    );
                                                }}
                                            />
                                        </td>
                                    )}
                                    {!isBeingEdited && (
                                        <td>
                                            {address.latitude
                                                ? `${address.address} ${address.latitude} ${address.longitude}`
                                                : 'Bez adresy'}
                                        </td>
                                    )}
                                    <td>
                                        <Row xs="2">
                                            <Col>
                                                <Button
                                                    color={
                                                        editedAddressesIds.includes(
                                                            address.id
                                                        )
                                                            ? 'primary'
                                                            : 'warning'
                                                    }
                                                    block
                                                    onClick={() => {
                                                        if (
                                                            editedAddressesIds.includes(
                                                                address.id
                                                            )
                                                        ) {
                                                            onUpdateAddress(
                                                                editedAddresses.find(
                                                                    (
                                                                        c
                                                                    ) =>
                                                                        c.id ===
                                                                        address.id
                                                                )
                                                            );
                                                            setEditedAddresses(
                                                                [
                                                                    ...editedAddresses,
                                                                ].filter(
                                                                    (
                                                                        c
                                                                    ) =>
                                                                        c.id !==
                                                                        address.id
                                                                )
                                                            );
                                                        } else {
                                                            setEditedAddresses(
                                                                [
                                                                    ...editedAddresses,
                                                                    address,
                                                                ]
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {editedAddressesIds.includes(
                                                        address.id
                                                    )
                                                        ? 'Uložiť'
                                                        : 'Upraviť'}
                                                </Button>
                                            </Col>
                                            <Col>
                                                {editedAddressesIds.includes(
                                                    address.id
                                                ) && (
                                                    <Button
                                                        color="secondary"
                                                        block
                                                        onClick={() => {
                                                            setEditedAddresses(
                                                                [
                                                                    ...editedAddresses,
                                                                ].filter(
                                                                    (
                                                                        c
                                                                    ) =>
                                                                        c.id !==
                                                                        address.id
                                                                )
                                                            );
                                                        }}
                                                    >
                                                        Zrušiť
                                                    </Button>
                                                )}
                                                {addresses.length >
                                                    0 &&
                                                    !editedAddressesIds.includes(
                                                        address.id
                                                    ) && (
                                                        <Button
                                                            color="danger"
                                                            block
                                                            onClick={() => {
                                                                if (
                                                                    window.confirm(
                                                                        'Are you sure?'
                                                                    )
                                                                ) {
                                                                    onRemoveAddress(
                                                                        address.id
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            Odstrániť
                                                        </Button>
                                                    )}
                                            </Col>
                                        </Row>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </Table>
        </>
    );
}
