import { gql } from '@apollo/client';

export const LANGUAGES_SUBSCRIPTION = gql`
    subscription languagesSubscription {
        languagesSubscription
    }
`;

export const GET_LANGUAGES = gql`
    query {
        languages {
            id
            languageCode
            title
            fixed
            energy
            fats
            protein
            saccharides
            salts
            saturatedFats
            serving
            sugars
        }
    }
`;

export const ADD_LANGUAGE = gql`
    mutation addLanguage(
        $title: String!
        $languageCode: String!
        $fixed: Boolean

        $serving: String
        $energy: String
        $fats: String
        $saturatedFats: String
        $saccharides: String
        $sugars: String
        $protein: String
        $salts: String
    ) {
        addLanguage(
            title: $title
            fixed: $fixed
            languageCode: $languageCode
            serving: $serving
            energy: $energy
            fats: $fats
            saturatedFats: $saturatedFats
            saccharides: $saccharides
            sugars: $sugars
            protein: $protein
            salts: $salts
        ) {
            id
        }
    }
`;

export const UPDATE_LANGUAGE = gql`
    mutation updateLanguage(
        $id: Int!
        $title: String
        $languageCode: String
        $fixed: Boolean

        $serving: String
        $energy: String
        $fats: String
        $saturatedFats: String
        $saccharides: String
        $sugars: String
        $protein: String
        $salts: String
    ) {
        updateLanguage(
        id: $id, 
        title: $title, 
        fixed: $fixed,
        languageCode: $languageCode, 
        serving: $serving, 
        energy: $energy, 
        fats: $fats, 
        saturatedFats: $saturatedFats, 
        saccharides: $saccharides, 
        sugars: $sugars, 
        protein: $protein, salts: $salts
        ) {
        id
        }
    }
`

export const DELETE_LANGUAGE = gql`
    mutation deleteLanguage($id: Int!) {
        deleteLanguage(id: $id) {
            id
        }
    }
`;
