import { gql } from '@apollo/client';

export const NEW_INGREDIENTS_SUBSCRIPTION = gql`
    subscription ingredientsSubscription {
        ingredientsSubscription
    }
`;

export const NEW_INGREDIENTS = gql`
    query ingredients($search: String, $languageId: Int) {
        ingredients(
            search: $search
            languageId: $languageId
        ) {
            id
            units
            translations {
                title
                language {
                    id
                    title
                    languageCode
                }
            }
        }
    }
`;

export const NEW_INGREDIENT = gql`
    query ingredient($ingredientId: Int!) {
        ingredient(id: $ingredientId) {
            id
            units
            translations {
                title
                language {
                    id
                    title
                    languageCode
                }
            }
            conversions {
                fromUnit
                toUnit
                multiplier
            }
        }
    }
`;

export const ADD_NEW_INGREDIENT = gql`
    mutation addIngredient(
        $units: [IngredientUnitEnum]!
        $conversions: [IngredientConversionInput]!
        $translations: [IngredientTranslationInput]!
    ) {
        addIngredient(
            units: $units
            conversions: $conversions
            translations: $translations
        ) {
            id
        }
    }
`;

export const UPDATE_NEW_INGREDIENT = gql`
    mutation updateIngredient(
        $updateIngredientId: Int!
        $units: [IngredientUnitEnum]!
        $conversions: [IngredientConversionInput]
        $translations: [IngredientTranslationInput]
    ) {
        updateIngredient(
            id: $updateIngredientId
            units: $units
            conversions: $conversions
            translations: $translations
        ) {
            id
        }
    }
`;

export const DELETE_NEW_INGREDIENT = gql`
    mutation deleteIngredient($deleteIngredientId: Int!) {
        deleteIngredient(id: $deleteIngredientId) {
            id
        }
    }
`;
