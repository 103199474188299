import React, { useState, useEffect } from 'react';
import {
    useMutation,
    useQuery,
    useSubscription,
} from '@apollo/client';
import {
    LANGUAGES_SUBSCRIPTION,
    GET_LANGUAGES,
    ADD_LANGUAGE,
    DELETE_LANGUAGE,
    UPDATE_LANGUAGE,
} from 'queries';
import {
    FormGroup,
    Label,
    Form,
    Input,
    Button,
    Card,
    Row,
    Table,
    Spinner,
} from 'reactstrap';
import Select from 'react-select';
import languages from 'configs/languages';

export default function Languages() {
    const [addLanguage] = useMutation(ADD_LANGUAGE);
    const [updateLanguage] = useMutation(UPDATE_LANGUAGE);
    const [deleteLanguage] = useMutation(DELETE_LANGUAGE);

    const {
        data: languagesData,
        loading: languagesLoading,
        refetch: languagesRefetch,
    } = useQuery(GET_LANGUAGES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(LANGUAGES_SUBSCRIPTION, {
        onData: () => {
            languagesRefetch();
        },
    });

    const [chosenLanguages, setChosenLanguages] = useState(
        []
    );

    useEffect(() => {
        if (languagesData) {
            const newChosenLanguages =
                languagesData.languages.map((language) => ({
                    ...language,
                    ...languages.find(
                        (lang) =>
                            language.languageCode ===
                            lang.value
                    ),
                }));
            setChosenLanguages(newChosenLanguages);
        }
    }, [languagesData]);

    const addLanguageFunc = (language) => {
        addLanguage({
            variables: {
                title: language.label,
                languageCode: language.value,
                fixed: false,
            },
        }).then((response) => {
        });
    };

    const updateLanguageFunc = (language) => {
        updateLanguage({
            variables: {
                id: language.id,
                fixed: !language.fixed,
            },
        }).then((response) => {
        });
    };

    const deleteLanguageFunc = (language) => {
        deleteLanguage({
            variables: {
                id: language.id,
            },
        }).then((response) => {
        });
    };

    if (languagesLoading) {
        return (
            <Card style={{ padding: '1em' }}>
                <Row>
                    <Spinner color="primary" />
                </Row>
            </Card>
        );
    }

    return (
        <Card style={{ padding: '1em' }}>
            <Row>
                <Form>
                    <FormGroup>
                        <Label htmlFor="supported-languages">
                            Jazyk
                        </Label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={false}
                            isSearchable={true}
                            name="supported-languages"
                            options={languages.filter(
                                (language) =>
                                    !chosenLanguages.find(
                                        (lang) =>
                                            lang.value ===
                                            language.value
                                    )
                            )}
                            value={null}
                            onChange={(option) => {
                                if (option) {
                                    addLanguageFunc(option);
                                }
                            }}
                        />
                    </FormGroup>
                </Form>
            </Row>

            <Row>
                <Table>
                    <thead>
                        <tr>
                            <th>Hlavný</th>
                            <th>Jazyk</th>
                            <th>Kód (ISO 639-1)</th>
                            <th width="10%">Akcia</th>
                        </tr>
                    </thead>
                    <tbody>
                        {chosenLanguages
                            .sort((lang1, lang2) => {
                                return lang1.label >
                                    lang2.label
                                    ? 1
                                    : -1;
                            })
                            .map((language) => (
                                <tr key={language.value}>
                                    <td>
                                        <FormGroup check>
                                            <Input
                                                id={`${language.value}-fixed`}
                                                type="checkbox"
                                                checked={language.fixed}
                                                onChange={() => {
                                                    if (window.confirm("Ste si istý?")) {
                                                        updateLanguageFunc(language)
                                                    }
                                                }}
                                            />
                                            <Label
                                                check
                                                htmlFor={`${language.value}-fixed`}
                                            >

                                            </Label>
                                        </FormGroup>
                                    </td>
                                    <td>
                                        {`${language.label} (${language.native})`}
                                    </td>
                                    <td>
                                        {`${language.value}`}
                                    </td>
                                    <td>
                                        {!language.fixed && (
                                            <Button
                                                color="danger"
                                                onClick={() => {
                                                    if (
                                                        window.confirm(
                                                            'Ste si istý?'
                                                        )
                                                    ) {
                                                        deleteLanguageFunc(
                                                            language
                                                        );
                                                    }
                                                }}
                                            >
                                                Odstrániť
                                            </Button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </Row>
        </Card>
    );
}
