import React from 'react';
import {
    Form,
    FormGroup,
    Label,
    Input,
    InputGroup,
    Button,
} from 'reactstrap';
import Select from 'react-select';
import ImageInput from 'components/imageInput';
import { isEmail } from 'utils';
import { MaterialSymbol } from 'react-material-symbols';

export default function CustomerProfile(props) {
    const {
        customerID,
        active,
        setActive,
        changeUserActiveFunc,
        name,
        setName,
        surname,
        setSurname,
        phoneNumber,
        setPhoneNumber,
        siteLanguage,
        setSiteLanguage,
        notificationsThroughEmail,
        setNotificationsThroughEmail,
        notificationsThroughSms,
        setNotificationsThroughSms,
        iconPath,
        setIconPath,
        image,
        setImage,
        siteLanguagesOptions,
        email,
        setEmail,
        wrongEmailFormat,
        setWrongEmailFormat,
        password,
        setPassword,
        password2,
        setPassword2,
        password1Visible,
        setPassword1Visible,
        password2Visible,
        setPassword2Visible,
        passwordErrors,
        setPasswordErrors,
    } = props;

    const checkPasswords = (calledFromFirstPassword) => {
        if (password.length + password2.length === 0) {
            return;
        }
        let newErrors = [];
        if (
            (!calledFromFirstPassword &&
                password !== password2) ||
            (password !== password2 && password2.length > 0)
        ) {
            newErrors.push('Heslá nie sú rovnaké!');
        }
        if (password.length < 6) {
            newErrors.push(
                'Heslo musí mať aspoň 6 znakov!'
            );
        }
        setPasswordErrors(newErrors);
    };

    return (
        <Form>
            <ImageInput
                iconPath={iconPath}
                setIconPath={setIconPath}
                image={image}
                setImage={setImage}
                size={'image'}
            />

            {customerID === 'add' && (
                <FormGroup check>
                    <Input
                        id="customer-active"
                        type="checkbox"
                        checked={active}
                        onChange={() => {
                            setActive(!active);
                            changeUserActiveFunc();
                        }}
                    />
                    <Label check htmlFor="customer-active">
                        Aktívny
                    </Label>
                </FormGroup>
            )}

            <FormGroup>
                <Label htmlFor="customer-title">Meno</Label>
                <Input
                    id="customer-title"
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value);
                    }}
                />
            </FormGroup>

            <FormGroup>
                <Label htmlFor="customer-surname">
                    Priezvisko
                </Label>
                <Input
                    id="customer-surname"
                    value={surname}
                    onChange={(e) => {
                        setSurname(e.target.value);
                    }}
                />
            </FormGroup>

            {customerID !== 'add' && (
                <FormGroup>
                    <Label htmlFor="customer-email">
                        Email
                    </Label>
                    <Input
                        id="customer-email"
                        invalid={wrongEmailFormat}
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setWrongEmailFormat(false);
                        }}
                        onBlur={() => {
                            if (!isEmail(email)) {
                                setWrongEmailFormat(true);
                            }
                        }}
                    />
                </FormGroup>
            )}
            {wrongEmailFormat && (
                <p>{'Email má nesprávny tvar!'}</p>
            )}

            {customerID !== 'add' && (
                <FormGroup>
                    <Label htmlFor="admin-pass">
                        Heslo
                    </Label>
                    <InputGroup>
                        <Input
                            id="admin-pass"
                            invalid={
                                passwordErrors.length > 0
                            }
                            value={password}
                            type={
                                password1Visible
                                    ? 'text'
                                    : 'password'
                            }
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            onBlur={() =>
                                checkPasswords(true)
                            }
                        />
                        <Button
                            onClick={() => {
                                setPassword1Visible(
                                    !password1Visible
                                );
                            }}
                        >
                            <MaterialSymbol
                                icon={
                                    password1Visible
                                        ? 'visibility_off'
                                        : 'visibility'
                                }
                                size={24}
                                fill
                                grade={-25}
                                color="white"
                            />
                        </Button>
                    </InputGroup>
                </FormGroup>
            )}
            {customerID !== 'add' && (
                <FormGroup>
                    <Label htmlFor="admin-pass2">
                        Zopakujte heslo
                    </Label>
                    <InputGroup>
                        <Input
                            id="admin-pass2"
                            invalid={
                                passwordErrors.length > 0
                            }
                            value={password2}
                            type={
                                password2Visible
                                    ? 'text'
                                    : 'password'
                            }
                            onChange={(e) => {
                                setPassword2(
                                    e.target.value
                                );
                            }}
                            onBlur={() => {
                                checkPasswords();
                            }}
                        />
                        <Button
                            onClick={() => {
                                setPassword2Visible(
                                    !password2Visible
                                );
                            }}
                        >
                            <MaterialSymbol
                                icon={
                                    password2Visible
                                        ? 'visibility_off'
                                        : 'visibility'
                                }
                                size={24}
                                fill
                                grade={-25}
                                color="white"
                            />
                        </Button>
                    </InputGroup>
                </FormGroup>
            )}

            {customerID !== 'add' &&
                passwordErrors.map((error, index) => (
                    <p key={index}>{error}</p>
                ))}

            <FormGroup>
                <Label htmlFor="customer-phone-number">
                    Mobilné číslo
                </Label>
                <Input
                    id="customer-phone-number"
                    value={phoneNumber}
                    onChange={(e) => {
                        setPhoneNumber(e.target.value);
                    }}
                />
            </FormGroup>

            <FormGroup>
                <Label htmlFor="customer-language">
                    Jazyk stránky
                </Label>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={false}
                    isSearchable={true}
                    name="customer-language"
                    options={siteLanguagesOptions}
                    value={siteLanguage}
                    onChange={(language) => {
                        setSiteLanguage(language);
                    }}
                />
            </FormGroup>

            <FormGroup check>
                <Input
                    id="customer-notificationsThroughEmail"
                    type="checkbox"
                    checked={notificationsThroughEmail}
                    onChange={() => {
                        setNotificationsThroughEmail(
                            !notificationsThroughEmail
                        );
                    }}
                />
                <Label
                    check
                    htmlFor="customer-notificationsThroughEmail"
                >
                    Dostávať notifikácie emailom
                </Label>
            </FormGroup>

            <FormGroup check>
                <Input
                    id="customer-notificationsThroughSms"
                    type="checkbox"
                    checked={notificationsThroughSms}
                    onChange={() => {
                        setNotificationsThroughSms(
                            !notificationsThroughSms
                        );
                    }}
                />
                <Label
                    check
                    htmlFor="customer-notificationsThroughSms"
                >
                    Dostávať notifikácie smskou
                </Label>
            </FormGroup>
        </Form>
    );
}
