import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_TRENDING_GROUP } from 'queries';
import TrendingGroupForm from './form';

export default function TrendingGroupLoader(props) {
    const { trendingGroupId } = props;

    const {
        data: trendingGroupData,
        loading: trendingGroupLoading,
    } = useQuery(GET_TRENDING_GROUP, {
        variables: {
            id: trendingGroupId,
        },
        fetchPolicy: 'network-only',
    });

    return (
        <TrendingGroupForm
            {...props}
            trendingGroupData={trendingGroupData}
            trendingGroupLoading={trendingGroupLoading}
        />
    );
}
