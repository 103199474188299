import React, { useState, useEffect } from 'react';
import {
    useMutation,
    useQuery,
    useSubscription,
} from '@apollo/client';
import {
    DISTANCE_PRICING_SUBSCRIPTION,
    GET_DISTANCE_PRICING,
    UPDATE_DISTANCE_PRICING,
} from 'queries';
import {
    Input,
    Button,
    Card,
    Row,
    Col,
    Table,
    Spinner,
} from 'reactstrap';

export default function DistancePricing() {
    const [updateDistancePricing] = useMutation(
        UPDATE_DISTANCE_PRICING
    );

    const {
        data: distancePricingData,
        loading: distancePricingLoading,
        refetch: distancePricingRefetch,
    } = useQuery(GET_DISTANCE_PRICING, {
        fetchPolicy: 'network-only',
    });

    useSubscription(DISTANCE_PRICING_SUBSCRIPTION, {
        onData: () => {
            distancePricingRefetch();
        },
    });

    const [km1, setKm1] = useState(1);
    const [km2, setKm2] = useState(1);
    const [km3, setKm3] = useState(1);
    const [km4, setKm4] = useState(1);
    const [km5, setKm5] = useState(1);
    const [km6, setKm6] = useState(1);
    const [km7, setKm7] = useState(1);
    const [km8, setKm8] = useState(1);
    const [km9, setKm9] = useState(1);

    const [km1Courier, setKm1Courier] = useState(0.2);
    const [km2Courier, setKm2Courier] = useState(0.2);
    const [km3Courier, setKm3Courier] = useState(0.2);
    const [km4Courier, setKm4Courier] = useState(0.2);
    const [km5Courier, setKm5Courier] = useState(0.2);
    const [km6Courier, setKm6Courier] = useState(0.2);
    const [km7Courier, setKm7Courier] = useState(0.2);
    const [km8Courier, setKm8Courier] = useState(0.2);
    const [km9Courier, setKm9Courier] = useState(0.2);

    const [openEdit, setOpenEdit] = useState(false);

    useEffect(() => {
        if (distancePricingData) {
            setKm1(distancePricingData.distancePricing.km1);
            setKm2(distancePricingData.distancePricing.km2);
            setKm3(distancePricingData.distancePricing.km3);
            setKm4(distancePricingData.distancePricing.km4);
            setKm5(distancePricingData.distancePricing.km5);
            setKm6(distancePricingData.distancePricing.km6);
            setKm7(distancePricingData.distancePricing.km7);
            setKm8(distancePricingData.distancePricing.km8);
            setKm9(distancePricingData.distancePricing.km9);

            setKm1Courier(
                distancePricingData.distancePricing
                    .km1Courier
            );
            setKm2Courier(
                distancePricingData.distancePricing
                    .km2Courier
            );
            setKm3Courier(
                distancePricingData.distancePricing
                    .km3Courier
            );
            setKm4Courier(
                distancePricingData.distancePricing
                    .km4Courier
            );
            setKm5Courier(
                distancePricingData.distancePricing
                    .km5Courier
            );
            setKm6Courier(
                distancePricingData.distancePricing
                    .km6Courier
            );
            setKm7Courier(
                distancePricingData.distancePricing
                    .km7Courier
            );
            setKm8Courier(
                distancePricingData.distancePricing
                    .km8Courier
            );
            setKm9Courier(
                distancePricingData.distancePricing
                    .km9Courier
            );
        }
    }, [distancePricingData]);

    const updateDistancePricingFunc = () => {
        updateDistancePricing({
            variables: {
                km1: parseFloat(km1),
                km2: parseFloat(km2),
                km3: parseFloat(km3),
                km4: parseFloat(km4),
                km5: parseFloat(km5),
                km6: parseFloat(km6),
                km7: parseFloat(km7),
                km8: parseFloat(km8),
                km9: parseFloat(km9),
                km1Courier: parseFloat(km1Courier),
                km2Courier: parseFloat(km2Courier),
                km3Courier: parseFloat(km3Courier),
                km4Courier: parseFloat(km4Courier),
                km5Courier: parseFloat(km5Courier),
                km6Courier: parseFloat(km6Courier),
                km7Courier: parseFloat(km7Courier),
                km8Courier: parseFloat(km8Courier),
                km9Courier: parseFloat(km9Courier),
            },
        }).then((response) => {
            setOpenEdit(false);
        });
    };

    const getDistanceValue = (distance, isCourier) => {
        switch (distance) {
            case 1:
                return isCourier ? km1Courier : km1;
            case 2:
                return isCourier ? km2Courier : km2;
            case 3:
                return isCourier ? km3Courier : km3;
            case 4:
                return isCourier ? km4Courier : km4;
            case 5:
                return isCourier ? km5Courier : km5;
            case 6:
                return isCourier ? km6Courier : km6;
            case 7:
                return isCourier ? km7Courier : km7;
            case 8:
                return isCourier ? km8Courier : km8;
            case 9:
                return isCourier ? km9Courier : km9;

            default:
                break;
        }
    };

    const onChangeDistance = (
        value,
        distance,
        isCourier
    ) => {
        let newValue = value;
        if (isNaN(value)) {
            newValue = 0;
        }
        if (parseFloat(newValue) < 0) {
            newValue = newValue * -1;
        }
        switch (distance) {
            case 1:
                if (isCourier) {
                    if (newValue <= km1) {
                        setKm1Courier(newValue);
                    }
                } else {
                    setKm1(newValue);
                }
                break;
            case 2:
                if (isCourier) {
                    if (newValue <= km1) {
                        setKm2Courier(newValue);
                    }
                } else {
                    setKm2(newValue);
                }
                break;
            case 3:
                if (isCourier) {
                    if (newValue <= km1) {
                        setKm3Courier(newValue);
                    }
                } else {
                    setKm3(newValue);
                }
                break;
            case 4:
                if (isCourier) {
                    if (newValue <= km1) {
                        setKm4Courier(newValue);
                    }
                } else {
                    setKm4(newValue);
                }
                break;
            case 5:
                if (isCourier) {
                    if (newValue <= km1) {
                        setKm5Courier(newValue);
                    }
                } else {
                    setKm5(newValue);
                }
                break;
            case 6:
                if (isCourier) {
                    if (newValue <= km1) {
                        setKm6Courier(newValue);
                    }
                } else {
                    setKm6(newValue);
                }
                break;
            case 7:
                if (isCourier) {
                    if (newValue <= km1) {
                        setKm7Courier(newValue);
                    }
                } else {
                    setKm7(newValue);
                }
                break;
            case 8:
                if (isCourier) {
                    if (newValue <= km1) {
                        setKm8Courier(newValue);
                    }
                } else {
                    setKm8(newValue);
                }
                break;
            case 9:
                if (isCourier) {
                    if (newValue <= km1) {
                        setKm9Courier(newValue);
                    }
                } else {
                    setKm9(newValue);
                }
                break;

            default:
                break;
        }
    };

    if (distancePricingLoading) {
        return (
            <Card style={{ padding: '1em' }}>
                <Row>
                    <Spinner color="primary" />
                </Row>
            </Card>
        );
    }

    return (
        <Card style={{ padding: '1em' }}>
            <Row xs={openEdit ? '2' : ''}>
                {!openEdit && (
                    <Col>
                        <Button
                            color="warning"
                            onClick={() => {
                                setOpenEdit(true);
                            }}
                        >
                            Upraviť
                        </Button>
                    </Col>
                )}

                {openEdit && (
                    <Col>
                        <Button
                            onClick={() => {
                                setKm1(
                                    distancePricingData
                                        .distancePricing.km1
                                );
                                setKm2(
                                    distancePricingData
                                        .distancePricing.km2
                                );
                                setKm3(
                                    distancePricingData
                                        .distancePricing.km3
                                );
                                setKm4(
                                    distancePricingData
                                        .distancePricing.km4
                                );
                                setKm5(
                                    distancePricingData
                                        .distancePricing.km5
                                );
                                setKm6(
                                    distancePricingData
                                        .distancePricing.km6
                                );
                                setKm7(
                                    distancePricingData
                                        .distancePricing.km7
                                );
                                setKm8(
                                    distancePricingData
                                        .distancePricing.km8
                                );
                                setKm9(
                                    distancePricingData
                                        .distancePricing.km9
                                );

                                setKm1Courier(
                                    distancePricingData
                                        .distancePricing
                                        .km1Courier
                                );
                                setKm2Courier(
                                    distancePricingData
                                        .distancePricing
                                        .km2Courier
                                );
                                setKm3Courier(
                                    distancePricingData
                                        .distancePricing
                                        .km3Courier
                                );
                                setKm4Courier(
                                    distancePricingData
                                        .distancePricing
                                        .km4Courier
                                );
                                setKm5Courier(
                                    distancePricingData
                                        .distancePricing
                                        .km5Courier
                                );
                                setKm6Courier(
                                    distancePricingData
                                        .distancePricing
                                        .km6Courier
                                );
                                setKm7Courier(
                                    distancePricingData
                                        .distancePricing
                                        .km7Courier
                                );
                                setKm8Courier(
                                    distancePricingData
                                        .distancePricing
                                        .km8Courier
                                );
                                setKm9Courier(
                                    distancePricingData
                                        .distancePricing
                                        .km9Courier
                                );

                                setOpenEdit(false);
                            }}
                        >
                            Zrušiť
                        </Button>
                    </Col>
                )}

                {openEdit && (
                    <Col className="flex jc-flex-end">
                        <Button
                            color="primary"
                            onClick={() => {
                                updateDistancePricingFunc();
                            }}
                        >
                            Uložiť
                        </Button>
                    </Col>
                )}
            </Row>
            <Row>
                <Table>
                    <thead>
                        <tr>
                            <th width="33%">Vzdialenosť</th>
                            <th width="33%">
                                Cena donášky
                            </th>
                            <th width="33%">
                                Z toho pre kuriera
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(
                            (distance) => (
                                <tr key={distance}>
                                    <td>
                                        {`${distance} km`}
                                    </td>
                                    {openEdit && (
                                        <td>
                                            <Input
                                                id={`distance-pricing-${distance}-km`}
                                                value={getDistanceValue(
                                                    distance
                                                )}
                                                type="number"
                                                step={0.1}
                                                onChange={(
                                                    e
                                                ) => {
                                                    onChangeDistance(
                                                        e
                                                            .target
                                                            .value,
                                                        distance
                                                    );
                                                }}
                                            />
                                        </td>
                                    )}
                                    {!openEdit && (
                                        <td>
                                            {`${getDistanceValue(
                                                distance
                                            )} eur`}
                                        </td>
                                    )}

                                    {openEdit && (
                                        <td>
                                            <Input
                                                id={`distance-pricing-${distance}-km-courier`}
                                                value={getDistanceValue(
                                                    distance,
                                                    true
                                                )}
                                                type="number"
                                                step={0.1}
                                                max={getDistanceValue(
                                                    distance
                                                )}
                                                onChange={(
                                                    e
                                                ) => {
                                                    onChangeDistance(
                                                        e
                                                            .target
                                                            .value,
                                                        distance,
                                                        true
                                                    );
                                                }}
                                            />
                                        </td>
                                    )}
                                    {!openEdit && (
                                        <td>
                                            {`${getDistanceValue(
                                                distance,
                                                true
                                            )} eur`}
                                        </td>
                                    )}
                                </tr>
                            )
                        )}
                    </tbody>
                </Table>
            </Row>
        </Card>
    );
}
