import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';

export default function SettingsSidebar() {
    return (
        <Nav fill pills vertical>
            <NavItem>
                <NavLink tag={Link} to={`/settings/admins`}>
                    Administrátori
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} to={`/settings/smtp`}>
                    SMTP
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    tag={Link}
                    to={`/settings/languages`}
                >
                    Jazyky
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    tag={Link}
                    to={`/settings/distance-pricing`}
                >
                    Ceny okruhov
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} to={`/settings/keys`}>
                    API keys
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    tag={Link}
                    to={`/settings/trending-groups`}
                >
                    Trending groups
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    tag={Link}
                    to={`/settings/ingredients`}
                >
                    Ingrediencie
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    tag={Link}
                    to={`/settings/product_categories`}
                >
                    Kategórie produktov
                </NavLink>
            </NavItem>

            <NavItem>
                <NavLink
                    tag={Link}
                    to={`/settings/diets`}
                >
                    Diéty
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    tag={Link}
                    to={`/settings/preparation_times`}
                >
                    Doby prípravy receptu
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    tag={Link}
                    to={`/settings/meal_types`}
                >
                    Typy jedla
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    tag={Link}
                    to={`/settings/new_ingredients`}
                >
                    Nové ingrediencie
                </NavLink>
            </NavItem>
        </Nav>
    );
}
