import React, { useState } from 'react';
import {
    Table,
    Modal,
    ModalBody,
} from 'reactstrap';
import { MaterialSymbol } from 'react-material-symbols';
import { timestampToStringFNS, getStageLabel, getLabel } from 'utils';
import Recipe from './recipe';

export default function Recipes(props) {
    const {
        recipes,
        language,
        showVisible,
    } = props;

    const [shownRecipe, setShownRecipe] = useState(null);

    return (
        <>
            <Table hover>
                <thead>
                    <tr>
                        <th width={'10%'}>Viditeľný</th>
                        <th width={'30%'}>Názov</th>
                        <th width={'20%'}>Upravný dňa</th>
                        <th width={'20%'}>Status</th>
                        <th width={'20%'}>Pridaný dňa</th>
                    </tr>
                </thead>
                <tbody>
                    {recipes.length === 0 && (
                        <tr style={{ marginTop: '1em' }}>
                            <td colSpan={5}>
                                {
                                    showVisible ? "Kuchár zatiaľ nemá vytvorené žiadne recepty." : "Kuchár zatiaľ nemá vytvorené žiadne viditeľné recepty."
                                }
                            </td>

                        </tr>
                    )}
                    {recipes.map((recipe) => (
                        <tr
                            key={recipe.id}
                            style={{ cursor: 'pointer' }}
                            onClick={() => { setShownRecipe(recipe.id) }}
                        >
                            <td>
                                <MaterialSymbol
                                    icon={
                                        recipe.visible
                                            ? 'visibility'
                                            : 'visibility_off'
                                    }
                                    size={24}
                                    fill
                                    grade={-25}
                                    color="black"
                                />
                            </td>
                            <td>{getLabel(recipe.recipeTranslations, language) + " " + recipe.id}</td>
                            <td>{getStageLabel(recipe.progress)}</td>
                            <td>
                                {timestampToStringFNS(
                                    recipe.createdAt
                                )}
                            </td>
                            <td>
                                {recipe.updatedAt
                                    ? timestampToStringFNS(
                                        recipe.updatedAt
                                    )
                                    : timestampToStringFNS(
                                        recipe.createdAt
                                    )}
                            </td>
                            <td>

                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Modal isOpen={shownRecipe !== null} toggle={() => { setShownRecipe(null) }}>
                <ModalBody>
                    <Recipe
                        closeModal={() => { setShownRecipe(null) }}
                        recipeId={shownRecipe}
                    />
                </ModalBody>
            </Modal>
        </>
    );
}
