import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import {
    NavLink as Link,
    useParams,
} from 'react-router-dom';

export default function CustomerSidebar() {
    const { customerID } = useParams();

    return (
        <Nav fill pills vertical>
            <NavItem>
                <NavLink tag={Link} to={`/customers`} end>
                    ← Naspäť na zoznam
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    tag={Link}
                    to={`/customers/${customerID}/profile`}
                >
                    Profil
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    tag={Link}
                    to={`/customers/${customerID}/addresses`}
                >
                    Adresy
                </NavLink>
            </NavItem>
        </Nav>
    );
}
