import React, { useState, useEffect } from 'react';
import {
    useMutation,
} from '@apollo/client';
import {
    Button,
    Col,
    Row,
    Spinner,
    Label,
    FormGroup,
    Input,
} from 'reactstrap';

import translate from 'translate';

import {
    UPDATE_RECIPE_TRANSLATION,
} from 'queries';
import { REST_URL } from 'configs/restAPI';
import axios from 'axios';

export default function RecipeTranslationGenerator(props) {
    const {
        recipeId,
        language,
        setTranslationWasEdited,
        originalTranslation,
        originalSubtitles,
        recipeTranslation,
        recipeSubtitlesPath,
        allTranslations,
        setAllTranslations,
    } = props;


    const [adminUpdateRecipeTranslation] = useMutation(UPDATE_RECIPE_TRANSLATION);

    const [saving, setSaving] = useState(false);

    const [translating, setTranslating] = useState(false);

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [preparation, setPreparation] = useState([]);

    const [subtitlesLoading, setSubtitlesLoading] = useState(false);
    const [subtitles, setSubtitles] = useState([]);

    useEffect(() => {
        setSubtitlesLoading(true);
        getSubtitles(recipeSubtitlesPath);
    }, [recipeSubtitlesPath, language])

    useEffect(() => {
        if (recipeTranslation) {
            setTitle(recipeTranslation.title);
            setDescription(recipeTranslation.description);

            if (!recipeTranslation.preparation || recipeTranslation.preparation.length === 0) {
                let newPreparation = originalTranslation.preparation.map((prep) => ({
                    order: prep.order,
                    text: "",
                }));
                setPreparation(newPreparation);
            } else {
                setPreparation(recipeTranslation.preparation);
            }

        } else {
            setTitle("");
            setDescription("");
            if (originalTranslation) {
                let newPreparation = originalTranslation.preparation.map((prep) => ({
                    order: prep.order,
                    text: "",
                }));
                setPreparation(newPreparation);

            } else {
                setPreparation([]);
                setSubtitles([]);
            }
        }

        setSaving(false);
        setTranslating(false);
        setTranslationWasEdited(false);

    }, [recipeTranslation, originalTranslation]);


    const updateRecipeTranslationFunc = () => {
        setSaving(true);

        adminUpdateRecipeTranslation({
            variables: {
                recipeId,
                languageId: language.id,
                subtitles: subtitles.map((subt) => `${subt.order}\n${subt.timeStamp}\n${subt.text}`).join(`\n`),
                title,
                description,
                preparation: preparation.map((prep) => ({
                    ...(prep.id ? { id: prep.id } : {}),
                    order: prep.order,
                    text: prep.text,
                })),
            },
        }).then((resp) => {
            setTranslationWasEdited(false);
            let newAllTranslations = allTranslations.map((translation) => ({
                ...translation,
                translation: translation.language.id === language.id ? {
                    ...translation.translation,
                    subtitles,
                    title,
                    description,
                    preparation,
                } : translation.translation
            }))
            setAllTranslations(newAllTranslations)
            setSaving(false);
        });
    };

    async function getSubtitles(subtitlesPath) {
        console.log(subtitlesPath, originalSubtitles, recipeTranslation);

        if (!subtitlesPath && !originalSubtitles) {
            setSubtitles([]);
            setSubtitlesLoading(false);

            return;
        }
        if (!subtitlesPath && originalSubtitles) {
            if (recipeTranslation && recipeTranslation.subtitles) {
                console.log("HI?");

                setSubtitles(recipeTranslation.subtitles);
            } else {
                console.log("HI");

                let newSubtitles = originalSubtitles.map((subt) => ({
                    ...subt,
                    text: "",
                }));
                setSubtitles(newSubtitles);
            }
            setSubtitlesLoading(false);
            return;
        }

        try {
            const srtFile = await axios.get(`${REST_URL}/${subtitlesPath}`,
                {
                    params: {
                        responseType: 'blob', // Important
                    }
                }
            );
            const parsedSubtitles = srtFile.data.split(/[\r\n]+/);
            /*  const parsedSubtitles = `1
   00:05:00,400 --> 00:05:15,300
   This is an example of
   a subtitle.
   
   2
   00:05:16,400 --> 00:05:25,300
   This is an example of
   a subtitle - 2nd subtitle.`.split(/[\r\n]+/);*/
            let result = [];
            let nextEntry = null;
            for (let index = 0; index < parsedSubtitles.length; index++) {
                const element = parsedSubtitles[index];
                if (/^[0-9]+$/g.test(element)) {
                    if (nextEntry) {
                        result.push(nextEntry);
                    }
                    nextEntry = {
                        order: element,
                        timeStamp: null,
                        text: "",
                    };
                } else if (/[0-9]{2}:[0-9]{2}:[0-9]{2},[0-9]{3}.{1,}[0-9]{2}:[0-9]{2}:[0-9]{2},[0-9]{3}/.test(element)) {
                    nextEntry.timeStamp = element;
                } else {
                    nextEntry.text = nextEntry.text + " " + element;
                }
            }
            result.push(nextEntry);

            setSubtitles(result);

            setSubtitlesLoading(false);

        } catch (e) {
            console.log(e);
            setSubtitles([]);
            setSubtitlesLoading(false);
        }
    }

    async function getTranslations() {
        const newTitle = originalTranslation.title ? await translate(originalTranslation.title, { from: originalTranslation.language.languageCode, to: language.languageCode }) : "";
        const newDescription = originalTranslation.description ? await translate(originalTranslation.description, { from: originalTranslation.language.languageCode, to: language.languageCode }) : '';
        const newPreparation = [];
        for (let index = 0; index < originalTranslation.preparation.length; index++) {
            const element = originalTranslation.preparation[index];
            let newPrepStep = await translate(element.text, { from: originalTranslation.language.languageCode, to: language.languageCode });
            newPreparation.push({
                order: element.order,
                text: newPrepStep
            });
        }
        const newSubtitles = []
        for (let index = 0; index < originalSubtitles.length; index++) {
            const element = originalSubtitles[index];

            let newSubtStep = await translate(element.text, { from: originalTranslation.language.languageCode, to: language.languageCode });
            newSubtitles.push({
                ...element,
                text: newSubtStep,
            });
        }

        setTitle(newTitle);
        setDescription(newDescription);
        setPreparation(newPreparation);
        setSubtitles(newSubtitles);

        setTranslating(false);
        setTranslationWasEdited(true);
    }

    if (translating) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    return (
        <>
            <Row>
                <Button
                    color={"link"}
                    block
                    onClick={() => {
                        setTranslating(true);
                        getTranslations();
                    }}
                >
                    {`Navrhnúť preklad podľa originálu`}
                </Button>
            </Row>

            <Row>
                <FormGroup>
                    <Label htmlFor="translation-title">
                        Názov
                    </Label>
                    {
                        !originalTranslation?.title &&
                        <p>Bez názvu</p>
                    }
                    {
                        originalTranslation?.title &&
                        <Input
                            id="translation-title"
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                                setTranslationWasEdited(true);
                            }}
                        />
                    }
                </FormGroup>
            </Row>

            <Row>
                <FormGroup>
                    <Label htmlFor="translation-description">
                        Popis
                    </Label>
                    {
                        (!originalTranslation || !originalTranslation.description || originalTranslation.description.length === 0) &&
                        <p>Bez popisu</p>
                    }
                    {
                        (originalTranslation && originalTranslation.description && originalTranslation.description.length > 0) &&
                        <Input
                            id="translation-description"
                            value={description}
                            onChange={(e) => {
                                setTranslationWasEdited(true);
                                setDescription(e.target.value);
                            }}
                        />
                    }
                </FormGroup>
            </Row>


            <Row>
                <FormGroup>
                    <Label htmlFor="translation-preparation">
                        Postup
                    </Label>
                    {
                        (!originalTranslation || !originalTranslation.preparation || originalTranslation.preparation.length === 0) &&
                        <p>Bez postupu</p>
                    }
                    {
                        preparation.map((prep, index) => (
                            <div key={index}>
                                <label>{`Krok ${index + 1}`}</label>
                                <Input
                                    id="translation-preparation"
                                    value={prep.text}
                                    onChange={(e) => {
                                        setTranslationWasEdited(true);
                                        let newPrep = [...preparation];
                                        newPrep[index].text = e.target.value;
                                        setPreparation(newPrep);
                                    }}
                                />
                            </div>
                        ))
                    }
                </FormGroup>
            </Row>


            <Row>
                <FormGroup>
                    <Label htmlFor="translation-preparation">
                        Titulky
                    </Label>
                    {
                        subtitlesLoading &&
                        <Spinner />
                    }
                    {
                        !subtitlesLoading && originalSubtitles.length === 0 &&
                        <p>Bez titulkov</p>
                    }
                    {
                        !subtitlesLoading && subtitles.map((subtitle, index) => (
                            <div key={subtitle.order}>
                                <label>{`${subtitle.order} | ${subtitle.timeStamp}`}</label>
                                <Input
                                    id="translation-preparation"
                                    value={subtitle.text}
                                    onChange={(e) => {
                                        setTranslationWasEdited(true);
                                        let newSubtitles = [...subtitles];
                                        newSubtitles[index].text = e.target.value;
                                        setSubtitles(newSubtitles);
                                    }}
                                />
                            </div>
                        ))
                    }
                </FormGroup>
            </Row>

            <Row>
                <Button
                    color={"success"}

                    disabled={saving}
                    onClick={() => {
                        updateRecipeTranslationFunc()
                    }}
                    style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}
                >
                    {
                        saving &&
                        <Spinner />
                    }
                    <span style={{ marginLeft: "10px" }}>{saving ? `Ukladám ${language.label?.toUpperCase()} preklad` : `Uložiť ${language.label?.toUpperCase()} preklad`}</span>
                </Button>
            </Row>

        </ >

    );
}
