import React, { useState, useEffect } from 'react';
import {
    useMutation,
    useQuery,
    useSubscription,
} from '@apollo/client';
import Profile from '../components/profile';
import { useParams } from 'react-router-dom';
import {
    Button,
    Col,
    Row,
    Spinner,
    Alert,
} from 'reactstrap';
import allLanguages from 'configs/languages';
import daysOfTheWeek from 'configs/daysOfTheWeek';

import {
    GET_STORE,
    UPDATE_STORE,
    GET_LANGUAGES,
    LANGUAGES_SUBSCRIPTION,
    CHANGE_STORE_ACTIVE,
} from 'queries';
import { useGetMyData } from 'utils';
import {
    getMilisecondsFromMidnight,
    getTimeFromMilisecondsFromMidnight,
} from 'utils';

export default function ShopProfileContainer() {
    const { shopID } = useParams();

    const currentAdmin = useGetMyData();

    const [updateStore] = useMutation(UPDATE_STORE);
    const [changeStoreActive] = useMutation(
        CHANGE_STORE_ACTIVE
    );

    const {
        data: languagesData,
        loading: languagesLoading,
        refetch: languagesRefetch,
    } = useQuery(GET_LANGUAGES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(LANGUAGES_SUBSCRIPTION, {
        onData: () => {
            languagesRefetch();
        },
    });

    const {
        data: storeData,
        loading: storeLoading,
        refetch: storeRefetch,
    } = useQuery(GET_STORE, {
        variables: {
            storeId: parseInt(shopID),
        },
        fetchPolicy: 'network-only',
    });

    const [title, setTitle] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [siteLanguage, setSiteLanguage] = useState(null);
    const [owner, setOwner] = useState('');
    const [ownerContact, setOwnerContact] = useState('');
    const [dph, setDph] = useState('');
    const [ico, setIco] = useState('');
    const [dic, setDic] = useState('');
    const [icDic, setIcDic] = useState('');
    const [languages, setLanguages] = useState([]);
    const [location, setLocation] = useState({
        address: '',
        latitude: null,
        longitude: null,
    });
    const [iconPath, setIconPath] = useState(null);
    const [image, setImage] = useState(null);

    const [deliveryOption, setDeliveryOption] =
        useState(true);
    const [drivethroughOption, setDrivethroughOption] =
        useState(true);
    const [pickupOption, setPickupOption] = useState(true);

    const [preparationTime, setPreparationTime] =
        useState(10);

    const [openHours, setOpenHours] = useState(
        daysOfTheWeek.map((day) => ({
            day,
            from: '07:00',
            to: '19:00',
            closed: false,
        }))
    );

    const [deliveryHours, setDeliveryHours] = useState(
        daysOfTheWeek.map((day) => ({
            day,
            from: '07:00',
            to: '19:00',
            closed: false,
        }))
    );

    const [saving, setSaving] = useState(false);
    const [saved, setSaved] = useState(false);

    const onDismissSaved = () => setSaved(false);

    const getOpenHoursData = (id, data) => {
        switch (id) {
            case 1:
                return {
                    to: getTimeFromMilisecondsFromMidnight(
                        data.openHoursMon.to
                    ),
                    from: getTimeFromMilisecondsFromMidnight(
                        data.openHoursMon.from
                    ),
                    closed: data.openHoursMon.closed,
                };
            case 2:
                return {
                    to: getTimeFromMilisecondsFromMidnight(
                        data.openHoursTue.to
                    ),
                    from: getTimeFromMilisecondsFromMidnight(
                        data.openHoursTue.from
                    ),
                    closed: data.openHoursTue.closed,
                };
            case 3:
                return {
                    to: getTimeFromMilisecondsFromMidnight(
                        data.openHoursWen.to
                    ),
                    from: getTimeFromMilisecondsFromMidnight(
                        data.openHoursWen.from
                    ),
                    closed: data.openHoursWen.closed,
                };
            case 4:
                return {
                    to: getTimeFromMilisecondsFromMidnight(
                        data.openHoursThu.to
                    ),
                    from: getTimeFromMilisecondsFromMidnight(
                        data.openHoursThu.from
                    ),
                    closed: data.openHoursThu.closed,
                };
            case 5:
                return {
                    to: getTimeFromMilisecondsFromMidnight(
                        data.openHoursFri.to
                    ),
                    from: getTimeFromMilisecondsFromMidnight(
                        data.openHoursFri.from
                    ),
                    closed: data.openHoursFri.closed,
                };
            case 6:
                return {
                    to: getTimeFromMilisecondsFromMidnight(
                        data.openHoursSat.to
                    ),
                    from: getTimeFromMilisecondsFromMidnight(
                        data.openHoursSat.from
                    ),
                    closed: data.openHoursSat.closed,
                };
            case 7:
                return {
                    to: getTimeFromMilisecondsFromMidnight(
                        data.openHoursSun.to
                    ),
                    from: getTimeFromMilisecondsFromMidnight(
                        data.openHoursSun.from
                    ),
                    closed: data.openHoursSun.closed,
                };

            default:
                break;
        }
    };

    const getDeliveryHoursData = (id, data) => {
        switch (id) {
            case 1:
                return {
                    to: getTimeFromMilisecondsFromMidnight(
                        data.deliveryHoursMon.to
                    ),
                    from: getTimeFromMilisecondsFromMidnight(
                        data.deliveryHoursMon.from
                    ),
                    closed: data.deliveryHoursMon.closed,
                };
            case 2:
                return {
                    to: getTimeFromMilisecondsFromMidnight(
                        data.deliveryHoursTue.to
                    ),
                    from: getTimeFromMilisecondsFromMidnight(
                        data.deliveryHoursTue.from
                    ),
                    closed: data.deliveryHoursTue.closed,
                };
            case 3:
                return {
                    to: getTimeFromMilisecondsFromMidnight(
                        data.deliveryHoursWen.to
                    ),
                    from: getTimeFromMilisecondsFromMidnight(
                        data.deliveryHoursWen.from
                    ),
                    closed: data.deliveryHoursWen.closed,
                };
            case 4:
                return {
                    to: getTimeFromMilisecondsFromMidnight(
                        data.deliveryHoursThu.to
                    ),
                    from: getTimeFromMilisecondsFromMidnight(
                        data.deliveryHoursThu.from
                    ),
                    closed: data.deliveryHoursThu.closed,
                };
            case 5:
                return {
                    to: getTimeFromMilisecondsFromMidnight(
                        data.deliveryHoursFri.to
                    ),
                    from: getTimeFromMilisecondsFromMidnight(
                        data.deliveryHoursFri.from
                    ),
                    closed: data.deliveryHoursFri.closed,
                };
            case 6:
                return {
                    to: getTimeFromMilisecondsFromMidnight(
                        data.deliveryHoursSat.to
                    ),
                    from: getTimeFromMilisecondsFromMidnight(
                        data.deliveryHoursSat.from
                    ),
                    closed: data.deliveryHoursSat.closed,
                };
            case 7:
                return {
                    to: getTimeFromMilisecondsFromMidnight(
                        data.deliveryHoursSun.to
                    ),
                    from: getTimeFromMilisecondsFromMidnight(
                        data.deliveryHoursSun.from
                    ),
                    closed: data.deliveryHoursSun.closed,
                };

            default:
                break;
        }
    };

    useEffect(() => {
        storeRefetch({
            storeId: parseInt(shopID),
        });
    }, [shopID]);

    useEffect(() => {
        if (storeData && storeData.store) {
            setData();
        }
    }, [storeData]);

    const setData = () => {
        setTitle(storeData.store.title);
        setPhoneNumber(storeData.store.phoneNumber);
        setSiteLanguage(
            [
                {
                    value: 'sk',
                    label: 'Slovak',
                },
                {
                    value: 'cs',
                    label: 'Czech',
                },
                {
                    value: 'en',
                    label: 'English',
                },
                {
                    value: 'de',
                    label: 'German',
                },
            ].find(
                (lang) =>
                    lang.value ===
                    storeData.store.siteLanguage
            )
        );
        setOwner(storeData.store.owner);
        setOwnerContact(storeData.store.ownerContact);
        setDph(storeData.store.dph);
        setIco(storeData.store.ico);
        setDic(storeData.store.dic);
        setIcDic(storeData.store.icDic);
        setLanguages(
            storeData.store.languages.map((language) => ({
                ...language,
                ...allLanguages.find(
                    (lang) =>
                        lang.value === language.languageCode
                ),
            }))
        );
        setLocation({
            latitude: storeData.store.location.latitude,
            longitude: storeData.store.location.longitude,
            address: storeData.store.location.address,
        });
        setIconPath(storeData.store.iconPath);
        setDeliveryOption(storeData.store.details.delivery);
        setDrivethroughOption(
            storeData.store.details.driveThrough
        );
        setPickupOption(storeData.store.details.pickup);
        setPreparationTime(
            storeData.store.details.preparationTime
        );
        setOpenHours(
            daysOfTheWeek.map((day) => ({
                day,
                ...getOpenHoursData(
                    day.value,
                    storeData.store.details
                ),
            }))
        );
        setDeliveryHours(
            daysOfTheWeek.map((day) => ({
                day,
                ...getDeliveryHoursData(
                    day.value,
                    storeData.store.details
                ),
            }))
        );
    };

    const changeStoreActiveFunc = () => {
        changeStoreActive({
            variables: {
                id: parseInt(shopID),
            },
        }).then(() => {
            setSaving(false);
            setSaved(true);
        });
    };

    const updateStoreFunc = () => {
        updateStore({
            variables: {
                id: parseInt(shopID),
                title,
                phoneNumber,
                siteLanguage: siteLanguage.value,
                owner,
                ownerContact,
                dph,
                ico,
                dic,
                icDic,
                icon: image,
                languages: languages.map((lang) => lang.id),
                location,
                details: {
                    pickup: pickupOption,
                    delivery: deliveryOption,
                    driveThrough: drivethroughOption,
                    preparationTime:
                        parseInt(preparationTime),
                    openHoursMon: {
                        to: getMilisecondsFromMidnight(
                            openHours[0].to
                        ),
                        from: getMilisecondsFromMidnight(
                            openHours[0].from
                        ),
                        closed: openHours[0].closed,
                    },
                    openHoursTue: {
                        to: getMilisecondsFromMidnight(
                            openHours[1].to
                        ),
                        from: getMilisecondsFromMidnight(
                            openHours[1].from
                        ),
                        closed: openHours[1].closed,
                    },
                    openHoursWen: {
                        to: getMilisecondsFromMidnight(
                            openHours[2].to
                        ),
                        from: getMilisecondsFromMidnight(
                            openHours[2].from
                        ),
                        closed: openHours[2].closed,
                    },
                    openHoursThu: {
                        to: getMilisecondsFromMidnight(
                            openHours[3].to
                        ),
                        from: getMilisecondsFromMidnight(
                            openHours[3].from
                        ),
                        closed: openHours[3].closed,
                    },
                    openHoursFri: {
                        to: getMilisecondsFromMidnight(
                            openHours[4].to
                        ),
                        from: getMilisecondsFromMidnight(
                            openHours[4].from
                        ),
                        closed: openHours[4].closed,
                    },
                    openHoursSat: {
                        to: getMilisecondsFromMidnight(
                            openHours[5].to
                        ),
                        from: getMilisecondsFromMidnight(
                            openHours[5].from
                        ),
                        closed: openHours[5].closed,
                    },
                    openHoursSun: {
                        to: getMilisecondsFromMidnight(
                            openHours[6].to
                        ),
                        from: getMilisecondsFromMidnight(
                            openHours[6].from
                        ),
                        closed: openHours[6].closed,
                    },

                    deliveryHoursMon: {
                        to: getMilisecondsFromMidnight(
                            deliveryHours[0].to
                        ),
                        from: getMilisecondsFromMidnight(
                            deliveryHours[0].from
                        ),
                        closed: deliveryHours[0].closed,
                    },
                    deliveryHoursTue: {
                        to: getMilisecondsFromMidnight(
                            deliveryHours[1].to
                        ),
                        from: getMilisecondsFromMidnight(
                            deliveryHours[1].from
                        ),
                        closed: deliveryHours[1].closed,
                    },
                    deliveryHoursWen: {
                        to: getMilisecondsFromMidnight(
                            deliveryHours[2].to
                        ),
                        from: getMilisecondsFromMidnight(
                            deliveryHours[2].from
                        ),
                        closed: deliveryHours[2].closed,
                    },
                    deliveryHoursThu: {
                        to: getMilisecondsFromMidnight(
                            deliveryHours[3].to
                        ),
                        from: getMilisecondsFromMidnight(
                            deliveryHours[3].from
                        ),
                        closed: deliveryHours[3].closed,
                    },
                    deliveryHoursFri: {
                        to: getMilisecondsFromMidnight(
                            deliveryHours[4].to
                        ),
                        from: getMilisecondsFromMidnight(
                            deliveryHours[4].from
                        ),
                        closed: deliveryHours[4].closed,
                    },
                    deliveryHoursSat: {
                        to: getMilisecondsFromMidnight(
                            deliveryHours[5].to
                        ),
                        from: getMilisecondsFromMidnight(
                            deliveryHours[5].from
                        ),
                        closed: deliveryHours[5].closed,
                    },
                    deliveryHoursSun: {
                        to: getMilisecondsFromMidnight(
                            deliveryHours[6].to
                        ),
                        from: getMilisecondsFromMidnight(
                            deliveryHours[6].from
                        ),
                        closed: deliveryHours[6].closed,
                    },
                },
            },
        }).then(() => {
            setSaving(false);
            setSaved(true);
        });
    };

    const cannotSave =
        title.length === 0 ||
        phoneNumber.length === 0 ||
        owner.length === 0 ||
        ownerContact.length === 0 ||
        dph.length === 0 ||
        ico.length === 0 ||
        dic.length === 0 ||
        icDic.length === 0 ||
        languages.length === 0 ||
        location.latitude === null;

    if (languagesLoading || storeLoading) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    return (
        <Col>
            <Profile
                shopID={parseInt(shopID)}
                title={title}
                setTitle={setTitle}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                siteLanguage={siteLanguage}
                setSiteLanguage={setSiteLanguage}
                owner={owner}
                setOwner={setOwner}
                ownerContact={ownerContact}
                setOwnerContact={setOwnerContact}
                dph={dph}
                setDph={setDph}
                ico={ico}
                setIco={setIco}
                dic={dic}
                setDic={setDic}
                icDic={icDic}
                setIcDic={setIcDic}
                languages={languages}
                setLanguages={setLanguages}
                location={location}
                setLocation={setLocation}
                iconPath={iconPath}
                setIconPath={setIconPath}
                image={image}
                setImage={setImage}
                deliveryOption={deliveryOption}
                setDeliveryOption={setDeliveryOption}
                drivethroughOption={drivethroughOption}
                setDrivethroughOption={
                    setDrivethroughOption
                }
                pickupOption={pickupOption}
                setPickupOption={setPickupOption}
                preparationTime={preparationTime}
                setPreparationTime={setPreparationTime}
                openHours={openHours}
                setOpenHours={setOpenHours}
                deliveryHours={deliveryHours}
                setDeliveryHours={setDeliveryHours}
                siteLanguagesOptions={[
                    {
                        value: 'sk',
                        label: 'Slovak',
                    },
                    {
                        value: 'cs',
                        label: 'Czech',
                    },
                    {
                        value: 'en',
                        label: 'English',
                    },
                    {
                        value: 'de',
                        label: 'German',
                    },
                ]}
                supportedLanguagesOptions={languagesData.languages.map(
                    (language) => ({
                        ...language,
                        ...allLanguages.find(
                            (lang) =>
                                lang.value ===
                                language.languageCode
                        ),
                    })
                )}
                currentAdmin={currentAdmin}
            />
            {!storeData.store.active && (
                <Row xs="2">
                    <Col>
                        <Button
                            color="secondary"
                            onClick={() => {
                                setSaving(true);
                                changeStoreActiveFunc();
                            }}
                        >
                            Znovuaktivovať obchod
                        </Button>
                    </Col>
                    <Col className="flex jc-flex-end"></Col>
                </Row>
            )}
            <Alert
                color="info"
                isOpen={saved}
                toggle={onDismissSaved}
            >
                Zmeny boli uložené!
            </Alert>
            <Row
                xs="2"
                style={{
                    marginBottom: '1em',
                }}
            >
                <Col>
                    <Button
                        color="secondary"
                        onClick={setData}
                    >
                        Zrušiť
                    </Button>
                </Col>
                <Col className="flex jc-flex-end">
                    <Button
                        color="primary"
                        disabled={cannotSave}
                        onClick={() => {
                            setSaving(true);
                            updateStoreFunc();
                        }}
                    >
                        {saving && (
                            <Spinner
                                color="light"
                                size="sm"
                            ></Spinner>
                        )}
                        {!saving ? 'Uložiť' : ''}
                    </Button>
                </Col>
            </Row>
        </Col>
    );
}
