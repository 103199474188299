import React, { useState } from 'react';
import { Table, Button, Row, Col, Modal } from 'reactstrap';
import NewIngredientLoader from './loader';
import { useGetMyData } from 'utils';

export default function NewIngredients(props) {
    const {
        newIngredients,
        onUpdateNewIngredient,
        onRemoveNewIngredient,
    } = props;

    const currentAdmin = useGetMyData();

    const [editNewIngredient, setEditNewIngredient] =
        useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const toggle = () => {
        setModalOpen(!modalOpen);
    };

    const getTitle = (newIngredient) => {
        const currentAdminTranslation =
            newIngredient.translations.find(
                (translation) =>
                    translation.language.languageCode ===
                    currentAdmin.siteLanguage
            );
        if (currentAdminTranslation) {
            return currentAdminTranslation.title;
        }
        if (newIngredient.translations.length > 0) {
            return newIngredient.translations
                .map(
                    (translation) =>
                        `${translation.title} (${translation.language.title})`
                )
                .join(', ');
        }
        return 'Bez názvu';
    };

    const sortedTranslatedNewIngredients = [...newIngredients]
        .map((newIngredient) => ({
            ...newIngredient,
            title: getTitle(newIngredient),
        }))
        .sort((i1, i2) => (i1.title < i2.title ? -1 : 1));

    return (
        <>
            <Table hover>
                <thead>
                    <tr>
                        <th width={'66%'}>Názov</th>
                        <th width={'33%'}>Akcia</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedTranslatedNewIngredients.map((newIngredient) => (
                        <tr
                            key={newIngredient.id}
                            style={{
                                cursor: 'pointer',
                            }}
                        >
                            <td>{newIngredient.title}</td>
                            <td className="ta-center">
                                {newIngredient.units.join(
                                    ', '
                                )}
                            </td>

                            <td>
                                <Row xs="2">
                                    <Col>
                                        <Button
                                            color="success"
                                            block
                                            onClick={() => {
                                                setEditNewIngredient(
                                                    newIngredient
                                                );
                                                toggle();
                                            }}
                                        >
                                            Schváliť
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button
                                            color="danger"
                                            block
                                            onClick={() => {
                                                if (
                                                    window.confirm(
                                                        'Ste si isty?'
                                                    )
                                                ) {
                                                    onRemoveNewIngredient(
                                                        newIngredient.id
                                                    );
                                                }
                                            }}
                                        >
                                            Odstrániť
                                        </Button>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Modal
                isOpen={modalOpen}
                toggle={() => {
                    if (modalOpen) {
                        setEditNewIngredient(null);
                    }
                    toggle();
                }}
            >
                {editNewIngredient && (
                    <NewIngredientLoader
                        onClose={() => {
                            setEditNewIngredient(null);
                            toggle();
                        }}
                        onSave={(data) => {
                            onUpdateNewIngredient(data);
                            toggle();
                        }}
                        newIngredientId={
                            editNewIngredient
                                ? editNewIngredient.id
                                : null
                        }
                    />
                )}
            </Modal>
        </>
    );
}
