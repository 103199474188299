import React, { useEffect, useState } from 'react';
import {
    FormGroup,
    Label,
    Input,
    Form,
    Button,
    Card,
    Row,
    Col,
    Spinner,
} from 'reactstrap';
import Select from 'react-select';
import { useQuery } from '@apollo/client';
import { GET_RECIPES, CHEFS } from 'queries';
import { useGetMyData } from 'utils';

export default function TrendingGroupForm(props) {
    const {
        defaultOrder,
        onClose,
        onSave,
        trendingGroupId,
        trendingGroupData,
        trendingGroupLoading,
    } = props;

    const currentAdmin = useGetMyData();

    const [title, setTitle] = useState('');
    const [show, setShow] = useState(true);
    const [shuffle, setShuffle] = useState(false);
    const [order, setOrder] = useState(defaultOrder);

    const [recipes, setRecipes] = useState([]);

    const [chefs, setChefs] = useState([]);

    const { data: recipesData, loading: recipesLoading } =
        useQuery(GET_RECIPES, {
            variables: {
                visible: true,
            },
            fetchPolicy: 'network-only',
        });

    const { data: chefsData, loading: chefsLoading } =
        useQuery(CHEFS, {
            fetchPolicy: 'network-only',
        });

    const getLabel = (recipe) => {
        const currentAdminfTranslation =
            recipe.recipeTranslations.find(
                (translation) =>
                    translation.language.languageCode ===
                    currentAdmin.siteLanguage
            );
        if (currentAdminfTranslation) {
            return currentAdminfTranslation.title.length > 0 ? currentAdminfTranslation.title : `Bez názvu (${currentAdminfTranslation.language.languageCode})`;
        }
        if (recipe.recipeTranslations.length > 0) {
            const joinedTitle = recipe.recipeTranslations.map(
                (translation) =>
                    `${translation.title} (${translation.language.title})`
            )
                .join(', ');
            return joinedTitle.length > 0 ? joinedTitle : "Bez názvu";
        }
        return 'Bez názvu';
    };

    useEffect(() => {
        if (
            trendingGroupData &&
            trendingGroupData.trendingGroup
        ) {
            setTitle(trendingGroupData.trendingGroup.title);
            setShow(trendingGroupData.trendingGroup.show);
            setShuffle(
                trendingGroupData.trendingGroup.shuffle
            );
            setOrder(trendingGroupData.trendingGroup.order);
            setRecipes(
                trendingGroupData.trendingGroup.recipes.map(
                    (recipe) => ({
                        ...recipe,
                        label: getLabel(recipe),
                        value: recipe.id,
                    })
                )
            );
            setChefs(
                trendingGroupData.trendingGroup.chefs.map(
                    (chef) => ({
                        ...chef,
                        label: chef.fullName,
                        value: chef.id,
                    })
                )
            );
        } else {
            setTitle('');
            setShow(true);
            setShuffle(false);
            setOrder(defaultOrder);
            setRecipes([]);
            setChefs([]);
        }
    }, [trendingGroupData]);

    if (
        trendingGroupLoading ||
        recipesLoading ||
        chefsLoading
    ) {
        return (
            <Card style={{ padding: '1em' }}>
                <Spinner color="primary" size="sm" />
            </Card>
        );
    }

    return (
        <Card style={{ padding: '1em' }}>
            <Row>
                <Form>
                    <FormGroup>
                        <Label htmlFor="trending-group-title">
                            Názov *
                        </Label>
                        <Input
                            id="trending-group-title"
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                        />
                    </FormGroup>

                    <FormGroup check>
                        <Input
                            id="trending-group-show"
                            type="checkbox"
                            checked={show}
                            onChange={() => {
                                setShow(!show);
                            }}
                        />
                        <Label
                            check
                            htmlFor="trending-group-show"
                        >
                            Zobrazovaná
                        </Label>
                    </FormGroup>

                    <FormGroup check>
                        <Input
                            id="trending-group-shuffle"
                            type="checkbox"
                            checked={shuffle}
                            onChange={() => {
                                setShuffle(!shuffle);
                            }}
                        />
                        <Label
                            check
                            htmlFor="trending-group-shuffle"
                        >
                            Meniť poradie
                            receptov/šéfkuchárov v skupine
                        </Label>
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="trending-group-order">
                            Poradie
                        </Label>
                        <Input
                            id="trending-group-order"
                            value={order}
                            type="number"
                            onChange={(e) => {
                                setOrder(e.target.value);
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="trending-group-recipes">
                            Recepty v skupine
                        </Label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={true}
                            isSearchable={true}
                            isMulti
                            name="trending-group-recipes"
                            options={recipesData.recipes.recipes.map(
                                (item) => ({
                                    ...item,
                                    label: getLabel(item),
                                    value: item.id,
                                })
                            )}
                            value={recipes}
                            onChange={(e) => {
                                setRecipes(e);
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="trending-group-chefs">
                            Šéfkuchári v skupine
                        </Label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={true}
                            isSearchable={true}
                            isMulti
                            name="trending-group-chefs"
                            options={chefsData.chefs.filter((chef) => chef.active && ![1, 2, 4].includes(chef.id)).map(
                                (item) => ({
                                    ...item,
                                    label: item.fullName,
                                    value: item.id,
                                })
                            )}
                            value={chefs}
                            onChange={(e) => {
                                setChefs(e);
                            }}
                        />
                    </FormGroup>
                </Form>
            </Row>

            <Row xs="2">
                <Col>
                    <Button
                        color="secondary"
                        block
                        onClick={onClose}
                    >
                        Zrušiť
                    </Button>
                </Col>
                <Col>
                    <Button
                        color="primary"
                        block
                        disabled={!title}
                        onClick={() => {
                            onSave({
                                id: trendingGroupId,
                                title,
                                show,
                                shuffle,
                                order,
                                recipes,
                                chefs,
                            });
                        }}
                    >
                        Uložiť
                    </Button>
                </Col>
            </Row>
        </Card>
    );
}
