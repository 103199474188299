export default [
    {
        'value': 'aa',
        'label': 'Afar',
        'native': 'Afar',
    },
    {
        'value': 'ab',
        'label': 'Abkhazian',
        'native': 'Аҧсуа',
    },
    {
        'value': 'af',
        'label': 'Afrikaans',
        'native': 'Afrikaans',
    },
    {
        'value': 'ak',
        'label': 'Akan',
        'native': 'Akana',
    },
    {
        'value': 'am',
        'label': 'Amharic',
        'native': 'አማርኛ',
    },
    {
        'value': 'an',
        'label': 'Aragonese',
        'native': 'Aragonés',
    },
    {
        'value': 'ar',
        'label': 'Arabic',
        'native': 'العربية',
        'rtl': 1,
    },
    {
        'value': 'as',
        'label': 'Assamese',
        'native': 'অসমীয়া',
    },
    {
        'value': 'av',
        'label': 'Avar',
        'native': 'Авар',
    },
    {
        'value': 'ay',
        'label': 'Aymara',
        'native': 'Aymar',
    },
    {
        'value': 'az',
        'label': 'Azerbaijani',
        'native': 'Azərbaycanca / آذربايجان',
    },
    {
        'value': 'ba',
        'label': 'Bashkir',
        'native': 'Башҡорт',
    },
    {
        'value': 'be',
        'label': 'Belarusian',
        'native': 'Беларуская',
    },
    {
        'value': 'bg',
        'label': 'Bulgarian',
        'native': 'Български',
    },
    {
        'value': 'bh',
        'label': 'Bihari',
        'native': 'भोजपुरी',
    },
    {
        'value': 'bi',
        'label': 'Bislama',
        'native': 'Bislama',
    },
    {
        'value': 'bm',
        'label': 'Bambara',
        'native': 'Bamanankan',
    },
    {
        'value': 'bn',
        'label': 'Bengali',
        'native': 'বাংলা',
    },
    {
        'value': 'bo',
        'label': 'Tibetan',
        'native': 'བོད་ཡིག / Bod skad',
    },
    {
        'value': 'br',
        'label': 'Breton',
        'native': 'Brezhoneg',
    },
    {
        'value': 'bs',
        'label': 'Bosnian',
        'native': 'Bosanski',
    },
    {
        'value': 'ca',
        'label': 'Catalan',
        'native': 'Català',
    },
    {
        'value': 'ce',
        'label': 'Chechen',
        'native': 'Нохчийн',
    },
    {
        'value': 'ch',
        'label': 'Chamorro',
        'native': 'Chamoru',
    },
    {
        'value': 'co',
        'label': 'Corsican',
        'native': 'Corsu',
    },
    {
        'value': 'cr',
        'label': 'Cree',
        'native': 'Nehiyaw',
    },
    {
        'value': 'cs',
        'label': 'Czech',
        'native': 'Česky',
    },
    {
        'value': 'cu',
        'label': 'Old Church Slavonic / Old Bulgarian',
        'native': 'словѣньскъ / slověnĭskŭ',
    },
    {
        'value': 'cv',
        'label': 'Chuvash',
        'native': 'Чăваш',
    },
    {
        'value': 'cy',
        'label': 'Welsh',
        'native': 'Cymraeg',
    },
    {
        'value': 'da',
        'label': 'Danish',
        'native': 'Dansk',
    },
    {
        'value': 'de',
        'label': 'German',
        'native': 'Deutsch',
    },
    {
        'value': 'dv',
        'label': 'Divehi',
        'native': 'ދިވެހިބަސް',
        'rtl': 1,
    },
    {
        'value': 'dz',
        'label': 'Dzongkha',
        'native': 'ཇོང་ཁ',
    },
    {
        'value': 'ee',
        'label': 'Ewe',
        'native': 'Ɛʋɛ',
    },
    {
        'value': 'el',
        'label': 'Greek',
        'native': 'Ελληνικά',
    },
    {
        'value': 'en',
        'label': 'English',
        'native': 'English',
    },
    {
        'value': 'eo',
        'label': 'Esperanto',
        'native': 'Esperanto',
    },
    {
        'value': 'es',
        'label': 'Spanish',
        'native': 'Español',
    },
    {
        'value': 'et',
        'label': 'Estonian',
        'native': 'Eesti',
    },
    {
        'value': 'eu',
        'label': 'Basque',
        'native': 'Euskara',
    },
    {
        'value': 'fa',
        'label': 'Persian',
        'native': 'فارسی',
        'rtl': 1,
    },
    {
        'value': 'ff',
        'label': 'Peul',
        'native': 'Fulfulde',
    },
    {
        'value': 'fi',
        'label': 'Finnish',
        'native': 'Suomi',
    },
    {
        'value': 'fj',
        'label': 'Fijian',
        'native': 'Na Vosa Vakaviti',
    },
    {
        'value': 'fo',
        'label': 'Faroese',
        'native': 'Føroyskt',
    },
    {
        'value': 'fr',
        'label': 'French',
        'native': 'Français',
    },
    {
        'value': 'fy',
        'label': 'Frisian',
        'native': 'Frysk',
    },
    {
        'value': 'ga',
        'label': 'Irish',
        'native': 'Gaeilge',
    },
    {
        'value': 'gd',
        'label': 'Scottish Gaelic',
        'native': 'Gàidhlig',
    },
    {
        'value': 'gl',
        'label': 'Galician',
        'native': 'Galego',
    },
    {
        'value': 'gn',
        'label': 'Guarani',
        'native': "Avañe'ẽ",
    },
    {
        'value': 'gu',
        'label': 'Gujarati',
        'native': 'ગુજરાતી',
    },
    {
        'value': 'gv',
        'label': 'Manx',
        'native': 'Gaelg / Gailck',
    },
    {
        'value': 'ha',
        'label': 'Hausa',
        'native': 'هَوُسَ',
    },
    {
        'value': 'he',
        'label': 'Hebrew',
        'native': 'עברית',
        'rtl': 1,
    },
    {
        'value': 'hi',
        'label': 'Hindi',
        'native': 'हिन्दी',
    },
    {
        'value': 'ho',
        'label': 'Hiri Motu',
        'native': 'Hiri Motu',
    },
    {
        'value': 'hr',
        'label': 'Croatian',
        'native': 'Hrvatski',
    },
    {
        'value': 'ht',
        'label': 'Haitian Creole',
        'native': 'Krèyol ayisyen',
    },
    {
        'value': 'hu',
        'label': 'Hungarian',
        'native': 'Magyar',
    },
    {
        'value': 'hy',
        'label': 'Armenian',
        'native': 'Հայերեն',
    },
    {
        'value': 'hz',
        'label': 'Herero',
        'native': 'Otsiherero',
    },
    {
        'value': 'ia',
        'label': 'Interlingua',
        'native': 'Interlingua',
    },
    {
        'value': 'id',
        'label': 'Indonesian',
        'native': 'Bahasa Indonesia',
    },
    {
        'value': 'ie',
        'label': 'Interlingue',
        'native': 'Interlingue',
    },
    {
        'value': 'ig',
        'label': 'Igbo',
        'native': 'Igbo',
    },
    {
        'value': 'ii',
        'label': 'Sichuan Yi',
        'native': 'ꆇꉙ / 四川彝语',
    },
    {
        'value': 'ik',
        'label': 'Inupiak',
        'native': 'Iñupiak',
    },
    {
        'value': 'io',
        'label': 'Ido',
        'native': 'Ido',
    },
    {
        'value': 'is',
        'label': 'Icelandic',
        'native': 'Íslenska',
    },
    {
        'value': 'it',
        'label': 'Italian',
        'native': 'Italiano',
    },
    {
        'value': 'iu',
        'label': 'Inuktitut',
        'native': 'ᐃᓄᒃᑎᑐᑦ',
    },
    {
        'value': 'ja',
        'label': 'Japanese',
        'native': '日本語',
    },
    {
        'value': 'jv',
        'label': 'Javanese',
        'native': 'Basa Jawa',
    },
    {
        'value': 'ka',
        'label': 'Georgian',
        'native': 'ქართული',
    },
    {
        'value': 'kg',
        'label': 'Kongo',
        'native': 'KiKongo',
    },
    {
        'value': 'ki',
        'label': 'Kikuyu',
        'native': 'Gĩkũyũ',
    },
    {
        'value': 'kj',
        'label': 'Kwanyama',
        'native': 'Kuanyama',
    },
    {
        'value': 'kk',
        'label': 'Kazakh',
        'native': 'Қазақша',
    },
    {
        'value': 'kl',
        'label': 'Greenlandic',
        'native': 'Kalaallisut / Kalaallit oqaasii',
    },
    {
        'value': 'km',
        'label': 'Cambodian',
        'native': 'ភាសាខ្មែរ',
    },
    {
        'value': 'kn',
        'label': 'Kannada',
        'native': 'ಕನ್ನಡ',
    },
    {
        'value': 'ko',
        'label': 'Korean',
        'native': '한국어',
    },
    {
        'value': 'kr',
        'label': 'Kanuri',
        'native': 'Kanuri',
    },
    {
        'value': 'ks',
        'label': 'Kashmiri',
        'native': 'कश्मीरी / كشميري',
    },
    {
        'value': 'ku',
        'label': 'Kurdish',
        'native': 'Kurdî / كوردی',
    },
    {
        'value': 'kv',
        'label': 'Komi',
        'native': 'Коми',
    },
    {
        'value': 'kw',
        'label': 'Cornish',
        'native': 'Kernewek',
    },
    {
        'value': 'ky',
        'label': 'Kirghiz',
        'native': 'Кыргызча',
    },
    {
        'value': 'la',
        'label': 'Latin',
        'native': 'Latina',
    },
    {
        'value': 'lb',
        'label': 'Luxembourgish',
        'native': 'Lëtzebuergesch',
    },
    {
        'value': 'lg',
        'label': 'Ganda',
        'native': 'Luganda',
    },
    {
        'value': 'li',
        'label': 'Limburgian',
        'native': 'Limburgs',
    },
    {
        'value': 'ln',
        'label': 'Lingala',
        'native': 'Lingála',
    },
    {
        'value': 'lo',
        'label': 'Laotian',
        'native': 'ລາວ / Pha xa lao',
    },
    {
        'value': 'lt',
        'label': 'Lithuanian',
        'native': 'Lietuvių',
    },
    {
        'value': 'lu',
        'label': 'Luba-Katanga',
        'native': 'Tshiluba',
    },
    {
        'value': 'lv',
        'label': 'Latvian',
        'native': 'Latviešu',
    },
    {
        'value': 'mg',
        'label': 'Malagasy',
        'native': 'Malagasy',
    },
    {
        'value': 'mh',
        'label': 'Marshallese',
        'native': 'Kajin Majel / Ebon',
    },
    {
        'value': 'mi',
        'label': 'Maori',
        'native': 'Māori',
    },
    {
        'value': 'mk',
        'label': 'Macedonian',
        'native': 'Македонски',
    },
    {
        'value': 'ml',
        'label': 'Malayalam',
        'native': 'മലയാളം',
    },
    {
        'value': 'mn',
        'label': 'Mongolian',
        'native': 'Монгол',
    },
    {
        'value': 'mo',
        'label': 'Moldovan',
        'native': 'Moldovenească',
    },
    {
        'value': 'mr',
        'label': 'Marathi',
        'native': 'मराठी',
    },
    {
        'value': 'ms',
        'label': 'Malay',
        'native': 'Bahasa Melayu',
    },
    {
        'value': 'mt',
        'label': 'Maltese',
        'native': 'bil-Malti',
    },
    {
        'value': 'my',
        'label': 'Burmese',
        'native': 'မြန်မာစာ',
    },
    {
        'value': 'na',
        'label': 'Nauruan',
        'native': 'Dorerin Naoero',
    },
    {
        'value': 'nb',
        'label': 'Norwegian Bokmål',
        'native': 'Norsk bokmål',
    },
    {
        'value': 'nd',
        'label': 'North Ndebele',
        'native': 'Sindebele',
    },
    {
        'value': 'ne',
        'label': 'Nepali',
        'native': 'नेपाली',
    },
    {
        'value': 'ng',
        'label': 'Ndonga',
        'native': 'Oshiwambo',
    },
    {
        'value': 'nl',
        'label': 'Dutch',
        'native': 'Nederlands',
    },
    {
        'value': 'nn',
        'label': 'Norwegian Nynorsk',
        'native': 'Norsk nynorsk',
    },
    {
        'value': 'no',
        'label': 'Norwegian',
        'native': 'Norsk',
    },
    {
        'value': 'nr',
        'label': 'South Ndebele',
        'native': 'isiNdebele',
    },
    {
        'value': 'nv',
        'label': 'Navajo',
        'native': 'Diné bizaad',
    },
    {
        'value': 'ny',
        'label': 'Chichewa',
        'native': 'Chichewa',
    },
    {
        'value': 'oc',
        'label': 'Occitan',
        'native': 'Occitan',
    },
    {
        'value': 'oj',
        'label': 'Ojibwa',
        'native': 'ᐊᓂᔑᓈᐯᒧᐎᓐ',
    },
    {
        'value': 'om',
        'label': 'Oromo',
        'native': 'Oromoo',
    },
    {
        'value': 'or',
        'label': 'Oriya',
        'native': 'ଓଡ଼ିଆ',
    },
    {
        'value': 'os',
        'label': 'Ossetian / Ossetic',
        'native': 'Иронау',
    },
    {
        'value': 'pa',
        'label': 'Punjabi',
        'native': 'ਪੰਜਾਬੀ / पंजाबी / پنجابي',
    },
    {
        'value': 'pi',
        'label': 'Pali',
        'native': 'Pāli / पाऴि',
    },
    {
        'value': 'pl',
        'label': 'Polish',
        'native': 'Polski',
    },
    {
        'value': 'ps',
        'label': 'Pashto',
        'native': 'پښتو',
        'rtl': 1,
    },
    {
        'value': 'pt',
        'label': 'Portuguese',
        'native': 'Português',
    },
    {
        'value': 'qu',
        'label': 'Quechua',
        'native': 'Runa Simi',
    },
    {
        'value': 'rm',
        'label': 'Raeto Romance',
        'native': 'Rumantsch',
    },
    {
        'value': 'rn',
        'label': 'Kirundi',
        'native': 'Kirundi',
    },
    {
        'value': 'ro',
        'label': 'Romanian',
        'native': 'Română',
    },
    {
        'value': 'ru',
        'label': 'Russian',
        'native': 'Русский',
    },
    {
        'value': 'rw',
        'label': 'Rwandi',
        'native': 'Kinyarwandi',
    },
    {
        'value': 'sa',
        'label': 'Sanskrit',
        'native': 'संस्कृतम्',
    },
    {
        'value': 'sc',
        'label': 'Sardinian',
        'native': 'Sardu',
    },
    {
        'value': 'sd',
        'label': 'Sindhi',
        'native': 'सिनधि',
    },
    {
        'value': 'se',
        'label': 'Northern Sami',
        'native': 'Sámegiella',
    },
    {
        'value': 'sg',
        'label': 'Sango',
        'native': 'Sängö',
    },
    {
        'value': 'sh',
        'label': 'Serbo-Croatian',
        'native': 'Srpskohrvatski / Српскохрватски',
    },
    {
        'value': 'si',
        'label': 'Sinhalese',
        'native': 'සිංහල',
    },
    {
        'value': 'sk',
        'label': 'Slovak',
        'native': 'Slovenčina',
    },
    {
        'value': 'sl',
        'label': 'Slovenian',
        'native': 'Slovenščina',
    },
    {
        'value': 'sm',
        'label': 'Samoan',
        'native': 'Gagana Samoa',
    },
    {
        'value': 'sn',
        'label': 'Shona',
        'native': 'chiShona',
    },
    {
        'value': 'so',
        'label': 'Somalia',
        'native': 'Soomaaliga',
    },
    {
        'value': 'sq',
        'label': 'Albanian',
        'native': 'Shqip',
    },
    {
        'value': 'sr',
        'label': 'Serbian',
        'native': 'Српски',
    },
    {
        'value': 'ss',
        'label': 'Swati',
        'native': 'SiSwati',
    },
    {
        'value': 'st',
        'label': 'Southern Sotho',
        'native': 'Sesotho',
    },
    {
        'value': 'su',
        'label': 'Sundanese',
        'native': 'Basa Sunda',
    },
    {
        'value': 'sv',
        'label': 'Swedish',
        'native': 'Svenska',
    },
    {
        'value': 'sw',
        'label': 'Swahili',
        'native': 'Kiswahili',
    },
    {
        'value': 'ta',
        'label': 'Tamil',
        'native': 'தமிழ்',
    },
    {
        'value': 'te',
        'label': 'Telugu',
        'native': 'తెలుగు',
    },
    {
        'value': 'tg',
        'label': 'Tajik',
        'native': 'Тоҷикӣ',
    },
    {
        'value': 'th',
        'label': 'Thai',
        'native': 'ไทย / Phasa Thai',
    },
    {
        'value': 'ti',
        'label': 'Tigrinya',
        'native': 'ትግርኛ',
    },
    {
        'value': 'tk',
        'label': 'Turkmen',
        'native': 'Туркмен / تركمن',
    },
    {
        'value': 'tl',
        'label': 'Tagalog / Filipino',
        'native': 'Tagalog',
    },
    {
        'value': 'tn',
        'label': 'Tswana',
        'native': 'Setswana',
    },
    {
        'value': 'to',
        'label': 'Tonga',
        'native': 'Lea Faka-Tonga',
    },
    {
        'value': 'tr',
        'label': 'Turkish',
        'native': 'Türkçe',
    },
    {
        'value': 'ts',
        'label': 'Tsonga',
        'native': 'Xitsonga',
    },
    {
        'value': 'tt',
        'label': 'Tatar',
        'native': 'Tatarça',
    },
    {
        'value': 'tw',
        'label': 'Twi',
        'native': 'Twi',
    },
    {
        'value': 'ty',
        'label': 'Tahitian',
        'native': 'Reo Mā`ohi',
    },
    {
        'value': 'ug',
        'label': 'Uyghur',
        'native': 'Uyƣurqə / ئۇيغۇرچە',
    },
    {
        'value': 'uk',
        'label': 'Ukrainian',
        'native': 'Українська',
    },
    {
        'value': 'ur',
        'label': 'Urdu',
        'native': 'اردو',
    },
    {
        'value': 'uz',
        'label': 'Uzbek',
        'native': 'Ўзбек',
    },
    {
        'value': 've',
        'label': 'Venda',
        'native': 'Tshivenḓa',
    },
    {
        'value': 'vi',
        'label': 'Vietnamese',
        'native': 'Tiếng Việt',
    },
    {
        'value': 'vo',
        'label': 'Volapük',
        'native': 'Volapük',
    },
    {
        'value': 'wa',
        'label': 'Walloon',
        'native': 'Walon',
    },
    {
        'value': 'wo',
        'label': 'Wolof',
        'native': 'Wollof',
    },
    {
        'value': 'xh',
        'label': 'Xhosa',
        'native': 'isiXhosa',
    },
    {
        'value': 'yi',
        'label': 'Yiddish',
        'native': 'ייִדיש',
        'rtl': 1,
    },
    {
        'value': 'yo',
        'label': 'Yoruba',
        'native': 'Yorùbá',
    },
    {
        'value': 'za',
        'label': 'Zhuang',
        'native': 'Cuengh / Tôô / 壮语',
    },
    {
        'value': 'zh',
        'label': 'Chinese',
        'native': '中文',
    },
    {
        'value': 'zu',
        'label': 'Zulu',
        'native': 'isiZulu',
    },
];
