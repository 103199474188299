export default [
    {
        'value': 1,
        'label': {
            'sk': 'Pondelok',
            'en': 'Monday',
            'de': 'Montag',
            'cs': 'Pondělí',
        },
        'short': {
            'sk': 'Po',
            'en': 'Mo',
            'de': 'Mo',
            'cs': 'Po',
        },
    },
    {
        'value': 2,
        'label': {
            'sk': 'Utorok',
            'en': 'Tuesday',
            'de': 'Dienstag',
            'cs': 'Úterý',
        },
        'short': {
            'sk': 'Ut',
            'en': 'Tu',
            'de': 'Di',
            'cs': 'Út',
        },
    },
    {
        'value': 3,
        'label': {
            'sk': 'Streda',
            'en': 'Wednesday',
            'de': 'Mittwoch',
            'cs': 'Středa',
        },
        'short': {
            'sk': 'St',
            'en': 'We',
            'de': 'Mi',
            'cs': 'St',
        },
    },
    {
        'value': 4,
        'label': {
            'sk': 'Štvrtok',
            'en': 'Thursday',
            'de': 'Donnerstag',
            'cs': 'Čtvrtek',
        },
        'short': {
            'sk': 'Št',
            'en': 'Th',
            'de': 'Do',
            'cs': 'Čt',
        },
    },
    {
        'value': 5,
        'label': {
            'sk': 'Piatok',
            'en': 'Friday',
            'de': 'Freitag',
            'cs': 'Pátek',
        },
        'short': {
            'sk': 'Pi',
            'en': 'Fr',
            'de': 'Fr',
            'cs': 'Pá',
        },
    },
    {
        'value': 6,
        'label': {
            'sk': 'Sobota',
            'en': 'Saturday',
            'de': 'Samstag',
            'cs': 'Sobota',
        },
        'short': {
            'sk': 'So',
            'en': 'Sa',
            'de': 'Sa',
            'cs': 'So',
        },
    },
    {
        'value': 7,
        'label': {
            'sk': 'Nedeľa',
            'en': 'Sunday',
            'de': 'Sonntag',
            'cs': 'Neděle',
        },
        'short': {
            'sk': 'Ne',
            'en': 'Su',
            'de': 'So',
            'cs': 'Ne',
        },
    },
];
