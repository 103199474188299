export const sortBasic = {
    label: 'Zoradiť podľa ID',
    value: 'id',
};

export const sortTitle = {
    label: 'Zoradiť podľa Názvu',
    value: 'title',
};

export const sortName = {
    label: 'Zoradiť podľa Mena',
    value: 'fullName',
};

export const sortCreatedAt = {
    label: 'Zoradiť podľa dátumu vytvorenia',
    value: 'createdAt',
};

export const sortUpdatedAt = {
    label: 'Zoradiť podľa dátumu úpravy',
    value: 'updatedAt',
};

export const sortStage = {
    label: 'Zoradiť podľa kroku',
    value: 'progress',
};

