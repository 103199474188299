import React from 'react';
import {
    useMutation,
    useQuery,
    useSubscription,
} from '@apollo/client';
import TrendingGroups from './trendingGroups';
import { Col, Row, Spinner } from 'reactstrap';
import {
    TRENDING_GROUPS,
    TRENDING_GROUPS_SUBSCRIPTION,
    ADD_TRENDING_GROUP,
    UPDATE_TRENDING_GROUP,
    DELETE_TRENDING_GROUP,
} from 'queries';

export default function TrendingGroupsContainer() {
    const [addTrendingGroup] = useMutation(
        ADD_TRENDING_GROUP
    );
    const [updateTrendingGroup] = useMutation(
        UPDATE_TRENDING_GROUP
    );
    const [deleteTrendingGroup] = useMutation(
        DELETE_TRENDING_GROUP
    );

    const {
        data: trendingGroupsData,
        loading: trendingGroupsLoading,
        refetch: trendingGroupsRefetch,
    } = useQuery(TRENDING_GROUPS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(TRENDING_GROUPS_SUBSCRIPTION, {
        onData: () => {
            trendingGroupsRefetch();
        },
    });

    const onCreateTrendingGroup = (data) => {
        addTrendingGroup({
            variables: {
                title: data.title,
                order: parseInt(data.order),
                shuffle: data.shuffle,
                show: data.show,
                recipes: data.recipes.map(
                    (recipe) => recipe.id
                ),
                chefs: data.chefs.map((chef) => chef.id),
            },
        });
    };

    const onUpdateTrendingGroup = (data) => {
        updateTrendingGroup({
            variables: {
                id: data.id,
                title: data.title,
                order: parseInt(data.order),
                shuffle: data.shuffle,
                show: data.show,
                recipes: data.recipes.map(
                    (recipe) => recipe.id
                ),
                chefs: data.chefs.map((chef) => chef.id),
            },
        });
    };
    const onRemoveTrendingGroup = (id) => {
        deleteTrendingGroup({
            variables: {
                id: id,
            },
        });
    };

    if (trendingGroupsLoading) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    return (
        <Col>
            <TrendingGroups
                trendingGroups={
                    trendingGroupsData &&
                        trendingGroupsData.trendingGroups
                        ? trendingGroupsData.trendingGroups
                        : []
                }
                onCreateTrendingGroup={
                    onCreateTrendingGroup
                }
                onUpdateTrendingGroup={
                    onUpdateTrendingGroup
                }
                onRemoveTrendingGroup={
                    onRemoveTrendingGroup
                }
            />
        </Col>
    );
}
