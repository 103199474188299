import React from 'react';
import {
    useMutation,
    useQuery,
    useSubscription,
} from '@apollo/client';
import Ingredients from './list';
import { Col, Row, Spinner } from 'reactstrap';
import {
    INGREDIENTS,
    INGREDIENTS_SUBSCRIPTION,
    ADD_INGREDIENT,
    UPDATE_INGREDIENT,
    DELETE_INGREDIENT,
    GET_LANGUAGES,
    LANGUAGES_SUBSCRIPTION,
} from 'queries';

export default function IngredientsContainer() {
    const [addIngredient] = useMutation(ADD_INGREDIENT);
    const [updateIngredient] = useMutation(
        UPDATE_INGREDIENT
    );
    const [deleteIngredient] = useMutation(
        DELETE_INGREDIENT
    );

    const {
        data: ingredientsData,
        loading: ingredientsLoading,
        refetch: ingredientsRefetch,
    } = useQuery(INGREDIENTS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(INGREDIENTS_SUBSCRIPTION, {
        onData: () => {
            ingredientsRefetch();
        },
    });

    const {
        data: languagesData,
        loading: languagesLoading,
        refetch: languagesRefetch,
    } = useQuery(GET_LANGUAGES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(LANGUAGES_SUBSCRIPTION, {
        onData: () => {
            languagesRefetch();
        },
    });

    const onCreateIngredient = (data) => {
        addIngredient({
            variables: {
                units: data.units.map((unit) => unit.value),
                conversions: data.conversions.map(
                    (conversion) => ({
                        fromUnit: conversion.fromUnit,
                        toUnit: conversion.toUnit,
                        multiplier: parseFloat(
                            conversion.multiplier
                        ),
                    })
                ),
                translations: data.translations.map(
                    (translation) => ({
                        title: translation.translation,
                        languageId: translation.language.id,
                    })
                ),
            },
        });
    };

    const onUpdateIngredient = (data) => {
        updateIngredient({
            variables: {
                id: data.id,
                units: data.units.map((unit) => unit.value),
                conversions: data.conversions.map(
                    (conversion) => ({
                        fromUnit: conversion.fromUnit,
                        toUnit: conversion.toUnit,
                        multiplier: parseFloat(
                            conversion.multiplier
                        ),
                    })
                ),
                translations: data.translations.map(
                    (translation) => ({
                        title: translation.translation,
                        languageId: translation.language.id,
                    })
                ),
            },
        });
    };

    const onRemoveIngredient = (id) => {
        deleteIngredient({
            variables: {
                id: id,
            },
        });
    };

    if (ingredientsLoading || languagesLoading) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    return (
        <Col>
            <Ingredients
                ingredients={
                    ingredientsData &&
                        ingredientsData.ingredients
                        ? ingredientsData.ingredients
                        : []
                }
                languages={
                    languagesData &&
                        languagesData.languages
                        ? languagesData.languages.map((lang) => ({
                            ...lang,
                            label: lang.title,
                            value: lang.id,
                        }))
                        : []
                }
                onCreateIngredient={onCreateIngredient}
                onUpdateIngredient={onUpdateIngredient}
                onRemoveIngredient={onRemoveIngredient}
            />
        </Col>
    );
}
