import React, { useState } from 'react';
import {
    useQuery,
    useSubscription,
} from '@apollo/client';
import {
    COURIERS,
    COURIERS_SUBSCRIPTION,
    GET_ORDER,
    GET_ORDERS,
    ORDERS_SUBSCRIPTION,
    RUNNERS,
    RUNNERS_SUBSCRIPTION,
    STORES,
    STORES_SUBSCRIPTION,
    USERS,
    USERS_SUBSCRIPTION,
} from 'queries';
import {
    FormGroup,
    Label,
    Form,
    Col,
    Row,
    Table,
    Spinner,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
} from 'reactstrap';
import Select from 'react-select';
import { timestampToStringFNS } from 'utils';

export default function OrderStatistics() {
    const [showDetail, setShowDetail] = useState(false);
    const [chosenOrder, setChosenOrder] = useState(null);
    const [queryVariables, setQueryVariables] = useState(
        {}
    );

    const toggle = () => {
        setShowDetail(!showDetail);
    };

    const {
        data: ordersData,
        loading: ordersLoading,
        refetch: ordersRefetch,
    } = useQuery(GET_ORDERS, {
        variables: {
            storeId: queryVariables.store
                ? queryVariables.store.id
                : null,
            userId: queryVariables.user
                ? queryVariables?.user.id
                : null,
            courierId: queryVariables.courier
                ? queryVariables?.courier.id
                : null,
            done: queryVariables.done
                ? queryVariables.done
                : null,
            runnerId: queryVariables.runner
                ? queryVariables?.runner.id
                : null,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(ORDERS_SUBSCRIPTION, {
        onData: () => {
            ordersRefetch({
                storeId: queryVariables.store
                    ? queryVariables.store.id
                    : null,
                userId: queryVariables.user
                    ? queryVariables?.user.id
                    : null,
                courierId: queryVariables.courier
                    ? queryVariables?.courier.id
                    : null,
                done: queryVariables.done
                    ? queryVariables.done
                    : null,
                runnerId: queryVariables.runner
                    ? queryVariables?.runner.id
                    : null,
            });
        },
    });

    const {
        data: orderData,
        loading: orderLoading,
        refetch: orderRefetch,
    } = useQuery(GET_ORDER, {
        variables: {
            orderUUID: '',
        },
        fetchPolicy: 'network-only',
    });

    const {
        data: couriersData,
        loading: couriersLoading,
        refetch: couriersRefetch,
    } = useQuery(COURIERS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(COURIERS_SUBSCRIPTION, {
        onData: () => {
            couriersRefetch();
        },
    });

    const {
        data: runnersData,
        loading: runnersLoading,
        refetch: runnersRefetch,
    } = useQuery(RUNNERS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(RUNNERS_SUBSCRIPTION, {
        onData: () => {
            runnersRefetch();
        },
    });

    const {
        data: storesData,
        loading: storesLoading,
        refetch: storesRefetch,
    } = useQuery(STORES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(STORES_SUBSCRIPTION, {
        onData: () => {
            storesRefetch();
        },
    });

    const {
        data: usersData,
        loading: usersLoading,
        refetch: usersRefetch,
    } = useQuery(USERS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(USERS_SUBSCRIPTION, {
        onData: () => {
            usersRefetch();
        },
    });

    if (
        ordersLoading ||
        couriersLoading ||
        runnersLoading ||
        storesLoading ||
        usersLoading
    ) {
        return (
            <Col style={{ padding: '1em' }}>
                <Row>
                    <Spinner color="primary" />
                </Row>
            </Col>
        );
    }

    return (
        <Col>
            <Row>
                <Form>
                    <FormGroup check>
                        <Input
                            id="admin-active"
                            type="checkbox"
                            checked={queryVariables.done}
                            onChange={() => {
                                setQueryVariables({
                                    ...queryVariables,
                                    done: !queryVariables.done,
                                });
                            }}
                        />
                        <Label check htmlFor="admin-active">
                            Iba ukončené
                        </Label>
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="order-store">
                            Obchod
                        </Label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={true}
                            isSearchable={true}
                            name="order-store"
                            options={storesData.stores.map(
                                (store) => ({
                                    ...store,
                                    label: store.title,
                                    value: store.id,
                                })
                            )}
                            value={queryVariables.store}
                            onChange={(option) => {
                                setQueryVariables({
                                    ...queryVariables,
                                    store: option,
                                });
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="order-customer">
                            Zákazník
                        </Label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={true}
                            isSearchable={true}
                            name="order-customer"
                            options={usersData.users.map(
                                (user) => ({
                                    ...user,
                                    label: user.fullName,
                                    value: user.id,
                                })
                            )}
                            value={queryVariables.user}
                            onChange={(option) => {
                                setQueryVariables({
                                    ...queryVariables,
                                    user: option,
                                });
                            }}
                        />
                    </FormGroup>
                </Form>
                <FormGroup>
                    <Label htmlFor="order-runner">
                        Runner
                    </Label>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        isSearchable={true}
                        name="order-runner"
                        options={runnersData.runners.map(
                            (runner) => ({
                                ...runner,
                                label: runner.fullName,
                                value: runner.id,
                            })
                        )}
                        value={queryVariables.runner}
                        onChange={(option) => {
                            setQueryVariables({
                                ...queryVariables,
                                runner: option,
                            });
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="order-courier">
                        Kurier
                    </Label>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        isSearchable={true}
                        name="order-courier"
                        options={couriersData.couriers.map(
                            (courier) => ({
                                ...courier,
                                label: courier.fullName,
                                value: courier.id,
                            })
                        )}
                        value={queryVariables.courier}
                        onChange={(option) => {
                            setQueryVariables({
                                ...queryVariables,
                                courier: option,
                            });
                        }}
                    />
                </FormGroup>
            </Row>

            <Row>
                <Table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Zákazník</th>
                            <th>Obchod</th>
                            <th>Typ</th>
                            <th>Vytvorená dňa</th>
                            <th>Runner</th>
                            <th>Kurier</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ordersData.orders.map((order) => (
                            <tr
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    orderRefetch({
                                        orderUuid:
                                            order.orderUUID,
                                    });
                                    setChosenOrder(order);
                                    toggle();
                                }}
                            >
                                <td>{order.orderUUID}</td>
                                <td>
                                    {order.user.fullName}
                                </td>
                                <td>{`${order.store.title} (${order.store.location.address})`}</td>
                                <td>
                                    {order.deliveryType}
                                </td>
                                <td>
                                    {timestampToStringFNS(
                                        order.createdAt
                                    )}
                                </td>
                                <td>
                                    {order.runner?.fullName}
                                </td>
                                <td>
                                    {
                                        order.courier
                                            ?.fullName
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Row>

            <Modal isOpen={showDetail} toggle={toggle}>
                <ModalHeader>{`Detail objednávky ${chosenOrder?.orderUUID}`}</ModalHeader>
                <ModalBody>
                    {orderLoading && <Spinner />}
                    <Table>
                        <tbody>
                            <tr>
                                <td>Vytvorená dňa</td>
                                <td>
                                    {orderData &&
                                    orderData.order
                                        ? timestampToStringFNS(
                                              orderData
                                                  .order
                                                  .createdAt
                                          )
                                        : ''}
                                </td>
                            </tr>
                            <tr>
                                <td>Stav</td>
                                <td>
                                    {orderData &&
                                    orderData.order
                                        ? orderData.order
                                              .state
                                        : ''}
                                </td>
                            </tr>
                            {orderData &&
                                orderData.order &&
                                orderData.order
                                    .deliveredDate && (
                                    <tr>
                                        <td>
                                            Uzavretá dňa
                                        </td>
                                        <td>
                                            {timestampToStringFNS(
                                                orderData
                                                    .order
                                                    .deliveredDate
                                            )}
                                        </td>
                                    </tr>
                                )}
                            {orderData &&
                                orderData.order &&
                                orderData.order
                                    .canceledDate && (
                                    <tr>
                                        <td>Zrušená dňa</td>
                                        <td>
                                            {timestampToStringFNS(
                                                orderData
                                                    .order
                                                    .canceledDate
                                            )}
                                        </td>
                                    </tr>
                                )}
                            {orderData &&
                                orderData.order &&
                                orderData.order
                                    .canceledDate && (
                                    <tr>
                                        <td>Z dôvodu</td>
                                        <td>
                                            {orderData &&
                                            orderData.order
                                                ? orderData
                                                      .order
                                                      .cancelReason
                                                : 'Nezadané'}
                                        </td>
                                    </tr>
                                )}
                            {orderData &&
                                orderData.order &&
                                orderData.order
                                    .delayTime && (
                                    <tr>
                                        <td>{`Pozdržaná ${orderData.order.delayTime} minút z dôvodu ${orderData.order.delayReason}`}</td>
                                    </tr>
                                )}
                            <tr>
                                <td>Typ</td>
                                <td>
                                    {orderData &&
                                    orderData.order
                                        ? orderData.order
                                              .deliveryType
                                        : ''}
                                </td>
                            </tr>
                            <tr>
                                <td>Zákazník</td>
                                <td>
                                    {orderData &&
                                    orderData.order
                                        ? orderData.order
                                              .user.fullName
                                        : ''}
                                </td>
                            </tr>
                            <tr>
                                <td>Obchod</td>
                                <td>
                                    {orderData &&
                                    orderData.order
                                        ? `${orderData.order.store.title} (${orderData.order.storeAddress.address})`
                                        : ''}
                                </td>
                            </tr>
                            <tr>
                                <td>Runner</td>
                                <td>
                                    {orderData &&
                                    orderData.order &&
                                    orderData.order.runner
                                        ? orderData.order
                                              .runner
                                              .fullName
                                        : 'Bez runnera'}
                                </td>
                            </tr>

                            <tr>
                                <td>Kurier</td>
                                <td>
                                    {orderData &&
                                    orderData.order &&
                                    orderData.order.courier
                                        ? orderData.order
                                              .courier
                                              .fullName
                                        : 'Bez kuriera'}
                                </td>
                            </tr>
                            <tr>
                                <td>Predpokladaná cena</td>
                                <td>
                                    {orderData &&
                                    orderData.order
                                        ? orderData.order
                                              .estimatedPrice
                                        : ''}
                                </td>
                            </tr>
                            {orderData &&
                                orderData.order &&
                                orderData.order
                                    .realPrice && (
                                    <tr>
                                        <td>
                                            Skutočná cena
                                        </td>
                                        <td>
                                            {
                                                orderData
                                                    .order
                                                    .realPrice
                                            }
                                        </td>
                                    </tr>
                                )}
                            {orderData &&
                                orderData.order &&
                                orderData.order.review && (
                                    <tr>
                                        <td>Hodnotenie</td>
                                        <td>
                                            {orderData.order
                                                .review
                                                .score
                                                ? `${
                                                      orderData
                                                          .order
                                                          .review
                                                          .score /
                                                      2
                                                  }/5`
                                                : 'Bez skóre'}
                                        </td>
                                    </tr>
                                )}
                            {orderData &&
                                orderData.order &&
                                orderData.order.review && (
                                    <tr>
                                        <td>
                                            Komentár k
                                            hodnoteniu
                                        </td>
                                        <td>
                                            {orderData.order
                                                .review
                                                .message &&
                                            orderData.order
                                                .review
                                                .message
                                                .length > 0
                                                ? orderData
                                                      .order
                                                      .review
                                                      .message
                                                : 'Bez komentára'}
                                        </td>
                                    </tr>
                                )}
                        </tbody>
                    </Table>
                </ModalBody>
            </Modal>
        </Col>
    );
}
