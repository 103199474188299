import React, { useState, useEffect } from 'react';
import {
    FormGroup,
    Label,
    Input,
    Form,
    Button,
    Card,
    Row,
    Col,
    Table,
} from 'reactstrap';
import { useQuery } from '@apollo/client';
import Select from 'react-select';
import { GET_PRODUCT } from 'queries';
import ImageInput from 'components/imageInput';

const UNITS = [
    {
        value: 'piece',
        label: 'ks',
    },
    {
        value: 'kilo',
        label: 'kg',
    },
    {
        value: 'liter',
        label: 'l',
    },
];

export default function ProductEditForm(props) {
    const {
        allCategories,
        allIngredients,
        supportedLanguages,
        onClose,
        onSave,
        productId,
    } = props;

    const { data: productData } = useQuery(GET_PRODUCT, {
        variables: {
            id: productId,
        },
        fetchPolicy: 'network-only',
    });

    const createTranslationVariations = () => {
        let variations = {};
        for (
            let index = 0;
            index < supportedLanguages.length;
            index++
        ) {
            variations[supportedLanguages[index].id] = '';
        }
        return variations;
    };

    const [languageTab, setLanguageTab] = useState(
        supportedLanguages[0].id
    );

    const [title, setTitle] = useState(
        createTranslationVariations()
    );
    const [composition, setComposition] = useState(
        createTranslationVariations()
    );
    const [description, setDescription] = useState(
        createTranslationVariations()
    );
    const [allergens, setAllergens] = useState(
        createTranslationVariations()
    );

    const [unitsPerServing, setUnitsPerServing] =
        useState('');

    const [unitsPerStandardAmount, setUnitsPerStandardAmount] =
        useState('');

    const [nutritionalValues, setNutritionalValues] =
        useState({
            energy: {
                perUnit: '',
                perServing: '',
            },
            fats: {
                perUnit: '',
                perServing: '',
            },
            saturatedFats: {
                perUnit: '',
                perServing: '',
            },
            saccharides: {
                perUnit: '',
                perServing: '',
            },
            sugars: {
                perUnit: '',
                perServing: '1',
            },
            protein: {
                perUnit: '',
                perServing: '',
            },
            salts: {
                perUnit: '',
                perServing: '',
            },
        });

    const [unit, setUnit] = useState(null);
    const [price, setPrice] = useState(0);
    const [priceWithoutVat, setPriceWithoutVat] =
        useState(0);
    const [amount, setAmount] = useState(0);
    const [min, setMin] = useState(1);
    const [max, setMax] = useState(5);
    const [available, setAvailable] = useState(true);
    const [iconPath, setIconPath] = useState(null);
    const [image, setImage] = useState(null);
    const [categories, setCategories] = useState([]);
    const [ingredientType, setIngredientType] = useState(null);

    const remapBackendDataToFrontend = (
        translations,
        attribute
    ) => {
        let result = {};
        for (
            let index = 0;
            index < supportedLanguages.length;
            index++
        ) {
            let translation = translations.find(
                (translation) =>
                    translation.language.id ===
                    supportedLanguages[index].id
            );
            if (translation) {
                result[supportedLanguages[index].id] =
                    translation[attribute];
            } else {
                result[supportedLanguages[index].id] = '';
            }
        }
        return result;
    };

    useEffect(() => {
        if (productData && productData.productDetail) {
            setTitle(
                remapBackendDataToFrontend(
                    productData.productDetail.translations,
                    'title'
                )
            );
            setComposition(
                remapBackendDataToFrontend(
                    productData.productDetail.translations,
                    'composition'
                )
            );
            setDescription(
                remapBackendDataToFrontend(
                    productData.productDetail.translations,
                    'description'
                )
            );
            setAllergens(
                remapBackendDataToFrontend(
                    productData.productDetail.translations,
                    'allergens'
                )
            );
            setUnitsPerServing(
                productData.productDetail.unitsPerServing
            );
            setUnitsPerStandardAmount(
                productData.productDetail.unitsPerStandardAmount
            );
            setNutritionalValues({
                energy: {
                    perUnit:
                        productData.productDetail
                            .energyPerUnit,
                    perServing:
                        productData.productDetail
                            .energyPerServing,
                },
                fats: {
                    perUnit:
                        productData.productDetail
                            .fatsPerUnit,
                    perServing:
                        productData.productDetail
                            .fatsPerServing,
                },
                saturatedFats: {
                    perUnit:
                        productData.productDetail
                            .saturatedFatsPerUnit,
                    perServing:
                        productData.productDetail
                            .saturatedFatsPerServing,
                },
                saccharides: {
                    perUnit:
                        productData.productDetail
                            .saccharidesPerUnit,
                    perServing:
                        productData.productDetail
                            .saccharidesPerServing,
                },
                sugars: {
                    perUnit:
                        productData.productDetail
                            .sugarsPerUnit,
                    perServing:
                        productData.productDetail
                            .sugarsPerServing,
                },
                protein: {
                    perUnit:
                        productData.productDetail
                            .proteinPerUnit,
                    perServing:
                        productData.productDetail
                            .proteinPerServing,
                },
                salts: {
                    perUnit:
                        productData.productDetail
                            .saltsPerUnit,
                    perServing:
                        productData.productDetail
                            .saltsPerServing,
                },
            });
            setPrice(productData.productDetail.price);
            setPriceWithoutVat(
                productData.productDetail.priceWithoutVAT
            );
            setAmount(productData.productDetail.amount);
            setMin(productData.productDetail.min);
            setMax(productData.productDetail.max);
            setAvailable(
                productData.productDetail.available
            );
            setIconPath(productData.productDetail.iconPath);
            console.log(languageTab, allCategories, productData.productDetail.categories, productData.productDetail.categories.map(
                (category) => ({
                    ...allCategories.find((cat) => cat.id === category.id),
                    value: category.id,
                })
            ));
            setCategories(
                productData.productDetail.categories.map(
                    (category) => ({
                        ...allCategories.find((cat) => cat.id === category.id),
                        value: category.id,
                    })
                )
            );

            setIngredientType(
                allIngredients.find(
                    (ing) =>
                        ing.value ===
                        productData.productDetail.ingredient
                            ?.id
                )
            );
            setUnit(
                UNITS.find(
                    (unit) =>
                        unit.value ===
                        productData.productDetail.unit
                )
            );
        }
    }, [productData]);

    const setTranslation = (
        value,
        object,
        setData,
        languageID
    ) => {
        let newObject = { ...object };
        newObject[languageID] = value;
        setData({ ...newObject });
    };

    const possibleUnits = ingredientType
        ? ingredientType.units.map((unit) =>
            UNITS.find((u) => u.value === unit)
        )
        : [];

    return (
        <Card style={{ padding: '1em' }}>
            <Row
                style={{
                    marginBottom: '1em',
                    cursor: 'pointer',
                }}
            >
                {supportedLanguages.map((language) => (
                    <Col
                        key={language.id}
                        className="flex jc-center"
                        style={
                            languageTab === language.id
                                ? {
                                    fontWeight: 'bold',
                                    paddingBottom: '1em',
                                    borderBottom:
                                        '2px solid lavender',
                                }
                                : { paddingBottom: '1em' }
                        }
                        onClick={() => {
                            setLanguageTab(language.id);
                        }}
                    >
                        {language.label}
                    </Col>
                ))}
            </Row>
            <Row>
                <Form>
                    <ImageInput
                        iconPath={iconPath}
                        setIconPath={setIconPath}
                        image={image}
                        setImage={setImage}
                        size={'image'}
                    />

                    <FormGroup check>
                        <Input
                            id="product-available"
                            type="checkbox"
                            checked={available}
                            onChange={() => {
                                setAvailable(!available);
                            }}
                        />
                        <Label
                            check
                            htmlFor="product-available"
                        >
                            Dostupne
                        </Label>
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="product-title">
                            Názov
                        </Label>
                        <Input
                            id="product-title"
                            value={title[languageTab]}
                            onChange={(e) => {
                                setTranslation(
                                    e.target.value,
                                    title,
                                    setTitle,
                                    languageTab
                                );
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="product-price">
                            Cena na jednotku
                        </Label>
                        <Input
                            id="product-price"
                            value={price}
                            type="number"
                            onChange={(e) => {
                                setPrice(e.target.value);
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="product-price-without-vat">
                            Cena bez DPH
                        </Label>
                        <Input
                            id="product-price-without-vat"
                            value={priceWithoutVat}
                            type="number"
                            onChange={(e) => {
                                setPriceWithoutVat(
                                    e.target.value
                                );
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="product-category">
                            Kategórie *
                        </Label>
                        <Select
                            className="basic-multi-select"
                            classNamePrefix="select"
                            isMulti={true}
                            isClearable={true}
                            isSearchable={true}
                            name="product-category"
                            options={allCategories.map(
                                (c) => ({
                                    value: c.id,
                                    label: c.translations.find(
                                        (translation) =>
                                            translation
                                                .language
                                                .id ===
                                            languageTab
                                    )?.title,
                                })
                            )}
                            value={categories.map((category) => ({
                                ...category, label: category.translations.find(
                                    (translation) =>
                                        translation
                                            .language
                                            .id ===
                                        languageTab
                                )?.title ?? "Bez názvu v tomto jazyku"
                            }))}
                            onChange={(e) => {
                                setCategories(e);
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="product-ingerdient-type">
                            Typ ingrediencie *
                        </Label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={false}
                            isSearchable={true}
                            name="product-ingerdient-type"
                            options={allIngredients}
                            value={ingredientType}
                            onChange={(type) => {
                                setIngredientType(type);
                                if (
                                    unit &&
                                    !type.units.includes(
                                        unit.value
                                    )
                                ) {
                                    setUnit(null);
                                }
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="shop-language">
                            Jednotka produktu
                        </Label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={false}
                            isSearchable={false}
                            name="product-unit"
                            options={possibleUnits}
                            value={unit}
                            onChange={(e) => {
                                setUnit(e);
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="product-composition">
                            Zloženie
                        </Label>
                        <Input
                            id="product-composition"
                            value={composition[languageTab]}
                            onChange={(e) => {
                                setTranslation(
                                    e.target.value,
                                    composition,
                                    setComposition,
                                    languageTab
                                );
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="product-allergens">
                            Alergény
                        </Label>
                        <Input
                            id="product-allergens"
                            value={allergens[languageTab]}
                            onChange={(e) => {
                                setTranslation(
                                    e.target.value,
                                    allergens,
                                    setAllergens,
                                    languageTab
                                );
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="product-description">
                            Popis
                        </Label>
                        <Input
                            id="product-description"
                            value={description[languageTab]}
                            onChange={(e) => {
                                setTranslation(
                                    e.target.value,
                                    description,
                                    setDescription,
                                    languageTab
                                );
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="product-amount">
                            {`Množstvo (${unit?.label})`}
                        </Label>
                        <Input
                            id="product-amount"
                            type="number"
                            min={0}
                            value={amount}
                            onChange={(e) => {
                                setAmount(e.target.value);
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="product-min">
                            {`Minimum na nákup (${unit?.label})`}
                        </Label>
                        <Input
                            id="product-min"
                            type="number"
                            min={0}
                            value={min}
                            onChange={(e) => {
                                setMin(e.target.value);
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="product-max">
                            {`Maximum na nákup (${unit?.label})`}
                        </Label>
                        <Input
                            id="product-max"
                            type="number"
                            min={0}
                            value={max}
                            onChange={(e) => {
                                setMax(e.target.value);
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="product-portion">
                            Porcia
                        </Label>
                        <Input
                            id="product-portion"
                            value={unitsPerServing}
                            onChange={(e) => {
                                setUnitsPerServing(
                                    e.target.value
                                );
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="product-amount">
                            Základné množstvo
                        </Label>
                        <Input
                            id="product-amount"
                            value={unitsPerStandardAmount}
                            onChange={(e) => {
                                setUnitsPerStandardAmount(
                                    e.target.value
                                );
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label>Nutričné hodnoty</Label>
                        <Table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>{`${unitsPerStandardAmount}`}</th>
                                    <th>{`${unitsPerServing}`}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(
                                    nutritionalValues
                                ).map((value) => (
                                    <tr key={value}>
                                        <th>
                                            {
                                                supportedLanguages.find((lang) => lang.id === languageTab)[value]
                                            }
                                        </th>
                                        <td>
                                            <Input
                                                value={
                                                    nutritionalValues[
                                                        value
                                                    ]
                                                        .perUnit
                                                }
                                                id={`nutritionalValues-${value}-per-unit`}
                                                onChange={(
                                                    e
                                                ) => {
                                                    let newValues =
                                                    {
                                                        ...nutritionalValues,
                                                    };
                                                    newValues[
                                                        value
                                                    ].perUnit =
                                                        e.target.value;
                                                    setNutritionalValues(
                                                        {
                                                            ...newValues,
                                                        }
                                                    );
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                value={
                                                    nutritionalValues[
                                                        value
                                                    ]
                                                        .perServing
                                                }
                                                id={`nutritionalValues-${value}-per-portioin`}
                                                onChange={(
                                                    e
                                                ) => {
                                                    let newValues =
                                                    {
                                                        ...nutritionalValues,
                                                    };
                                                    newValues[
                                                        value
                                                    ].perServing =
                                                        e.target.value;
                                                    setNutritionalValues(
                                                        {
                                                            ...newValues,
                                                        }
                                                    );
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </FormGroup>
                </Form>
            </Row>

            <Row xs="2">
                <Col>
                    <Button
                        color="secondary"
                        block
                        onClick={onClose}
                    >
                        Zrušiť
                    </Button>
                </Col>
                <Col>
                    <Button
                        color="primary"
                        block
                        disabled={categories.length === 0 || !ingredientType || !unit}
                        onClick={() => {
                            onSave({
                                productId,
                                title,
                                composition,
                                description,
                                allergens,
                                unitsPerServing,
                                unitsPerStandardAmount,
                                ingredientType,
                                nutritionalValues,
                                unit,
                                price,
                                priceWithoutVat,
                                amount,
                                min,
                                max,
                                available,
                                image,
                                categories,
                            });
                        }}
                    >
                        Uložiť
                    </Button>
                </Col>
            </Row>
        </Card>
    );
}
