import React, { useState } from 'react';
import { Table, Button, Row, Col, Modal } from 'reactstrap';
import PreparationTimeForm from './form';

export default function PreparationTimes(props) {
    const {
        preparationTimes,
        onCreatePreparationTime,
        onUpdatePreparationTime,
        onRemovePreparationTime,
    } = props;

    const [editPreparationTime, setEditPreparationTime] =
        useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const toggle = () => {
        setModalOpen(!modalOpen);
    };

    const sortedPreparationTimes = [...preparationTimes]
        .sort((i1, i2) => (i1.title < i2.title ? -1 : 1));

    return (
        <>
            <Table hover>
                <thead>
                    <tr>
                        <th width={'33%'}>Názov</th>
                        <th width={'33%'}>Poradie</th>
                        <th width={'33%'}>Akcia</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key="add-preparationTime-btn">
                        <td
                            colSpan={3}
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={toggle}
                        >
                            + Doba prípravy
                        </td>
                    </tr>
                    {sortedPreparationTimes.map((preparationTime) => (
                        <tr
                            key={preparationTime.id}
                            style={{
                                cursor: 'pointer',
                            }}
                        >
                            <td>{preparationTime.title}</td>
                            <td>{preparationTime.order}</td>

                            <td>
                                <Row xs="2">
                                    <Col>
                                        <Button
                                            color="warning"
                                            block
                                            onClick={() => {
                                                setEditPreparationTime(
                                                    preparationTime
                                                );
                                                toggle();
                                            }}
                                        >
                                            Upraviť
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button
                                            color="danger"
                                            block
                                            onClick={() => {
                                                if (
                                                    window.confirm(
                                                        'Ste si isty?'
                                                    )
                                                ) {
                                                    onRemovePreparationTime(
                                                        preparationTime.id
                                                    );
                                                }
                                            }}
                                        >
                                            Odstrániť
                                        </Button>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Modal
                isOpen={modalOpen}
                toggle={() => {
                    if (modalOpen) {
                        setEditPreparationTime(null);
                    }
                    toggle();
                }}
            >
                <PreparationTimeForm
                    onClose={() => {
                        setEditPreparationTime(null);
                        toggle();
                    }}
                    onSave={(data) => {
                        if (editPreparationTime) {
                            onUpdatePreparationTime(data);
                        } else {
                            onCreatePreparationTime(data);
                        }
                        toggle();
                    }}
                    preparationTime={editPreparationTime}
                />
            </Modal>
        </>
    );
}
