import { gql } from '@apollo/client';

export const PRODUCT_CATEGORIES_SUBSCRIPTION = gql`
    subscription productCategoriesSubscription {
        productCategoriesSubscription
    }
`;

export const PRODUCT_CATEGORIES = gql`
  query productCategories {
    productCategories {
      id
      iconPath
      order
      topLevelCategory
      translations {
        title
        language {
          id
          title
          languageCode
        }
      }
      subcategories {
        id
      }
      supercategories {
        id
      }
    }
  }
`;

export const ADD_PRODUCT_CATEGORY = gql`
mutation addProductCategory(
  $order: Int!
  $translations: [ProductCategoryTranslationInput!]!
  $icon: String
  $subcategories: [Int!]
  $supercategories: [Int!]
) {
    addProductCategory(
    order: $order, 
    translations: $translations, 
    icon: $icon, 
    subcategories: $subcategories, 
    supercategories: $supercategories
    ) {
      id
    }
  }
`;

export const UPDATE_PRODUCT_CATEGORY = gql`
mutation updateProductCategory(
  $id: Int!
  $order: Int
  $translations: [ProductCategoryTranslationInput!]
  $icon: String
  $subcategories: [Int!]
  $supercategories: [Int!]
) {
    updateProductCategory(
    id: $id, 
    order: $order, 
    translations: $translations, 
    icon: $icon, 
    subcategories: $subcategories, 
    supercategories: $supercategories
    ) {
      id
    }
  }
`;

export const DELETE_PRODUCT_CATEGORY = gql`
mutation deleteProductCategory($id: Int!, $newId: Int!) {
    deleteProductCategory(id: $id, newId: $newId) {
      id
    }
  }
`;
