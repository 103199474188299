import React from 'react';
import {
    useMutation,
    useQuery,
    useSubscription,
} from '@apollo/client';
import ProductCategories from './list';
import { Col, Row, Spinner } from 'reactstrap';
import {
    PRODUCT_CATEGORIES,
    PRODUCT_CATEGORIES_SUBSCRIPTION,
    ADD_PRODUCT_CATEGORY,
    UPDATE_PRODUCT_CATEGORY,
    DELETE_PRODUCT_CATEGORY,
} from 'queries';

export default function ProductCategoriesContainer() {
    const [addProductCategory] = useMutation(ADD_PRODUCT_CATEGORY);
    const [updateProductCategory] = useMutation(
        UPDATE_PRODUCT_CATEGORY
    );
    const [deleteProductCategory] = useMutation(
        DELETE_PRODUCT_CATEGORY
    );

    const {
        data: productCategoriesData,
        loading: productCategoriesLoading,
        refetch: productCategoriesRefetch,
    } = useQuery(PRODUCT_CATEGORIES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(PRODUCT_CATEGORIES_SUBSCRIPTION, {
        onData: () => {
            productCategoriesRefetch();
        },
    });

    const onCreateProductCategory = (data) => {
        addProductCategory({
            variables: {
                order: data.order,
                icon: data.icon,
                supercategories: data.superCategories,
                translations: data.translations.map(
                    (translation) => ({
                        title: translation.title,
                        languageId: translation.language.id,
                    })
                ),
            },
        });
    };

    const onUpdateProductCategory = (data) => {
        let variables = {
            id: data.id,
            order: data.order,
            translations: data.translations.map(
                (translation) => ({
                    title: translation.title,
                    languageId: translation.language.id,
                })
            ),
        }
        if (data.icon) {
            variables.icon = data.icon;
        }
        if (data.superCategories) {
            variables.supercategories = data.superCategories;
        }
        updateProductCategory({
            variables,
        });
    };

    const onRemoveProductCategory = (id, newId) => {
        deleteProductCategory({
            variables: {
                id,
                newId,
            },
        });
    };

    if (productCategoriesLoading) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    return (
        <Col>
            <ProductCategories
                productCategories={
                    productCategoriesData &&
                        productCategoriesData.productCategories
                        ? productCategoriesData.productCategories
                        : []
                }
                onCreateProductCategory={onCreateProductCategory}
                onUpdateProductCategory={onUpdateProductCategory}
                onRemoveProductCategory={onRemoveProductCategory}
            />
        </Col>
    );
}
