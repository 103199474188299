export const testing =
    process.env.REACT_APP_TESTING === 'true';
export const port = testing
    ? 4000
    : parseInt(process.env.REACT_APP_BACKEND_PORT);
export const backendURL = testing
    ? 'localhost'
    : process.env.REACT_APP_BACKEND_BASE_URL;

const protectedREST = `https://${backendURL}:${port}`;
const localREST = `http://localhost:4000`;

const protectedSocket = `wss://${backendURL}:${port}`;
const localSocket = `ws://localhost:4000`;

export const REST_URL = testing ? localREST : protectedREST;
export const SOCKET_URL = testing
    ? localSocket
    : protectedSocket;
