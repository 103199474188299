import React from 'react';
import { UPDATE_ADMIN, GET_ADMIN, GET_LANGUAGES, LANGUAGES_SUBSCRIPTION } from 'queries';
import AdminForm from './form';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { Spinner } from 'reactstrap';

export default function AdminEdit(props) {
    const { adminId, onSave, onClose } = props;

    const [updateAdmin] = useMutation(UPDATE_ADMIN);

    const {
        data: languagesData,
        refetch: languagesRefetch,
    } = useQuery(GET_LANGUAGES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(LANGUAGES_SUBSCRIPTION, {
        onData: () => {
            languagesRefetch();
        },
    });

    const { data: adminData, loading: adminLoading } =
        useQuery(GET_ADMIN, {
            variables: {
                adminId: adminId,
            },
            fetchPolicy: 'network-only',
        });

    const updateAdminFunc = (data) => {

        let dataToSave = {
            id: adminId,
            active: data.active,
            name: data.name,
            surname: data.surname,
            languageId: data.languageId,
            settings: data.settings,
        };
        if (data.email) {
            dataToSave = {
                ...dataToSave,
                email: data.email,
            };
        }
        if (data.password) {
            dataToSave = {
                ...dataToSave,
                password: data.password,
            };
        }
        if (data.phoneNumber) {
            dataToSave = {
                ...dataToSave,
                phoneNumber: data.phoneNumber,
            };
        }
        if (data.image) {
            dataToSave = {
                ...dataToSave,
                icon: data.image,
            };
        }

        updateAdmin({
            variables: dataToSave,
        }).then(() => {
            onSave();
        });
    };

    if (adminLoading) {
        return <Spinner color="primary"></Spinner>;
    }

    const languageOptions = languagesData && languagesData.languages ?
        [...languagesData.languages]
            .sort((lang1, lang2) => lang1.languageCode > lang2.languageCode ? -1 : 1)
            .map((lang) => ({
                ...lang,
                value: lang.id,
                label: lang.languageCode
            })) : [];

    return (
        <AdminForm
            languages={languageOptions}
            adminId={adminId}
            admin={adminData.admin}
            onSave={updateAdminFunc}
            onClose={onClose}
        />
    );
}
