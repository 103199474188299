import { gql } from '@apollo/client';

export const ADMIN_DATA_SUBSCRIPTION = gql`
    subscription adminDataSubscription {
        adminDataSubscription
    }
`;

export const GET_MY_DATA = gql`
    query {
        adminGetMyData {
            id
            active
            verified
            email
            name
            surname
                language{
                id
                languageCode
            }
            fullName
            settings
            iconPath
        }
    }
`;

export const UPDATE_PROFILE = gql`
    mutation adminUpdateProfile(
        $name: String
        $surname: String
        $phoneNumber: String
        $icon: String

        $languageId: Int
    ) {
        adminUpdateProfile(
            name: $name
            surname: $surname
            phoneNumber: $phoneNumber
            languageId: $languageId
            icon: $icon
        ) {
            admin {
                id
                iconPath
            }
        }
    }
`;

export const ADMIN_CHANGE_EMAIL = gql`
    mutation adminChangeEmail(
        $email: String!
        $password: String!
    ) {
        adminChangeEmail(
            email: $email
            password: $password
        ) {
            admin {
                id
            }
        }
    }
`;

export const ADMIN_CHANGE_PASSWORD = gql`
    mutation adminChangePassword(
        $newPass: String!
        $password: String!
    ) {
        adminChangePassword(
            newPass: $newPass
            password: $password
        ) {
            admin {
                id
            }
        }
    }
`;
