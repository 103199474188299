import React, { useState } from 'react';
import { Table, Button, Row, Col, Modal } from 'reactstrap';
import MealTypeForm from './form';

export default function MealTypes(props) {
    const {
        mealTypes,
        onCreateMealType,
        onUpdateMealType,
        onRemoveMealType,
    } = props;

    const [editMealType, setEditMealType] =
        useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const toggle = () => {
        setModalOpen(!modalOpen);
    };

    const sortedMealTypes = [...mealTypes]
        .sort((i1, i2) => (i1.title < i2.title ? -1 : 1));

    return (
        <>
            <Table hover>
                <thead>
                    <tr>
                        <th width={'33%'}>Názov</th>
                        <th width={'33%'}>Poradie</th>
                        <th width={'33%'}>Akcia</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key="add-mealType-btn">
                        <td
                            colSpan={3}
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={toggle}
                        >
                            + Typ jedla
                        </td>
                    </tr>
                    {sortedMealTypes.map((mealType) => (
                        <tr
                            key={mealType.id}
                            style={{
                                cursor: 'pointer',
                            }}
                        >
                            <td>{mealType.title}</td>
                            <td>{mealType.order}</td>

                            <td>
                                <Row xs="2">
                                    <Col>
                                        <Button
                                            color="warning"
                                            block
                                            onClick={() => {
                                                setEditMealType(
                                                    mealType
                                                );
                                                toggle();
                                            }}
                                        >
                                            Upraviť
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button
                                            color="danger"
                                            block
                                            onClick={() => {
                                                if (
                                                    window.confirm(
                                                        'Ste si isty?'
                                                    )
                                                ) {
                                                    onRemoveMealType(
                                                        mealType.id
                                                    );
                                                }
                                            }}
                                        >
                                            Odstrániť
                                        </Button>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Modal
                isOpen={modalOpen}
                toggle={() => {
                    if (modalOpen) {
                        setEditMealType(null);
                    }
                    toggle();
                }}
            >
                <MealTypeForm
                    onClose={() => {
                        setEditMealType(null);
                        toggle();
                    }}
                    onSave={(data) => {
                        if (editMealType) {
                            onUpdateMealType(data);
                        } else {
                            onCreateMealType(data);
                        }
                        toggle();
                    }}
                    mealType={editMealType}
                />
            </Modal>
        </>
    );
}
