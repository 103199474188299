import React, { useRef, useEffect } from 'react';
import {
    Form,
    FormGroup,
    Label,
    Input,
    Table,
} from 'reactstrap';
import Select from 'react-select';
import ImageInput from 'components/imageInput';

export default function ShopProfile(props) {
    const {
        title,
        setTitle,
        phoneNumber,
        setPhoneNumber,
        siteLanguage,
        setSiteLanguage,
        owner,
        setOwner,
        ownerContact,
        setOwnerContact,
        dph,
        setDph,
        ico,
        setIco,
        dic,
        setDic,
        icDic,
        setIcDic,
        languages,
        setLanguages,
        location,
        setLocation,
        iconPath,
        setIconPath,
        image,
        setImage,
        deliveryOption,
        setDeliveryOption,
        drivethroughOption,
        setDrivethroughOption,
        pickupOption,
        setPickupOption,
        preparationTime,
        setPreparationTime,
        openHours,
        setOpenHours,
        deliveryHours,
        setDeliveryHours,
        supportedLanguagesOptions,
        siteLanguagesOptions,
        currentAdmin,
    } = props;

    const autoCompleteRef = useRef();
    const inputRef = useRef();

    const options = {
        componentRestrictions: { country: 'sk' },
        fields: [
            'address_components',
            'geometry',
            'icon',
            'name',
        ],
    };
    useEffect(() => {
        /*autoCompleteRef.current =
            new window.google.maps.places.Autocomplete(
                inputRef.current,
                options
            );

        autoCompleteRef.current.addListener(
            'place_changed',
            async function () {
                const place =
                    await autoCompleteRef.current.getPlace();
                setLocation({
                    address: place.name,
                    latitude: parseFloat(
                        place.geometry.location.lat()
                    ),
                    longitude: parseFloat(
                        place.geometry.location.lng()
                    ),
                });
            }
        );*/
    }, []);

    return (
        <Form>
            <ImageInput
                iconPath={iconPath}
                setIconPath={setIconPath}
                image={image}
                setImage={setImage}
                size={'image'}
            />

            <FormGroup>
                <Label htmlFor="shop-title">Názov</Label>
                <Input
                    id="shop-title"
                    value={title}
                    onChange={(e) => {
                        setTitle(e.target.value);
                    }}
                />
            </FormGroup>

            <FormGroup>
                <Label htmlFor="shop-phone-number">
                    Číslo na prevádzku
                </Label>
                <Input
                    id="shop-phone-number"
                    value={phoneNumber}
                    onChange={(e) => {
                        setPhoneNumber(e.target.value);
                    }}
                />
            </FormGroup>

            <FormGroup>
                <Label htmlFor="shop-dph">DPH</Label>
                <Input
                    id="shop-dph"
                    value={dph}
                    onChange={(e) => {
                        setDph(e.target.value);
                    }}
                />
            </FormGroup>

            <FormGroup>
                <Label htmlFor="shop-ico">IČO</Label>
                <Input
                    id="shop-ico"
                    value={ico}
                    onChange={(e) => {
                        setIco(e.target.value);
                    }}
                />
            </FormGroup>

            <FormGroup>
                <Label htmlFor="shop-dic">DIČ</Label>
                <Input
                    id="shop-dic"
                    value={dic}
                    onChange={(e) => {
                        setDic(e.target.value);
                    }}
                />
            </FormGroup>

            <FormGroup>
                <Label htmlFor="shop-ic-dic">IČ DIČ</Label>
                <Input
                    id="shop-ic-dic"
                    value={icDic}
                    onChange={(e) => {
                        setIcDic(e.target.value);
                    }}
                />
            </FormGroup>

            <FormGroup>
                <Label htmlFor="shop-address">Adresa</Label>
                {location.latitude && (
                    <p>{`${location.address} ${location.latitude} ${location.longitude}`}</p>
                )}
                {!location.latitude && (
                    <p>Pridajte adresu</p>
                )}
                <input
                    ref={inputRef}
                    id="shop-address"
                    className="form-control"
                />
            </FormGroup>

            <FormGroup>
                <Label htmlFor="shop-owner">Majiteľ</Label>
                <Input
                    id="shop-owner"
                    value={owner}
                    onChange={(e) => {
                        setOwner(e.target.value);
                    }}
                />
            </FormGroup>

            <FormGroup>
                <Label htmlFor="shop-owner-contact">
                    Kontakt na majiteľa
                </Label>
                <Input
                    id="shop-owner-contact"
                    value={ownerContact}
                    onChange={(e) => {
                        setOwnerContact(e.target.value);
                    }}
                />
            </FormGroup>

            <FormGroup>
                <Label htmlFor="shop-language">
                    Jazyk stránky
                </Label>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={false}
                    isSearchable={true}
                    name="shop-language"
                    options={siteLanguagesOptions}
                    value={siteLanguage}
                    onChange={(language) => {
                        setSiteLanguage(language);
                        const containsSiteLanguage =
                            languages.find(
                                (lang) =>
                                    lang.value ===
                                    language.value
                            );
                        if (!containsSiteLanguage) {
                            setLanguages([
                                language,
                                ...languages,
                            ]);
                        }
                    }}
                />
            </FormGroup>

            <FormGroup>
                <Label htmlFor="shop-supported-languages">
                    Podporované jazyky
                </Label>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={false}
                    isSearchable={true}
                    isMulti
                    name="shop-supported-languages"
                    options={supportedLanguagesOptions}
                    value={languages}
                    onChange={(languages) => {
                        const containsSiteLanguage =
                            languages.find(
                                (lang) =>
                                    lang.value ===
                                    siteLanguage.value
                            );
                        if (containsSiteLanguage) {
                            setLanguages(languages);
                        } else {
                            setLanguages([
                                siteLanguage,
                                ...languages,
                            ]);
                        }
                    }}
                />
            </FormGroup>

            <hr />

            <FormGroup check>
                <Input
                    id="shop-delivery-option"
                    type="checkbox"
                    checked={deliveryOption}
                    onChange={() => {
                        setDeliveryOption(!deliveryOption);
                    }}
                />
                <Label check htmlFor="shop-delivery-option">
                    Donáška
                </Label>
            </FormGroup>

            <FormGroup check>
                <Input
                    id="shop-drivethrough-option"
                    type="checkbox"
                    checked={drivethroughOption}
                    onChange={() => {
                        setDrivethroughOption(
                            !drivethroughOption
                        );
                    }}
                />
                <Label
                    check
                    htmlFor="shop-drivethrough-option"
                >
                    Drive through
                </Label>
            </FormGroup>

            <FormGroup check>
                <Input
                    id="shop-pickup-option"
                    type="checkbox"
                    checked={pickupOption}
                    onChange={() => {
                        setPickupOption(!pickupOption);
                    }}
                />
                <Label check htmlFor="shop-pickup-option">
                    Osobný odber
                </Label>
            </FormGroup>

            <FormGroup>
                <Label htmlFor="shop-average-processing-time">
                    {`Priemerný čas spracovania donášky - ${preparationTime} min`}
                </Label>
                <Input
                    id="shop-average-processing-time"
                    type="range"
                    min="1"
                    max="120"
                    value={preparationTime}
                    onChange={(e) => {
                        setPreparationTime(e.target.value);
                    }}
                />
            </FormGroup>

            <FormGroup>
                <Label htmlFor="shop-open-hours">
                    Otváracie hodiny
                </Label>

                <Table>
                    <thead>
                        <tr>
                            <th>Deň</th>
                            <th>Od</th>
                            <th>Do</th>
                            <th>Zavreté</th>
                        </tr>
                    </thead>
                    <tbody>
                        {openHours.map((day) => (
                            <tr key={day.day.value}>
                                <td>
                                    {
                                        day.day.label[
                                            currentAdmin
                                                .siteLanguage
                                        ]
                                    }
                                </td>
                                <td>
                                    <Input
                                        id={`time-open-${day.day.value}-from`}
                                        type="time"
                                        value={day.from}
                                        disabled={
                                            day.closed
                                        }
                                        onChange={(e) => {
                                            setOpenHours(
                                                openHours.map(
                                                    (d) => {
                                                        if (
                                                            d
                                                                .day
                                                                .value !==
                                                            day
                                                                .day
                                                                .value
                                                        ) {
                                                            return d;
                                                        }
                                                        return {
                                                            ...day,
                                                            from: e
                                                                .target
                                                                .value,
                                                            closed: day.closed
                                                                ? day.closed
                                                                : e
                                                                      .target
                                                                      .value
                                                                      .length ===
                                                                  0,
                                                        };
                                                    }
                                                )
                                            );
                                        }}
                                    />
                                </td>
                                <td>
                                    <Input
                                        id={`time-open-${day.day.value}}-to`}
                                        type="time"
                                        disabled={
                                            day.closed
                                        }
                                        value={day.to}
                                        onChange={(e) => {
                                            setOpenHours(
                                                openHours.map(
                                                    (d) => {
                                                        if (
                                                            d
                                                                .day
                                                                .value !==
                                                            day
                                                                .day
                                                                .value
                                                        ) {
                                                            return d;
                                                        }
                                                        return {
                                                            ...day,
                                                            to: e
                                                                .target
                                                                .value,
                                                            closed: day.closed
                                                                ? day.closed
                                                                : e
                                                                      .target
                                                                      .value
                                                                      .length ===
                                                                  0,
                                                        };
                                                    }
                                                )
                                            );
                                        }}
                                    />
                                </td>
                                <td>
                                    <Input
                                        id={`time-open-${day.day.value}}`}
                                        type="checkbox"
                                        checked={day.closed}
                                        onChange={(e) => {
                                            setOpenHours(
                                                openHours.map(
                                                    (d) => {
                                                        if (
                                                            d
                                                                .day
                                                                .value !==
                                                            day
                                                                .day
                                                                .value
                                                        ) {
                                                            return d;
                                                        }
                                                        return {
                                                            ...day,
                                                            closed: !d.closed,
                                                            to:
                                                                day.closed &&
                                                                day
                                                                    .to
                                                                    .length ===
                                                                    0
                                                                    ? '19:00'
                                                                    : day.to,
                                                            from:
                                                                day.closed &&
                                                                day
                                                                    .from
                                                                    .length ===
                                                                    0
                                                                    ? '07:00'
                                                                    : day.from,
                                                        };
                                                    }
                                                )
                                            );
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </FormGroup>

            <FormGroup>
                <Label htmlFor="shop-open-hours">
                    Doručovacie hodiny
                </Label>
                <Table>
                    <thead>
                        <tr>
                            <th>Deň</th>
                            <th>Od</th>
                            <th>Do</th>
                            <th>Zavreté</th>
                        </tr>
                    </thead>
                    <tbody>
                        {deliveryHours.map((day) => (
                            <tr key={day.day.value}>
                                <td>
                                    {
                                        day.day.label[
                                            currentAdmin
                                                .siteLanguage
                                        ]
                                    }
                                </td>
                                <td>
                                    <Input
                                        id={`time-delivery-${day.day.value}}-from`}
                                        disabled={
                                            day.closed
                                        }
                                        type="time"
                                        value={day.from}
                                        onChange={(e) => {
                                            setDeliveryHours(
                                                deliveryHours.map(
                                                    (d) => {
                                                        if (
                                                            d
                                                                .day
                                                                .value !==
                                                            day
                                                                .day
                                                                .value
                                                        ) {
                                                            return d;
                                                        }
                                                        return {
                                                            ...day,
                                                            from: e
                                                                .target
                                                                .value,
                                                            closed: day.closed
                                                                ? day.closed
                                                                : e
                                                                      .target
                                                                      .value
                                                                      .length ===
                                                                  0,
                                                        };
                                                    }
                                                )
                                            );
                                        }}
                                    />
                                </td>
                                <td>
                                    <Input
                                        id={`time-delivery-${day.day.value}}-to`}
                                        type="time"
                                        disabled={
                                            day.closed
                                        }
                                        value={day.to}
                                        onChange={(e) => {
                                            setDeliveryHours(
                                                deliveryHours.map(
                                                    (d) => {
                                                        if (
                                                            d
                                                                .day
                                                                .value !==
                                                            day
                                                                .day
                                                                .value
                                                        ) {
                                                            return d;
                                                        }
                                                        return {
                                                            ...day,
                                                            to: e
                                                                .target
                                                                .value,
                                                            closed: day.closed
                                                                ? day.closed
                                                                : e
                                                                      .target
                                                                      .value
                                                                      .length ===
                                                                  0,
                                                        };
                                                    }
                                                )
                                            );
                                        }}
                                    />
                                </td>
                                <td>
                                    <Input
                                        id={`time-delivery-${day.day.value}}`}
                                        type="checkbox"
                                        checked={day.closed}
                                        onChange={() => {
                                            setDeliveryHours(
                                                deliveryHours.map(
                                                    (d) => {
                                                        if (
                                                            d
                                                                .day
                                                                .value !==
                                                            day
                                                                .day
                                                                .value
                                                        ) {
                                                            return d;
                                                        }
                                                        return {
                                                            ...day,
                                                            closed: !d.closed,
                                                            to:
                                                                day.closed &&
                                                                day
                                                                    .to
                                                                    .length ===
                                                                    0
                                                                    ? '19:00'
                                                                    : day.to,
                                                            from:
                                                                day.closed &&
                                                                day
                                                                    .from
                                                                    .length ===
                                                                    0
                                                                    ? '07:00'
                                                                    : day.from,
                                                        };
                                                    }
                                                )
                                            );
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </FormGroup>
        </Form>
    );
}
