import { gql } from '@apollo/client';

export const RUNNERS_SUBSCRIPTION = gql`
    subscription runnersSubscription {
        runnersSubscription
    }
`;

export const RUNNERS = gql`
    query runners {
        runners {
            active
            verified
            fullName
            email
            id
        }
    }
`;

export const GET_RUNNER = gql`
    query runners($runnerId: Int!) {
        runner(id: $runnerId) {
            active
            deactivationDate
            email
            iban
            iconPath
            id
            name
            phoneNumber
            siteLanguage
            store {
                id
                title
                location {
                    address
                }
            }
            surname
            verified
            updatedAt
            createdAt
        }
    }
`;

export const REGISTER_RUNNER = gql`
    mutation registerRunner(
        $email: String!
        $name: String!
        $surname: String!
        $iban: String!
        $phoneNumber: String!
        $siteLanguage: LanguageEnum!
        $store: Int!
        $icon: String
        $password: String
    ) {
        registerRunner(
            email: $email
            name: $name
            surname: $surname
            iban: $iban
            phoneNumber: $phoneNumber
            siteLanguage: $siteLanguage
            store: $store
            icon: $icon
            password: $password
        ) {
            id
        }
    }
`;

export const UPDATE_RUNNER = gql`
    mutation updateRunner(
        $updateRunnerId: Int!
        $active: Boolean
        $verified: Boolean
        $email: String
        $password: String
        $name: String
        $iban: String
        $surname: String
        $phoneNumber: String
        $siteLanguage: LanguageEnum
        $icon: String
        $store: Int
    ) {
        updateRunner(
            id: $updateRunnerId
            active: $active
            verified: $verified
            email: $email
            password: $password
            name: $name
            iban: $iban
            surname: $surname
            phoneNumber: $phoneNumber
            siteLanguage: $siteLanguage
            icon: $icon
            store: $store
        ) {
            id
        }
    }
`;

export const CHANGE_RUNNER_ACTIVE = gql`
    mutation changeRunnerActive(
        $changeRunnerActiveId: Int
    ) {
        changeRunnerActive(id: $changeRunnerActiveId) {
            id
        }
    }
`;
