import { gql } from '@apollo/client';

export const STORES_SUBSCRIPTION = gql`
    subscription storesSubscription {
        storesSubscription
    }
`;

export const STORES = gql`
    query stores {
        stores {
            id
            title
            location {
                address
            }
            owner
            ownerContact
            active
            verified
        }
    }
`;

export const GET_STORE = gql`
    query store($storeId: Int!) {
        store(id: $storeId) {
            active
            deactivationDate
            details {
                pickup
                delivery
                driveThrough
                preparationTime
                openHoursMon {
                    from
                    to
                    closed
                }
                openHoursTue {
                    from
                    to
                    closed
                }
                openHoursWen {
                    from
                    to
                    closed
                }
                openHoursThu {
                    from
                    to
                    closed
                }
                openHoursFri {
                    from
                    to
                    closed
                }
                openHoursSat {
                    from
                    to
                    closed
                }
                openHoursSun {
                    from
                    to
                    closed
                }
                deliveryHoursMon {
                    from
                    to
                    closed
                }
                deliveryHoursTue {
                    from
                    to
                    closed
                }
                deliveryHoursWen {
                    from
                    to
                    closed
                }
                deliveryHoursThu {
                    from
                    to
                    closed
                }
                deliveryHoursFri {
                    from
                    to
                    closed
                }
                deliveryHoursSat {
                    from
                    to
                    closed
                }
                deliveryHoursSun {
                    from
                    to
                    closed
                }
            }
            dic
            dph
            email
            icDic
            ico
            iconPath
            id
            languages {
                id
                title
                languageCode
                energy
                fats
                protein
                saccharides
                salts
                saturatedFats
                serving
                sugars
            }
            location {
                address
                longitude
                latitude
            }
            owner
            ownerContact
            phoneNumber
            language{
            id
            languageCode}
            title
            verified
        }
    }
`;

export const REGISTER_STORE = gql`
    mutation registerStore(
        $email: String!
        $password: String!
        $title: String!
        $phoneNumber: String!
        $owner: String!
        $ownerContact: String!
        $dph: String!
        $ico: String!
        $dic: String!
        $icDic: String!
        $icon: String

        $languageId: Int!

        $languages: [Int!]!
        $location: LocationInput!
        $details: StoreDetailInput!
    ) {
        registerStore(
            email: $email
            password: $password
            title: $title
            phoneNumber: $phoneNumber
            LanguageId: $LanguageId
            owner: $owner
            ownerContact: $ownerContact
            dph: $dph
            ico: $ico
            dic: $dic
            icDic: $icDic
            languages: $languages
            location: $location
            details: $details
            icon: $icon
        ) {
            id
        }
    }
`;

export const UPDATE_STORE = gql`
    mutation updateStore(
        $id: Int!
        $title: String
        $phoneNumber: String
        $LanguageId: Int
        $owner: String
        $ownerContact: String
        $dph: String
        $ico: String
        $dic: String
        $icDic: String
        $icon: String
        $email: String
        $password: String
        $languages: [Int!]
        $location: LocationInput
        $details: StoreDetailInput
    ) {
        updateStore(
            id: $id
            title: $title
            phoneNumber: $phoneNumber
            LanguageId: $LanguageId
            owner: $owner
            ownerContact: $ownerContact
            dph: $dph
            ico: $ico
            dic: $dic
            icDic: $icDic
            icon: $icon
            email: $email
            password: $password
            languages: $languages
            location: $location
            details: $details
        ) {
            id
            iconPath
        }
    }
`;
export const CHANGE_STORE_ACTIVE = gql`
    mutation changeStoreActive($id: Int) {
        changeStoreActive(id: $id) {
            id
        }
    }
`;
