import React, { useState, useEffect } from 'react';
import {
    useMutation,
    useQuery,
    useSubscription,
} from '@apollo/client';
import {
    API_SETTING,
    API_SETTING_SUBSCRIPTION,
    UPDATE_API_SETTING,
} from 'queries';
import {
    Input,
    Button,
    Card,
    Row,
    Col,
    Table,
    Spinner,
} from 'reactstrap';

export default function APIKeys() {
    const [updateApiSetting] = useMutation(
        UPDATE_API_SETTING
    );

    const {
        data: apiSettingData,
        loading: apiSettingLoading,
        refetch: apiSettingRefetch,
    } = useQuery(API_SETTING, {
        fetchPolicy: 'network-only',
    });

    useSubscription(API_SETTING_SUBSCRIPTION, {
        onData: () => {
            apiSettingRefetch();
        },
    });

    const [googleKey, setGoogleKey] = useState('');
    const [vimeoId, setVimeoId] = useState('');
    const [vimeoSecret, setVimeoSecret] = useState('');
    const [vimeoToken, setVimeoToken] = useState('');

    const [openEdit, setOpenEdit] = useState(false);

    useEffect(() => {
        if (apiSettingData) {
            setGoogleKey(
                apiSettingData.apiSetting.googleKey
            );
            setVimeoId(apiSettingData.apiSetting.vimeoId);
            setVimeoSecret(
                apiSettingData.apiSetting.vimeoSecret
            );
            setVimeoToken(
                apiSettingData.apiSetting.vimeoToken
            );
        }
    }, [apiSettingData]);

    const updateApiSettingFunc = () => {
        updateApiSetting({
            variables: {
                googleKey,
                vimeoId,
                vimeoSecret,
                vimeoToken,
            },
        }).then((response) => {
            setOpenEdit(false);
        });
    };

    if (apiSettingLoading) {
        return (
            <Card style={{ padding: '1em' }}>
                <Row>
                    <Spinner color="primary" />
                </Row>
            </Card>
        );
    }

    return (
        <Card style={{ padding: '1em' }}>
            <Row xs={openEdit ? '2' : ''}>
                {!openEdit && (
                    <Col>
                        <Button
                            color="warning"
                            onClick={() => {
                                setOpenEdit(true);
                            }}
                        >
                            Upraviť
                        </Button>
                    </Col>
                )}

                {openEdit && (
                    <Col>
                        <Button
                            onClick={() => {
                                setGoogleKey(
                                    apiSettingData
                                        .apiSetting
                                        .googleKey
                                );
                                setVimeoId(
                                    apiSettingData
                                        .apiSetting.vimeoId
                                );
                                setVimeoSecret(
                                    apiSettingData
                                        .apiSetting
                                        .vimeoSecret
                                );
                                setVimeoToken(
                                    apiSettingData
                                        .apiSetting
                                        .vimeoToken
                                );

                                setOpenEdit(false);
                            }}
                        >
                            Zrušiť
                        </Button>
                    </Col>
                )}

                {openEdit && (
                    <Col className="flex jc-flex-end">
                        <Button
                            color="primary"
                            onClick={() => {
                                updateApiSettingFunc();
                            }}
                        >
                            Uložiť
                        </Button>
                    </Col>
                )}
            </Row>
            <Row>
                <Table>
                    <thead>
                        <tr>
                            <th width="33%">Názov</th>
                            <th width="33%">Kľúč</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr key={'googleKey'}>
                            <td>Google Key</td>
                            {openEdit && (
                                <td>
                                    <Input
                                        id={`googleKey`}
                                        value={googleKey}
                                        onChange={(e) => {
                                            setGoogleKey(
                                                e.target
                                                    .value
                                            );
                                        }}
                                    />
                                </td>
                            )}
                            {!openEdit && (
                                <td
                                    style={{
                                        overflowWrap:
                                            'anywhere',
                                    }}
                                >
                                    {
                                        apiSettingData
                                            .apiSetting
                                            .googleKey
                                    }
                                </td>
                            )}
                        </tr>

                        <tr key={'vimeoId'}>
                            <td>Vimeo Id</td>
                            {openEdit && (
                                <td>
                                    <Input
                                        id={`vimeoId`}
                                        value={vimeoId}
                                        onChange={(e) => {
                                            setVimeoId(
                                                e.target
                                                    .value
                                            );
                                        }}
                                    />
                                </td>
                            )}
                            {!openEdit && (
                                <td
                                    style={{
                                        overflowWrap:
                                            'anywhere',
                                    }}
                                >
                                    {
                                        apiSettingData
                                            .apiSetting
                                            .vimeoId
                                    }
                                </td>
                            )}
                        </tr>

                        <tr key={'vimeoSecret'}>
                            <td>Vimeo Secret</td>
                            {openEdit && (
                                <td>
                                    <Input
                                        id={`vimeoSecret`}
                                        value={vimeoSecret}
                                        onChange={(e) => {
                                            setVimeoSecret(
                                                e.target
                                                    .value
                                            );
                                        }}
                                    />
                                </td>
                            )}
                            {!openEdit && (
                                <td
                                    style={{
                                        overflowWrap:
                                            'anywhere',
                                    }}
                                >
                                    {
                                        apiSettingData
                                            .apiSetting
                                            .vimeoSecret
                                    }
                                </td>
                            )}
                        </tr>

                        <tr key={'vimeoToken'}>
                            <td>Vimeo Token</td>
                            {openEdit && (
                                <td>
                                    <Input
                                        id={`vimeoToken`}
                                        value={vimeoToken}
                                        onChange={(e) => {
                                            setVimeoToken(
                                                e.target
                                                    .value
                                            );
                                        }}
                                    />
                                </td>
                            )}
                            {!openEdit && (
                                <td
                                    style={{
                                        overflowWrap:
                                            'anywhere',
                                    }}
                                >
                                    {
                                        apiSettingData
                                            .apiSetting
                                            .vimeoToken
                                    }
                                </td>
                            )}
                        </tr>
                    </tbody>
                </Table>
            </Row>
        </Card>
    );
}
