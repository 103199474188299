import { gql } from '@apollo/client';

export const ORDERS_SUBSCRIPTION = gql`
    subscription allOrdersSubscription {
        allOrdersSubscription
    }
`;

export const GET_ORDERS = gql`
    query orders(
        $storeId: Int
        $userId: Int
        $courierId: Int
        $done: Boolean
        $runnerId: Int
    ) {
        orders(
            storeId: $storeId
            userId: $userId
            courierId: $courierId
            done: $done
            runnerId: $runnerId
        ) {
            orderUUID
            deliveryType
            createdAt
            updatedAt
            state
            user {
                id
                fullName
                email
            }
            courier {
                id
                fullName
                email
            }
            runner {
                id
                email
                fullName
            }
            store {
                id
                title
                location {
                    address
                }
            }
            review {
                id
                score
                message
            }
        }
    }
`;
export const GET_ORDER = gql`
    query order($orderUuid: String!) {
        order(orderUUID: $orderUuid) {
            orderUUID
            createdAt
            state
            deliveryType
            cancelled
            cancelReason
            delayTime
            delayReason
            estimatedPrice
            realPrice
            products {
                product {
                    translations {
                        title
                        language {
                            id
                            languageCode
                        }
                    }
                }
                quantity
            }
            user {
                id
                fullName
            }
            courier {
                id
                fullName
            }
            runner {
                id
                fullName
            }
            store {
                id
                title
            }
            deliveryAddress {
                address
            }
            storeAddress {
                address
            }
            deliveredDate
            canceledDate
            review {
                score
                message
            }
        }
    }
`;
