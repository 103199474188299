import React, { useState } from 'react';
import { Table, Button, Row, Col, Modal } from 'reactstrap';
import TrendingGroupForm from './form';
import TrendingGroupLoader from './trendingGroupLoader';
import { MaterialSymbol } from 'react-material-symbols';

let fakeID = -1;

export default function TrendingGroups(props) {
    const {
        trendingGroups,
        onCreateTrendingGroup,
        onUpdateTrendingGroup,
        onRemoveTrendingGroup,
    } = props;

    const [editTrendingGroup, setEditTrendingGroup] =
        useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const toggle = () => {
        setModalOpen(!modalOpen);
    };

    return (
        <>
            <Table hover>
                <thead>
                    <tr>
                        <th width={'25%'}>Názov</th>
                        <th
                            width={'25%'}
                            className="ta-center"
                        >
                            Zobrazovaná
                        </th>
                        <th
                            width={'25%'}
                            className="ta-center"
                        >
                            Poradie
                        </th>
                        <th width={'25%'}>Akcia</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key="add-category-btn">
                        <td
                            colSpan={4}
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={toggle}
                        >
                            + Trending group
                        </td>
                    </tr>
                    {[...trendingGroups]
                        .sort((c1, c2) =>
                            c1.order > c2.order ? 1 : -1
                        )
                        .map((trendingGroup) => (
                            <tr
                                key={trendingGroup.id}
                                style={{
                                    cursor: 'pointer',
                                }}
                            >
                                <td>
                                    {trendingGroup.title}
                                </td>
                                <td className="ta-center">
                                    <MaterialSymbol
                                        icon={
                                            trendingGroup.show
                                                ? 'check_circle'
                                                : 'cancel'
                                        }
                                        size={24}
                                        grade={-25}
                                        color={
                                            trendingGroup.show
                                                ? 'green'
                                                : 'red'
                                        }
                                    />
                                </td>
                                <td className="ta-center">
                                    {trendingGroup.order}
                                </td>

                                <td>
                                    <Row xs="2">
                                        <Col>
                                            <Button
                                                color="warning"
                                                block
                                                onClick={() => {
                                                    setEditTrendingGroup(
                                                        trendingGroup
                                                    );
                                                    toggle();
                                                }}
                                            >
                                                Upraviť
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                color="danger"
                                                block
                                                onClick={() => {
                                                    if (
                                                        window.confirm(
                                                            'Ste si isty?'
                                                        )
                                                    ) {
                                                        onRemoveTrendingGroup(
                                                            trendingGroup.id
                                                        );
                                                    }
                                                }}
                                            >
                                                Odstrániť
                                            </Button>
                                        </Col>
                                    </Row>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>
            <Modal
                isOpen={modalOpen}
                toggle={() => {
                    if (modalOpen) {
                        setEditTrendingGroup(null);
                    }
                    toggle();
                }}
            >
                {editTrendingGroup && (
                    <TrendingGroupLoader
                        defaultOrder={
                            Math.max(
                                ...(trendingGroups.length >
                                0
                                    ? trendingGroups.map(
                                          (trendingGroup) =>
                                              trendingGroup.order
                                      )
                                    : [0])
                            ) + 1
                        }
                        onClose={() => {
                            setEditTrendingGroup(null);
                            toggle();
                        }}
                        onSave={(data) => {
                            onUpdateTrendingGroup(data);
                            toggle();
                        }}
                        trendingGroupId={
                            editTrendingGroup
                                ? editTrendingGroup.id
                                : null
                        }
                    />
                )}
                {!editTrendingGroup && (
                    <TrendingGroupForm
                        defaultOrder={
                            Math.max(
                                ...(trendingGroups.length >
                                0
                                    ? trendingGroups.map(
                                          (trendingGroup) =>
                                              trendingGroup.order
                                      )
                                    : [0])
                            ) + 1
                        }
                        onClose={() => {
                            toggle();
                        }}
                        onSave={(data) => {
                            onCreateTrendingGroup(data);
                            toggle();
                        }}
                        trendingGroupId={null}
                    />
                )}
            </Modal>
        </>
    );
}
