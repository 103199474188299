import { gql } from '@apollo/client';

export const TRENDING_GROUPS_SUBSCRIPTION = gql`
    subscription trendingGroupsSubscription {
        trendingGroupsSubscription
    }
`;

export const TRENDING_GROUPS = gql`
    query trendingGroups {
        trendingGroups {
            id
            title
            show
            shuffle
            order
        }
    }
`;

export const GET_TRENDING_GROUP = gql`
    query trendingGroup($id: Int!) {
        trendingGroup(id: $id) {
            id
            title
            show
            shuffle
            order
            recipes {
                id
                recipeTranslations {
                    id
                    title
                    language {
                        id
                        languageCode
                    }
                }
            }
            chefs {
                id
                fullName
            }
        }
    }
`;

export const ADD_TRENDING_GROUP = gql`
    mutation addTrendingGroup(
        $title: String!
        $shuffle: Boolean!
        $order: Int!
        $show: Boolean!

        $recipes: [Int!]
        $chefs: [Int!]
    ) {
        addTrendingGroup(
            title: $title
            shuffle: $shuffle
            order: $order
            show: $show
            recipes: $recipes
            chefs: $chefs
        ) {
            id
        }
    }
`;

export const UPDATE_TRENDING_GROUP = gql`
    mutation updateTrendingGroup(
        $id: Int!
        $title: String
        $show: Boolean
        $shuffle: Boolean
        $order: Int
        $recipes: [Int!]
        $chefs: [Int!]
    ) {
        updateTrendingGroup(
            id: $id
            title: $title
            show: $show
            shuffle: $shuffle
            order: $order
            recipes: $recipes
            chefs: $chefs
        ) {
            id
        }
    }
`;
export const DELETE_TRENDING_GROUP = gql`
    mutation deleteTrendingGroup(
        $id: Int!
    ) {
        deleteTrendingGroup(id: $id) {
            id
        }
    }
`;
