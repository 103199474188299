import React, { useEffect, useState, useMemo } from 'react';
import {
    useMutation,
    useQuery,
    useSubscription,
} from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Col, Row, Spinner } from 'reactstrap';
import Products from '../components/products';
import allLanguages from 'configs/languages';
import { useGetMyData } from 'utils';
import {
    GET_STORE,
    PRODUCTS,
    PRODUCTS_SUBSCRIPTION,
    PRODUCT_CATEGORIES,
    PRODUCT_CATEGORIES_SUBSCRIPTION,
    INGREDIENTS,
    INGREDIENTS_SUBSCRIPTION,
    GET_LANGUAGES,
    LANGUAGES_SUBSCRIPTION,
    ADD_PRODUCT,
    UPDATE_PRODUCT,
    DELETE_PRODUCT,
} from 'queries';

export default function ShopProductsContainer() {
    const { shopID } = useParams();
    const currentAdmin = useGetMyData();

    const [addProduct] = useMutation(ADD_PRODUCT);
    const [updateProduct] = useMutation(UPDATE_PRODUCT);
    const [deleteProduct] = useMutation(DELETE_PRODUCT);

    const [languages, setLanguages] = useState([]);

    const {
        data: storeData,
        loading: storeLoading,
        refetch: storeRefetch,
    } = useQuery(GET_STORE, {
        variables: {
            storeId: parseInt(shopID),
        },
        fetchPolicy: 'network-only',
    });

    const {
        data: productsData,
        loading: productsLoading,
        refetch: productsRefetch,
    } = useQuery(PRODUCTS, {
        variables: {
            storeId: parseInt(shopID),
            page: 1,
            limit: 30,
            filter: '',
        },
        fetchPolicy: 'network-only',
    });

    const {
        data: categoriesData,
        loading: categoriesLoading,
        refetch: categoriesRefetch,
    } = useQuery(PRODUCT_CATEGORIES, {
        fetchPolicy: 'network-only',
    });

    const {
        data: ingredientsData,
        loading: ingredientsLoading,
        refetch: ingredientsRefetch,
    } = useQuery(INGREDIENTS, {
        variables: {
            languageId: null,
        },
        fetchPolicy: 'network-only',
    });

    const {
        data: languagesData,
        loading: languagesLoading,
        refetch: languagesRefetch,
    } = useQuery(GET_LANGUAGES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(LANGUAGES_SUBSCRIPTION, {
        onData: () => {
            languagesRefetch();
        },
    });

    useSubscription(INGREDIENTS_SUBSCRIPTION, {
        onData: () => {
            ingredientsRefetch({
                languageId: null,
            });
        },
    });

    useSubscription(PRODUCT_CATEGORIES_SUBSCRIPTION, {
        onData: () => {
            categoriesRefetch();
        },
    });

    useSubscription(PRODUCTS_SUBSCRIPTION, {
        variables: {
            storeId: parseInt(shopID),
            page: 1,
            limit: 30,
            filter: '',
        },
        onData: () => {
            productsRefetch({
                storeId: parseInt(shopID),
                page: 1,
                limit: 30,
                filter: '',
            });
        },
    });

    useEffect(() => {
        productsRefetch({
            storeId: parseInt(shopID),
            page: 1,
            limit: 30,
            filter: '',
        });

        storeRefetch({
            id: parseInt(shopID),
        });
    }, [shopID]);

    useEffect(() => {
        if (
            storeData &&
            storeData.store &&
            storeData.store.languages
        ) {
            setLanguages(
                storeData.store.languages.map(
                    (language) => ({
                        ...language,
                        ...allLanguages.find(
                            (lang) =>
                                lang.value ===
                                language.languageCode
                        ),
                    })
                )
            );
        }
    }, [storeData]);

    const recipeLanguage = useMemo(() => {
        return languagesData && languagesData.languages
            ? languagesData.languages.find(
                (language) =>
                    language.languageCode ===
                    currentAdmin.siteLanguage
            )?.id
            : null;
    }, [languagesData, currentAdmin]);

    const onCreateProduct = (data) => {
        addProduct({
            variables: {
                categories: data.categories.map((category) => category.id),
                price: parseFloat(data.price),
                ingredientId: data.ingredientType?.value,
                priceWithoutVat: parseFloat(
                    data.priceWithoutVat
                ),
                amount: parseFloat(data.amount),

                translations: languages.map((language) => ({
                    allergens: data.allergens[language.id],
                    description:
                        data.description[language.id],
                    components:
                        data.components[language.id],
                    title: data.title[language.id],
                    languageId: language.id,
                })),

                energyPerUnit:
                    data.nutritionalValues.energy.perUnit,
                energyPerServing:
                    data.nutritionalValues.energy
                        .perServing,
                fatsPerUnit:
                    data.nutritionalValues.fat.perUnit,
                fatsPerServing:
                    data.nutritionalValues.fat.perServing,
                saturatedFatsPerUnit:
                    data.nutritionalValues.ofWhichSaturates
                        .perUnit,
                saturatedFatsPerServing:
                    data.nutritionalValues.ofWhichSaturates
                        .perServing,
                saccharidesPerUnit:
                    data.nutritionalValues.carbohydrate
                        .perUnit,
                saccharidesPerServing:
                    data.nutritionalValues.carbohydrate
                        .perServing,
                sugarsPerUnit:
                    data.nutritionalValues.ofWhichSugars
                        .perUnit,
                sugarsPerServing:
                    data.nutritionalValues.ofWhichSugars
                        .perServing,
                proteinPerUnit:
                    data.nutritionalValues.protein
                        .perUnit,
                proteinPerServing:
                    data.nutritionalValues.protein
                        .perServing,
                saltsPerUnit:
                    data.nutritionalValues.salt.perUnit,
                saltsPerServing:
                    data.nutritionalValues.salt.perServing,

                unitsPerStandardAmount: data.unitsPerStandardAmount,
                unitsPerServing: data.unitsPerServing,

                icon: data.image,
                max: parseFloat(data.max),
                min: parseFloat(data.min),
                unit: data.unit.value,
                available: data.available,
                storeId: parseInt(shopID),
            },
        }).then(() => {
            productsRefetch({
                storeId: parseInt(shopID),
                page: 1,
                limit: 30,
                filter: '',
            });
        });
    };

    const onUpdateProduct = (data) => {
        updateProduct({
            variables: {
                id: data.productId,
                categories: data.categories.map((category) => category.id),
                ingredientId: data.ingredientType?.value,
                price: parseFloat(data.price),
                priceWithoutVat: parseFloat(
                    data.priceWithoutVat
                ),
                amount: parseFloat(data.amount),

                translations: languages.map((language) => ({
                    allergens: data.allergens[language.id],
                    description:
                        data.description[language.id],
                    components:
                        data.components[language.id],
                    title: data.title[language.id],
                    languageId: language.id,
                })),

                energyPerUnit:
                    data.nutritionalValues.energy.perUnit,
                energyPerServing:
                    data.nutritionalValues.energy
                        .perServing,
                fatsPerUnit:
                    data.nutritionalValues.fat.perUnit,
                fatsPerServing:
                    data.nutritionalValues.fat.perServing,
                saturatedFatsPerUnit:
                    data.nutritionalValues.ofWhichSaturates
                        .perUnit,
                saturatedFatsPerServing:
                    data.nutritionalValues.ofWhichSaturates
                        .perServing,
                saccharidesPerUnit:
                    data.nutritionalValues.carbohydrate
                        .perUnit,
                saccharidesPerServing:
                    data.nutritionalValues.carbohydrate
                        .perServing,
                sugarsPerUnit:
                    data.nutritionalValues.ofWhichSugars
                        .perUnit,
                sugarsPerServing:
                    data.nutritionalValues.ofWhichSugars
                        .perServing,
                proteinPerUnit:
                    data.nutritionalValues.protein
                        .perUnit,
                proteinPerServing:
                    data.nutritionalValues.protein
                        .perServing,
                saltsPerUnit:
                    data.nutritionalValues.salt.perUnit,
                saltsPerServing:
                    data.nutritionalValues.salt.perServing,

                unitsPerStandardAmount: data.unitsPerStandardAmount,
                unitsPerServing: data.unitsPerServing,

                icon: data.image,
                max: parseInt(data.max),
                min: parseInt(data.min),
                unit: data.unit.value,
                available: data.available,
                storeId: parseInt(shopID),
            },
        }).then(() => {
            productsRefetch({
                storeId: parseInt(shopID),
                page: 1,
                limit: 30,
                filter: '',
            });
        });
    };

    const onRemoveProduct = (id) => {
        deleteProduct({
            variables: {
                deleteProductId: id,
            },
        }).then(() => {
            productsRefetch({
                storeId: parseInt(shopID),
                page: 1,
                limit: 30,
                filter: '',
            });
        });
    };

    if (
        storeLoading ||
        languagesLoading ||
        ingredientsLoading ||
        productsLoading ||
        categoriesLoading
    ) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    return (
        <Col>
            <Products
                supportedLanguages={languages}
                categories={
                    categoriesData &&
                        categoriesData.productCategories
                        ? [
                            ...categoriesData.productCategories,
                        ].sort((c1, c2) =>
                            c1.order > c2.order ? -1 : 1
                        )
                        : []
                }
                allIngredients={
                    ingredientsData &&
                        ingredientsData.ingredients
                        ? ingredientsData.ingredients
                            .filter(
                                (ing) =>
                                    ing.units.includes(
                                        'kilo'
                                    ) ||
                                    ing.units.includes(
                                        'liter'
                                    ) ||
                                    ing.units.includes(
                                        'piece'
                                    )
                            )
                            .map((ing) => ({
                                value: ing.id,
                                label: ing.translations.find(
                                    (translation) =>
                                        translation
                                            .language
                                            .id ===
                                        recipeLanguage
                                )?.title,
                                units: ing.units,
                            }))
                        : []
                }
                products={
                    productsData &&
                        productsData.storeProducts &&
                        productsData.storeProducts.products
                        ? [
                            ...productsData.storeProducts
                                .products,
                        ].sort((p1, p2) =>
                            p1.id > p2.id ? -1 : 1
                        )
                        : []
                }
                onCreateProduct={onCreateProduct}
                onUpdateProduct={onUpdateProduct}
                onRemoveProduct={onRemoveProduct}
                currentAdmin={currentAdmin}
                shopID={parseInt(shopID)}
                siteLanguage={storeData.store.siteLanguage}
            />
        </Col>
    );
}
