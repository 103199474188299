import React, { useState } from 'react';
import { Row, Col, Table, Spinner } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useQuery, useSubscription } from '@apollo/client';
import { USERS, USERS_SUBSCRIPTION } from 'queries';
import { MaterialSymbol } from 'react-material-symbols';
import Select from 'react-select';
import { sortBasic, sortName } from 'configs/sortOptions';

export default function ShopsTable() {
    const navigate = useNavigate();

    const [sortOption, setSortOption] = useState(sortBasic);

    const {
        data: usersData,
        loading: usersLoading,
        refetch: usersRefetch,
    } = useQuery(USERS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(USERS_SUBSCRIPTION, {
        onData: () => {
            usersRefetch();
        },
    });

    if (usersLoading) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    return (
        <Row
            style={{
                paddingTop: '1em',
            }}
        >
            <Col>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={false}
                    isSearchable={false}
                    name="user-sort"
                    options={[sortBasic, sortName]}
                    value={sortOption}
                    onChange={(option) => {
                        setSortOption(option);
                    }}
                />
                <Table hover>
                    <thead>
                        <tr>
                            <th width="10%">ID</th>
                            <th width="30%">Meno</th>
                            <th width="40%">Email</th>
                            <th
                                width="10%"
                                className="ta-center"
                            >
                                Aktívny
                            </th>
                            <th
                                width="10%"
                                className="ta-center"
                            >
                                Overený
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{ cursor: 'pointer' }}>
                            <th
                                colSpan={6}
                                onClick={() =>
                                    navigate(
                                        '/customers/add'
                                    )
                                }
                            >
                                + Zákazník
                            </th>
                        </tr>
                        {[...usersData.users]
                            .sort((u1, u2) => {
                                if (
                                    sortOption.value ===
                                    'id'
                                ) {
                                    return u1[
                                        sortOption.value
                                    ] < u2[sortOption.value]
                                        ? -1
                                        : 1;
                                }
                                return u1[
                                    sortOption.value
                                ].toLowerCase() <
                                    u2[
                                        sortOption.value
                                    ].toLowerCase()
                                    ? -1
                                    : 1;
                            })
                            .map((user) => (
                                <tr
                                    key={user.id}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                        navigate(
                                            `/customers/${user.id}/profile`
                                        )
                                    }
                                >
                                    <th scope="row">
                                        {user.id}
                                    </th>
                                    <td>{user.fullName}</td>
                                    <td>{user.email}</td>
                                    <td className="ta-center">
                                        <MaterialSymbol
                                            icon={
                                                user.active
                                                    ? 'check_circle'
                                                    : 'cancel'
                                            }
                                            size={24}
                                            grade={-25}
                                            color={
                                                user.active
                                                    ? 'green'
                                                    : 'red'
                                            }
                                        />
                                    </td>
                                    <td className="ta-center">
                                        <MaterialSymbol
                                            icon={
                                                user.verified
                                                    ? 'check_circle'
                                                    : 'cancel'
                                            }
                                            size={24}
                                            grade={-25}
                                            color={
                                                user.verified
                                                    ? 'green'
                                                    : 'red'
                                            }
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
}
