import React, { useState } from 'react';
import {
    FormGroup,
    FormFeedback,
    Label,
    Input,
    Row,
} from 'reactstrap';
import ImageLoader from 'components/image';

export default function ImageInput(props) {
    const { iconPath, setIconPath, image, setImage } =
        props;

    const uniqueID = 'x'
        .repeat(5)
        .replace(
            /./g,
            (c) =>
                'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'[
                    Math.floor(Math.random() * 62)
                ]
        );

    const [imageSizeTooBig, setImageSizeTooBig] =
        useState(false);

    const blobToBase64 = (blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise((resolve) => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });
    };

    async function setIconAsBase64(e) {
        if (e.target.files.length > 0) {
            let file = e.target.files[0];
            if (file.size < 2 * 1024 * 1024) {
                setImageSizeTooBig(false);
                let blob = await blobToBase64(file);
                setImage(blob);
            } else {
                setImageSizeTooBig(true);
            }
        }
    }

    return (
        <Row className="flex jc-center">
            <ImageLoader
                path={iconPath ? iconPath : null}
                imageSource={image}
                size={'image'}
                width={'auto'}
            />
            <FormGroup className="flex jc-center">
                <Label htmlFor={`uploadImage-${uniqueID}`}>
                    Upraviť obrázok
                </Label>
                <Input
                    type="file"
                    accept="image/png, image/jpeg"
                    invalid={imageSizeTooBig}
                    id={`uploadImage-${uniqueID}`}
                    style={{ display: 'none' }}
                    onChange={(e) => {
                        if (setIconPath) {
                            setIconPath(null);
                        }
                        setIconAsBase64(e);
                    }}
                />
                {imageSizeTooBig && (
                    <FormFeedback>
                        {
                            'Obrazok je prilis velky! Maximalna velkost je 2MB.'
                        }
                    </FormFeedback>
                )}
            </FormGroup>
        </Row>
    );
}
