import React, { useState } from 'react';
import {
    Row,
    Col,
    Table,
    Spinner,
    Label,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalHeader,
    Form,
    Button
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useQuery, useSubscription, useMutation, } from '@apollo/client';
import { MASS_DECLINE_POTENTIAL_CHEFS, POTENTIAL_CHEFS, POTENTIAL_CHEFS_SUBSCRIPTION } from 'queries';
import { MaterialSymbol } from 'react-material-symbols';
import Select from 'react-select';
import { sortBasic, sortName } from 'configs/sortOptions';

export default function PotentialChefsTable() {
    const navigate = useNavigate();

    const [declinePotentialChefs] = useMutation(MASS_DECLINE_POTENTIAL_CHEFS);

    const [sortOption, setSortOption] = useState(sortBasic);
    const [showOnlyVerified, setShowOnlyVerified] = useState(true);
    const [selectedChefs, setSelectedChefs] = useState([]);

    const [declineReason, setDeclineReason] = useState("");
    const [showDeclineModal, setShowDeclineModal] = useState(false);
    const [sendEmails, setSendEmails] = useState(true);

    const {
        data: chefsData,
        loading: chefsLoading,
        refetch: chefsRefetch,
    } = useQuery(POTENTIAL_CHEFS, {
        variables: {
            verified: showOnlyVerified,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(POTENTIAL_CHEFS_SUBSCRIPTION, {
        onData: () => {
            chefsRefetch();
        },
    });

    const massDeclineChefs = () => {
        declinePotentialChefs({
            variables: {
                reason: declineReason,
                ids: selectedChefs,
                sendEmails
            },
        }).then(() => {
            chefsRefetch()
        });
    };

    if (chefsLoading) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    return (
        <Row
            style={{
                paddingTop: '1em',
            }}
        >
            <Col>

                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={false}
                    isSearchable={false}
                    name="chef-sort"
                    options={[sortBasic, sortName]}
                    value={sortOption}
                    onChange={(option) => {
                        setSortOption(option);
                    }}
                />
                <FormGroup check>
                    <Input
                        id="show-unverified-chefs"
                        type="checkbox"
                        checked={!showOnlyVerified}
                        onChange={() => {
                            setShowOnlyVerified(!showOnlyVerified);
                        }}
                    />
                    <Label
                        check
                        htmlFor="show-unverified-chefs"
                    >
                        Ukázať šéfkuchárov s neovereným emailom
                    </Label>
                </FormGroup>
                <Table hover>
                    <thead>
                        <tr>
                            <th width="15%"><Button
                                color={"danger"}
                                block
                                onClick={() => {
                                    setShowDeclineModal(true)
                                }}
                            >
                                Odmietnuť označených
                            </Button></th>
                            <th width="5%">ID</th>
                            <th width="20%">Meno</th>
                            <th width="25%">Instagram</th>
                            <th width="25%">Youtube</th>
                            <th
                                width="10%"
                                className="ta-center"
                            >
                                Overený email
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {[...chefsData.potentialChefs]
                            .sort((c1, c2) => {
                                if (
                                    sortOption.value ===
                                    'id'
                                ) {
                                    return c1[
                                        sortOption.value
                                    ] < c2[sortOption.value]
                                        ? -1
                                        : 1;
                                }
                                return c1[
                                    sortOption.value
                                ].toLowerCase() <
                                    c2[
                                        sortOption.value
                                    ].toLowerCase()
                                    ? -1
                                    : 1;
                            })
                            .map((chef) => (
                                <tr
                                    key={chef.id}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                >
                                    <td>
                                        <Input
                                            type="checkbox"
                                            checked={selectedChefs.includes(chef.id)}
                                            onChange={() => {
                                                if (selectedChefs.includes(chef.id)) {
                                                    setSelectedChefs(selectedChefs.filter((id) => id !== chef.id));

                                                } else {
                                                    setSelectedChefs([...selectedChefs, chef.id]);
                                                }
                                            }}
                                        />
                                    </td>
                                    <th scope="row"
                                        onClick={() =>
                                            navigate(
                                                `/potential-chefs/${chef.id}/profile`
                                            )
                                        }>
                                        {chef.id}
                                    </th>
                                    <td
                                        onClick={() =>
                                            navigate(
                                                `/potential-chefs/${chef.id}/profile`
                                            )
                                        }>{`${chef.fullName} (${chef.nickname})`}</td>
                                    <td
                                        onClick={() =>
                                            navigate(
                                                `/potential-chefs/${chef.id}/profile`
                                            )
                                        }>{chef.instagram}</td>
                                    <td
                                        onClick={() =>
                                            navigate(
                                                `/potential-chefs/${chef.id}/profile`
                                            )
                                        }>{chef.youtube}</td>
                                    <td className="ta-center"
                                        onClick={() =>
                                            navigate(
                                                `/potential-chefs/${chef.id}/profile`
                                            )
                                        }>
                                        <MaterialSymbol
                                            icon={
                                                chef.verified
                                                    ? 'check_circle'
                                                    : 'cancel'
                                            }
                                            size={24}
                                            grade={-25}
                                            color={
                                                chef.verified
                                                    ? 'green'
                                                    : 'red'
                                            }
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>

                <Modal isOpen={showDeclineModal} toggle={() => { setShowDeclineModal(false) }}>
                    <ModalHeader>
                        Odmietnuť žiadosti?
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup check>
                                <Input
                                    id="send-decline-emails"
                                    type="checkbox"
                                    checked={sendEmails}
                                    onChange={() => {
                                        setSendEmails(!sendEmails);
                                    }}
                                />
                                <Label
                                    check
                                    htmlFor="send-decline-emails"
                                >
                                    Odoslať email o zamietnutí.
                                </Label>
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="decline-reason">
                                    Dôvod?
                                </Label>
                                <Input
                                    id="decline-reason"
                                    value={declineReason}
                                    onChange={(e) => {
                                        setDeclineReason(e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <Row xs="2">
                                <Col>
                                    <Button
                                        color="secondary"
                                        block
                                        onClick={() => {
                                            setShowDeclineModal(false)
                                        }}
                                    >
                                        Zavrieť
                                    </Button>
                                </Col>
                                <Col className="flex jc-flex-end">
                                    <Button
                                        color={"danger"}
                                        block
                                        onClick={() => {
                                            massDeclineChefs()
                                        }}
                                    >
                                        Odmietnuť
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>

                </Modal>
            </Col>
        </Row>
    );
}
