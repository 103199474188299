import { gql } from '@apollo/client';

export const PREPARATOIN_TIMES_SUBSCRIPTION = gql`
    subscription preparationTimesSubscription {
        preparationTimesSubscription
    }
`;

export const PREPARATOIN_TIMES = gql`
query preparationTimes($available: Boolean) {
    preparationTimes(available: $available) {
        available
        id
        order
        title
    }
}
`;

export const ADD_PREPARATOIN_TIME = gql`
mutation addPreparationTime(
    $available: Boolean, 
    $order: Int, 
    $title: String!
) {
  addPreparationTime(
  available: $available, 
  order: $order, 
  title: $title
  ) {
    id
  }
}
`;

export const UPDATE_PREPARATOIN_TIME = gql`
mutation updatePreparationTime(
    $updatePreparationTimeId: Int!, 
    $available: Boolean, 
    $title: String, 
    $order: Int
) {
  updatePreparationTime(
  id: $updatePreparationTimeId, 
  available: $available, 
  title: $title, 
  order: $order
  ) {
    id
  }
}
`;

export const DELETE_PREPARATOIN_TIME = gql`
    mutation deletePreparationTime($deletePreparationTimeId: Int!) {
        deletePreparationTime(id: $deletePreparationTimeId)
    }
`;
