import React, { useEffect, useState } from 'react';
import {
    useMutation,
    useQuery,
    useSubscription,
} from '@apollo/client';
import {
    FormGroup,
    Label,
    Input,
    Button,
    InputGroup,
    Row,
    Col,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Modal,
    ModalHeader,
    ModalBody,
    Spinner,
} from 'reactstrap';
import {
    NavLink as Link,
    useNavigate,
} from 'react-router-dom';
import Profile from '../components/profile';
import allLanguages from 'configs/languages';
import daysOfTheWeek from 'configs/daysOfTheWeek';
import {
    LANGUAGES_SUBSCRIPTION,
    GET_LANGUAGES,
    REGISTER_STORE,
} from 'queries';
import { useGetMyData } from 'utils';
import { getMilisecondsFromMidnight } from 'utils';
import { MaterialSymbol } from 'react-material-symbols';

const PROFILE = 'profile';

export default function ShopAdd() {
    const navigate = useNavigate();

    const [registerStore] = useMutation(REGISTER_STORE);

    const currentAdmin = useGetMyData();

    const isEmail = (email) =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            email
        );

    const {
        data: languagesData,
        loading: languagesLoading,
        refetch: languagesRefetch,
    } = useQuery(GET_LANGUAGES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(LANGUAGES_SUBSCRIPTION, {
        onData: () => {
            languagesRefetch();
        },
    });

    const [openTab, setOpenTab] = useState(PROFILE);

    const [modalOpen, setModalOpen] = useState(false);

    const [email, setEmail] = useState('');
    const [wrongEmailFormat, setWrongEmailFormat] =
        useState(false);

    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [passwordVisible, setPasswordVisible] =
        useState(false);
    const [password2Visible, setPassword2Visible] =
        useState(false);
    const [passwordError] = useState(false);

    const [title, setTitle] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [siteLanguage, setSiteLanguage] = useState(null);
    const [owner, setOwner] = useState('');
    const [ownerContact, setOwnerContact] = useState('');
    const [dph, setDph] = useState('');
    const [ico, setIco] = useState('');
    const [dic, setDic] = useState('');
    const [icDic, setIcDic] = useState('');
    const [languages, setLanguages] = useState([]);
    const [location, setLocation] = useState({
        address: '',
        latitude: null,
        longitude: null,
    });
    const [image, setImage] = useState(null);

    const [deliveryOption, setDeliveryOption] =
        useState(true);
    const [drivethroughOption, setDrivethroughOption] =
        useState(true);
    const [pickupOption, setPickupOption] = useState(true);

    const [preparationTime, setPreparationTime] =
        useState(10);

    const [openHours, setOpenHours] = useState(
        daysOfTheWeek.map((day) => ({
            day,
            from: '07:00',
            to: '19:00',
            closed: false,
        }))
    );

    const [deliveryHours, setDeliveryHours] = useState(
        daysOfTheWeek.map((day) => ({
            day,
            from: '07:00',
            to: '19:00',
            closed: false,
        }))
    );

    useEffect(() => {
        if (languagesData && languagesData.languages) {
            let adminLanguage = [
                {
                    value: 'sk',
                    label: 'Slovak',
                },
                {
                    value: 'cs',
                    label: 'Czech',
                },
                {
                    value: 'en',
                    label: 'English',
                },
                {
                    value: 'de',
                    label: 'German',
                },
            ].find(
                (lang) =>
                    currentAdmin.siteLanguage === lang.value
            );
            setSiteLanguage(
                adminLanguage
                    ? {
                          ...adminLanguage,
                      }
                    : {
                          value: 'sk',
                          label: 'Slovak',
                      }
            );

            let newLanguages =
                languagesData.languages.filter(
                    (lang) =>
                        lang.fixed ||
                        lang.languageCode === 'sk'
                );
            setLanguages(
                newLanguages.map((language) => ({
                    ...language,
                    ...allLanguages.find(
                        (lang) =>
                            lang.value ===
                            language.languageCode
                    ),
                }))
            );
        }
    }, [languagesData, currentAdmin]);

    const toggle = () => {
        setModalOpen(!modalOpen);
    };

    const registerStoreFunc = () => {
        registerStore({
            variables: {
                email,
                password,
                title,
                phoneNumber,
                siteLanguage: siteLanguage.value,
                owner,
                ownerContact,
                dph,
                ico,
                dic,
                icDic,
                icon: image,
                languages: languages.map((lang) => lang.id),
                location,
                details: {
                    pickup: pickupOption,
                    delivery: deliveryOption,
                    driveThrough: drivethroughOption,
                    preparationTime:
                        parseInt(preparationTime),
                    openHoursMon: {
                        to: getMilisecondsFromMidnight(
                            openHours[0].to
                        ),
                        from: getMilisecondsFromMidnight(
                            openHours[0].from
                        ),
                        closed: openHours[0].closed,
                    },
                    openHoursTue: {
                        to: getMilisecondsFromMidnight(
                            openHours[1].to
                        ),
                        from: getMilisecondsFromMidnight(
                            openHours[1].from
                        ),
                        closed: openHours[1].closed,
                    },
                    openHoursWen: {
                        to: getMilisecondsFromMidnight(
                            openHours[2].to
                        ),
                        from: getMilisecondsFromMidnight(
                            openHours[2].from
                        ),
                        closed: openHours[2].closed,
                    },
                    openHoursThu: {
                        to: getMilisecondsFromMidnight(
                            openHours[3].to
                        ),
                        from: getMilisecondsFromMidnight(
                            openHours[3].from
                        ),
                        closed: openHours[3].closed,
                    },
                    openHoursFri: {
                        to: getMilisecondsFromMidnight(
                            openHours[4].to
                        ),
                        from: getMilisecondsFromMidnight(
                            openHours[4].from
                        ),
                        closed: openHours[4].closed,
                    },
                    openHoursSat: {
                        to: getMilisecondsFromMidnight(
                            openHours[5].to
                        ),
                        from: getMilisecondsFromMidnight(
                            openHours[5].from
                        ),
                        closed: openHours[5].closed,
                    },
                    openHoursSun: {
                        to: getMilisecondsFromMidnight(
                            openHours[6].to
                        ),
                        from: getMilisecondsFromMidnight(
                            openHours[6].from
                        ),
                        closed: openHours[6].closed,
                    },

                    deliveryHoursMon: {
                        to: getMilisecondsFromMidnight(
                            deliveryHours[0].to
                        ),
                        from: getMilisecondsFromMidnight(
                            deliveryHours[0].from
                        ),
                        closed: deliveryHours[0].closed,
                    },
                    deliveryHoursTue: {
                        to: getMilisecondsFromMidnight(
                            deliveryHours[1].to
                        ),
                        from: getMilisecondsFromMidnight(
                            deliveryHours[1].from
                        ),
                        closed: deliveryHours[1].closed,
                    },
                    deliveryHoursWen: {
                        to: getMilisecondsFromMidnight(
                            deliveryHours[2].to
                        ),
                        from: getMilisecondsFromMidnight(
                            deliveryHours[2].from
                        ),
                        closed: deliveryHours[2].closed,
                    },
                    deliveryHoursThu: {
                        to: getMilisecondsFromMidnight(
                            deliveryHours[3].to
                        ),
                        from: getMilisecondsFromMidnight(
                            deliveryHours[3].from
                        ),
                        closed: deliveryHours[3].closed,
                    },
                    deliveryHoursFri: {
                        to: getMilisecondsFromMidnight(
                            deliveryHours[4].to
                        ),
                        from: getMilisecondsFromMidnight(
                            deliveryHours[4].from
                        ),
                        closed: deliveryHours[4].closed,
                    },
                    deliveryHoursSat: {
                        to: getMilisecondsFromMidnight(
                            deliveryHours[5].to
                        ),
                        from: getMilisecondsFromMidnight(
                            deliveryHours[5].from
                        ),
                        closed: deliveryHours[5].closed,
                    },
                    deliveryHoursSun: {
                        to: getMilisecondsFromMidnight(
                            deliveryHours[6].to
                        ),
                        from: getMilisecondsFromMidnight(
                            deliveryHours[6].from
                        ),
                        closed: deliveryHours[6].closed,
                    },
                },
            },
        }).then((response) => {
            navigate(`/stores`);
        });
    };

    const cannotSave =
        title.length === 0 ||
        phoneNumber.length === 0 ||
        owner.length === 0 ||
        ownerContact.length === 0 ||
        dph.length === 0 ||
        ico.length === 0 ||
        dic.length === 0 ||
        icDic.length === 0 ||
        languages.length === 0 ||
        location.latitude === null;

    if (languagesLoading) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    return (
        <Row>
            <Col xs="2">
                <Nav tabs fill pills vertical>
                    <NavItem>
                        <NavLink
                            tag={Link}
                            to={`/stores`}
                            end
                        >
                            ← Naspäť na zoznam
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={
                                openTab === PROFILE
                                    ? 'active'
                                    : ''
                            }
                            onClick={() =>
                                setOpenTab(PROFILE)
                            }
                        >
                            Profil
                        </NavLink>
                    </NavItem>
                </Nav>
            </Col>
            <Col>
                <TabContent activeTab={openTab}>
                    <TabPane tabId={PROFILE}>
                        <Profile
                            shopID={'add'}
                            title={title}
                            setTitle={setTitle}
                            phoneNumber={phoneNumber}
                            setPhoneNumber={setPhoneNumber}
                            siteLanguage={siteLanguage}
                            setSiteLanguage={
                                setSiteLanguage
                            }
                            owner={owner}
                            setOwner={setOwner}
                            ownerContact={ownerContact}
                            setOwnerContact={
                                setOwnerContact
                            }
                            dph={dph}
                            setDph={setDph}
                            ico={ico}
                            setIco={setIco}
                            dic={dic}
                            setDic={setDic}
                            icDic={icDic}
                            setIcDic={setIcDic}
                            languages={languages}
                            setLanguages={setLanguages}
                            location={location}
                            setLocation={setLocation}
                            image={image}
                            setImage={setImage}
                            deliveryOption={deliveryOption}
                            setDeliveryOption={
                                setDeliveryOption
                            }
                            drivethroughOption={
                                drivethroughOption
                            }
                            setDrivethroughOption={
                                setDrivethroughOption
                            }
                            pickupOption={pickupOption}
                            setPickupOption={
                                setPickupOption
                            }
                            preparationTime={
                                preparationTime
                            }
                            setPreparationTime={
                                setPreparationTime
                            }
                            openHours={openHours}
                            setOpenHours={setOpenHours}
                            deliveryHours={deliveryHours}
                            setDeliveryHours={
                                setDeliveryHours
                            }
                            siteLanguagesOptions={[
                                {
                                    value: 'sk',
                                    label: 'Slovak',
                                },
                                {
                                    value: 'cs',
                                    label: 'Czech',
                                },
                                {
                                    value: 'en',
                                    label: 'English',
                                },
                                {
                                    value: 'de',
                                    label: 'German',
                                },
                            ]}
                            supportedLanguagesOptions={languagesData.languages.map(
                                (language) => ({
                                    ...language,
                                    ...allLanguages.find(
                                        (lang) =>
                                            lang.value ===
                                            language.languageCode
                                    ),
                                })
                            )}
                            currentAdmin={currentAdmin}
                        />
                    </TabPane>
                </TabContent>
                <Row>
                    <Col className="flex jc-flex-end">
                        <Button
                            color="danger"
                            disabled={cannotSave}
                            onClick={toggle}
                            style={{
                                marginBottom: '1em',
                            }}
                        >
                            Uložiť
                        </Button>
                    </Col>
                </Row>
                <Modal isOpen={modalOpen} toggle={toggle}>
                    <ModalHeader toggle={toggle}>
                        Prihlasovacie údaje
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label htmlFor="shop-email">
                                Email
                            </Label>
                            <Input
                                id="shop-email"
                                invalid={wrongEmailFormat}
                                value={email}
                                onChange={(e) => {
                                    setEmail(
                                        e.target.value
                                    );
                                    setWrongEmailFormat(
                                        false
                                    );
                                }}
                                onBlur={() => {
                                    if (!isEmail(email)) {
                                        setWrongEmailFormat(
                                            true
                                        );
                                    }
                                }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="shop-pass">
                                Heslo
                            </Label>
                            <InputGroup>
                                <Input
                                    id="shop-pass"
                                    invalid={passwordError}
                                    value={password}
                                    type={
                                        passwordVisible
                                            ? 'text'
                                            : 'password'
                                    }
                                    onChange={(e) => {
                                        setPassword(
                                            e.target.value
                                        );
                                    }}
                                />
                                <Button
                                    color="primary"
                                    className="flex"
                                    onClick={() => {
                                        setPasswordVisible(
                                            !passwordVisible
                                        );
                                    }}
                                >
                                    <MaterialSymbol
                                        icon={
                                            passwordVisible
                                                ? 'visibility_off'
                                                : 'visibility'
                                        }
                                        size={24}
                                        fill
                                        grade={-25}
                                        color="white"
                                    />
                                </Button>
                            </InputGroup>
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="shop-pass2">
                                Zopakujte heslo
                            </Label>
                            <InputGroup>
                                <Input
                                    id="shop-pass2"
                                    invalid={
                                        password.length >
                                            0 &&
                                        password2.length >
                                            0 &&
                                        password !==
                                            password2
                                    }
                                    value={password2}
                                    type={
                                        password2Visible
                                            ? 'text'
                                            : 'password'
                                    }
                                    onChange={(e) => {
                                        setPassword2(
                                            e.target.value
                                        );
                                    }}
                                />
                                <Button
                                    color="primary"
                                    className="flex"
                                    onClick={() => {
                                        setPassword2Visible(
                                            !password2Visible
                                        );
                                    }}
                                >
                                    <MaterialSymbol
                                        icon={
                                            password2Visible
                                                ? 'visibility_off'
                                                : 'visibility'
                                        }
                                        size={24}
                                        fill
                                        grade={-25}
                                        color="white"
                                    />
                                </Button>
                            </InputGroup>
                        </FormGroup>

                        <Row xs="2">
                            <Col>
                                <Button
                                    color="secondary"
                                    block
                                    onClick={toggle}
                                >
                                    Naspäť
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    color="primary"
                                    block
                                    onClick={
                                        registerStoreFunc
                                    }
                                    disabled={
                                        !isEmail(email) ||
                                        (password.length >
                                            0 &&
                                            password2.length >
                                                0 &&
                                            password !==
                                                password2) ||
                                        password.length ===
                                            0 ||
                                        password2.length ===
                                            0
                                    }
                                >
                                    Uložiť
                                </Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </Col>
        </Row>
    );
}
