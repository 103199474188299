import { gql } from '@apollo/client';

export const MEAL_TYPES_SUBSCRIPTION = gql`
    subscription mealTypesSubscription {
        mealTypesSubscription
    }
`;

export const MEAL_TYPES = gql`
query mealTypes($available: Boolean) {
    mealTypes(available: $available) {
        available
        id
        order
        title
    }
}
`;

export const ADD_MEAL_TYPE = gql`
mutation addMealType(
    $available: Boolean, 
    $order: Int, 
    $title: String!
) {
  addMealType(
  available: $available, 
  order: $order, 
  title: $title
  ) {
    id
  }
}
`;

export const UPDATE_MEAL_TYPE = gql`
mutation updateMealType(
    $updateMealTypeId: Int!, 
    $available: Boolean, 
    $title: String, 
    $order: Int
) {
  updateMealType(
  id: $updateMealTypeId, 
  available: $available, 
  title: $title, 
  order: $order
  ) {
    id
  }
}
`;

export const DELETE_MEAL_TYPE = gql`
    mutation deleteMealType($deleteMealTypeId: Int!) {
        deleteMealType(id: $deleteMealTypeId)
    }
`;
