import React from 'react';
import { useQuery } from '@apollo/client';
import { INGREDIENT } from 'queries';
import IngredientForm from './form';

export default function IngredientLoader(props) {
    const { ingredientId } = props;

    const {
        data: ingredientData,
        loading: ingredientLoading,
    } = useQuery(INGREDIENT, {
        variables: {
            id: ingredientId,
        },
        fetchPolicy: 'network-only',
    });

    return (
        <IngredientForm
            {...props}
            ingredientData={ingredientData}
            ingredientLoading={ingredientLoading}
        />
    );
}
