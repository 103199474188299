import { gql } from '@apollo/client';

export const ADD_OR_UPDATE_SMTP = gql`
    mutation addOrUpdateSmtp(
        $title: String!
        $order: Int!
        $username: String!
        $password: String!
        $host: String
        $port: Int
        $rejectUnauthorized: Boolean
        $secure: Boolean
        $wellKnown: WellKnownEnum
    ) {
        addOrUpdateSmtp(
            title: $title
            order: $order
            username: $username
            password: $password
            host: $host
            port: $port
            rejectUnauthorized: $rejectUnauthorized
            secure: $secure
            wellKnown: $wellKnown
        ) {
            id
        }
    }
`;

export const GET_SMTP = gql`
    query smtp {
        smtp {
            currentlyTested
            errorMessage
            id
            host
            port
            rejectUnauthorized
            secure
            title
            username
            working
            wellKnown
            password
        }
    }
`;

export const TEST_SMTP = gql`
    mutation testSmtp {
        testSmtp
    }
`;

export const SMTP_SUBSCRIPTION = gql`
    subscription smtpSubscription {
        smtpSubscription
    }
`;
