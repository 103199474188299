import React, { useState, useEffect } from 'react';
import {
    FormGroup,
    InputGroup,
    Label,
    Input,
    Form,
    Button,
    Card,
    Row,
    Col,
} from 'reactstrap';
import Select from 'react-select';
import AdminChangeEmail from './changeEmail';
import AdminChangePassword from './changePassword';
import ImageInput from 'components/imageInput';
import { MaterialSymbol } from 'react-material-symbols';

export default function AdminForm(props) {
    const {
        onClose,
        onSave,
        languages,
        admin,
        adminId,
        isProfile,
    } = props;

    const isEmail = (email) =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            email
        );

    const [iconPath, setIconPath] = useState(null);
    const [image, setImage] = useState(null);
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [wrongEmailFormat, setWrongEmailFormat] =
        useState(false);

    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [passwordErrors, setPasswordErrors] = useState(
        []
    );
    const [password1Visible, setPassword1Visible] =
        useState(false);
    const [password2Visible, setPassword2Visible] =
        useState(false);

    const [active, setActive] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState('');

    const [language, setLanguage] = useState(
        languages[0]
    );
    const [settings, setSettings] = useState(false);

    useEffect(() => {
        if (admin) {
            setIconPath(admin.iconPath);
            setImage(null);
            setName(admin.name);
            setSurname(admin.surname);
            setEmail(admin.email);
            setActive(admin.active);
            setPhoneNumber(admin.phoneNumber);
            setLanguage({
                ...admin.language,
                value: admin.language?.id,
                label: admin.language?.languageCode
            });
            setSettings(admin.settings);
        }
    }, [admin, adminId]);

    const checkPasswords = (calledFromFirstPassword) => {
        if (password.length + password2.length === 0) {
            return;
        }
        let newErrors = [];
        if (
            (!calledFromFirstPassword &&
                password !== password2) ||
            (password !== password2 && password2.length > 0)
        ) {
            newErrors.push('Heslá nie sú rovnaké!');
        }
        if (password.length < 6) {
            newErrors.push(
                'Heslo musí mať aspoň 6 znakov!'
            );
        }
        setPasswordErrors(newErrors);
    };

    return (
        <Card style={{ padding: '1em' }}>
            <Row>
                <Form>
                    <ImageInput
                        iconPath={iconPath}
                        setIconPath={setIconPath}
                        image={image}
                        setImage={setImage}
                        size={'image'}
                    />

                    {!isProfile && (
                        <FormGroup check>
                            <Input
                                id="admin-active"
                                type="checkbox"
                                checked={active}
                                onChange={() => {
                                    setActive(!active);
                                }}
                            />
                            <Label
                                check
                                htmlFor="admin-active"
                            >
                                Aktívny
                            </Label>
                        </FormGroup>
                    )}

                    <FormGroup>
                        <Label htmlFor="admin-name">
                            Meno
                        </Label>
                        <Input
                            id="admin-name"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="admin-surname">
                            Priezvisko
                        </Label>
                        <Input
                            id="admin-surname"
                            value={surname}
                            onChange={(e) => {
                                setSurname(e.target.value);
                            }}
                        />
                    </FormGroup>

                    {!isProfile && (
                        <FormGroup>
                            <Label htmlFor="admin-email">
                                Email
                            </Label>
                            <Input
                                id="admin-email"
                                invalid={wrongEmailFormat}
                                disabled={adminId}
                                value={email}
                                onChange={(e) => {
                                    setEmail(
                                        e.target.value
                                    );
                                    setWrongEmailFormat(
                                        false
                                    );
                                }}
                                onBlur={() => {
                                    if (!isEmail(email)) {
                                        setWrongEmailFormat(
                                            true
                                        );
                                    }
                                }}
                            />
                        </FormGroup>
                    )}
                    {wrongEmailFormat && (
                        <p>{'Email má nesprávny tvar!'}</p>
                    )}

                    {!isProfile && (
                        <FormGroup>
                            <Label htmlFor="admin-pass">
                                Heslo
                            </Label>
                            <InputGroup>
                                <Input
                                    id="admin-pass"
                                    invalid={
                                        passwordErrors.length >
                                        0
                                    }
                                    value={password}
                                    type={
                                        password1Visible
                                            ? 'text'
                                            : 'password'
                                    }
                                    onChange={(e) => {
                                        setPassword(
                                            e.target.value
                                        );
                                    }}
                                    onBlur={() =>
                                        checkPasswords(true)
                                    }
                                />
                                <Button
                                    onClick={() => {
                                        setPassword1Visible(
                                            !password1Visible
                                        );
                                    }}
                                >
                                    <MaterialSymbol
                                        icon={
                                            password1Visible
                                                ? 'visibility_off'
                                                : 'visibility'
                                        }
                                        size={24}
                                        fill
                                        grade={-25}
                                        color="white"
                                    />
                                </Button>
                            </InputGroup>
                        </FormGroup>
                    )}
                    {!isProfile && (
                        <FormGroup>
                            <Label htmlFor="admin-pass2">
                                Zopakujte heslo
                            </Label>
                            <InputGroup>
                                <Input
                                    id="admin-pass2"
                                    invalid={
                                        passwordErrors.length >
                                        0
                                    }
                                    value={password2}
                                    type={
                                        password2Visible
                                            ? 'text'
                                            : 'password'
                                    }
                                    onChange={(e) => {
                                        setPassword2(
                                            e.target.value
                                        );
                                    }}
                                    onBlur={() => {
                                        checkPasswords();
                                    }}
                                />
                                <Button
                                    onClick={() => {
                                        setPassword2Visible(
                                            !password2Visible
                                        );
                                    }}
                                >
                                    <MaterialSymbol
                                        icon={
                                            password2Visible
                                                ? 'visibility_off'
                                                : 'visibility'
                                        }
                                        size={24}
                                        fill
                                        grade={-25}
                                        color="white"
                                    />
                                </Button>
                            </InputGroup>
                        </FormGroup>
                    )}

                    {passwordErrors.map((error, index) => (
                        <p key={index}>{error}</p>
                    ))}

                    {!adminId && !isProfile && (
                        <FormGroup>
                            <Label htmlFor="admin-phone-number">
                                Mobilné číslo
                            </Label>
                            <Input
                                id="admin-phone-number"
                                value={phoneNumber}
                                onChange={(e) => {
                                    setPhoneNumber(
                                        e.target.value
                                    );
                                }}
                            />
                        </FormGroup>
                    )}
                    <FormGroup>
                        <Label htmlFor="admin-language">
                            Jazyk stránky
                        </Label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={false}
                            isSearchable={true}
                            name="admin-language"
                            options={languages}
                            value={language}
                            onChange={(lang) => {
                                setLanguage(lang);
                            }}
                        />
                    </FormGroup>
                    {!isProfile && (
                        <FormGroup check>
                            <Input
                                id="admin-settings"
                                type="checkbox"
                                checked={settings}
                                onChange={() => {
                                    setSettings(!settings);
                                }}
                            />
                            <Label
                                check
                                htmlFor="admin-settings"
                            >
                                Právo Nastavenia
                            </Label>
                        </FormGroup>
                    )}
                    <Row>
                        <Col>
                            {isProfile && (
                                <AdminChangeEmail />
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {isProfile && (
                                <AdminChangePassword />
                            )}
                        </Col>
                    </Row>
                </Form>
            </Row>

            <Row xs="2">
                <Col>
                    <Button
                        color="secondary"
                        block
                        onClick={onClose}
                    >
                        Zrušiť
                    </Button>
                </Col>
                <Col className="flex jc-flex-end">
                    <Button
                        color="primary"
                        block
                        onClick={() => {
                            let data = {
                                name,
                                surname,
                                email,
                                password,
                                active,
                                phoneNumber,
                                languageId: language.value,
                                settings,
                            };
                            if (image) {
                                data = {
                                    ...data,
                                    icon: image,
                                };
                            }
                            onSave(data);
                        }}
                    >
                        Uložiť
                    </Button>
                </Col>
            </Row>
        </Card>
    );
}
