import React, { useState } from 'react';
import {
    ADD_OR_UPDATE_SMTP,
    TEST_SMTP,
    GET_SMTP,
    SMTP_SUBSCRIPTION,
} from 'queries';
import SMTPForm from './form';
import {
    useMutation,
    useQuery,
    useSubscription,
} from '@apollo/client';
import {
    Card,
    Row,
    Col,
    Table,
    Spinner,
    Button,
} from 'reactstrap';
import wellKnownOptions from 'configs/wellKnown';

export default function SMTP() {
    const [addOrUpdateSMTP] = useMutation(
        ADD_OR_UPDATE_SMTP
    );
    const [testSMTP] = useMutation(TEST_SMTP);

    const {
        data: smtpData,
        loading: smtpLoading,
        refetch: smtpRefetch,
    } = useQuery(GET_SMTP, {
        fetchPolicy: 'network-only',
    });

    useSubscription(SMTP_SUBSCRIPTION, {
        onData: () => {
            smtpRefetch();
            setTesting(false);
        },
    });

    const [showOverView, setShowOVerView] = useState(true);
    const [testing, setTesting] = useState(false);

    const addOrUpdateSMTPFunc = (data) => {
        addOrUpdateSMTP({
            variables: {
                ...data,
            },
        }).then(() => {
            setShowOVerView(true);
        });
    };

    if (smtpLoading) {
        return <Spinner color="primary"></Spinner>;
    }

    if (showOverView) {
        return (
            <Card style={{ padding: '1em' }}>
                <Row>
                    <Col>Status</Col>
                    <Col>
                        {smtpData.smtp &&
                        smtpData.smtp.working
                            ? 'Funguje'
                            : `Nefunguje ${
                                  smtpData.smtp
                                      ? '- ' +
                                        smtpData.smtp
                                            .errorMessage
                                      : ''
                              }`}
                    </Col>
                    <Col className="flex jc-flex-end">
                        <Button
                            color="danger"
                            disabled={testing}
                            style={{ marginRight: '1rem' }}
                            onClick={() => {
                                setTesting(true);
                                testSMTP();
                            }}
                        >
                            {testing
                                ? 'Testuje sa...'
                                : 'Otestovať'}
                        </Button>
                        <Button
                            color="warning"
                            disabled={testing}
                            onClick={() => {
                                setShowOVerView(false);
                            }}
                        >
                            Upraviť
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Table>
                        <tbody>
                            <tr>
                                <td>Názov</td>
                                <th>
                                    {smtpData.smtp &&
                                    smtpData.smtp.title
                                        ? smtpData.smtp
                                              .title
                                        : 'Nenastavené'}
                                </th>
                            </tr>
                            <tr>
                                <td>Well Known</td>
                                <th>
                                    {smtpData.smtp
                                        ? wellKnownOptions.find(
                                              (option) =>
                                                  option.value ===
                                                  smtpData
                                                      .smtp
                                                      .wellKnown
                                          ).label
                                        : 'Nenastavené'}
                                </th>
                            </tr>
                            <tr>
                                <td>Hostiteľ</td>
                                <th>
                                    {smtpData.smtp &&
                                    smtpData.smtp.host
                                        ? smtpData.smtp.host
                                        : 'Nenastavené'}
                                </th>
                            </tr>
                            <tr>
                                <td>Port</td>
                                <th>
                                    {smtpData.smtp &&
                                    smtpData.smtp.port
                                        ? smtpData.smtp.port
                                        : 'Nenastavené'}
                                </th>
                            </tr>
                            <tr>
                                <td>Zabezpečený</td>
                                <th>
                                    {smtpData.smtp &&
                                    smtpData.smtp.secure
                                        ? 'Áno'
                                        : 'Nie'}
                                </th>
                            </tr>
                            <tr>
                                <td>Používateľské meno</td>
                                <th>
                                    {smtpData.smtp &&
                                    smtpData.smtp.username
                                        ? smtpData.smtp
                                              .username
                                        : 'Nenastavené'}
                                </th>
                            </tr>
                            <tr>
                                <td>Heslo</td>
                                <th>
                                    {smtpData.smtp &&
                                    smtpData.smtp.password
                                        ? smtpData.smtp
                                              .password
                                        : 'Nenastavené'}
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    Odmietnuť neautorizované
                                </td>
                                <th>
                                    {smtpData.smtp &&
                                    smtpData.smtp
                                        .rejectUnauthorized
                                        ? 'Áno'
                                        : 'Nie'}
                                </th>
                            </tr>
                        </tbody>
                    </Table>
                </Row>
            </Card>
        );
    }

    return (
        <SMTPForm
            onSave={addOrUpdateSMTPFunc}
            smtp={smtpData.smtp}
            onClose={() => {
                setShowOVerView(true);
            }}
        />
    );
}
