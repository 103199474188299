import React from 'react';
import {
    useMutation,
    useQuery,
    useSubscription,
} from '@apollo/client';
import PreparationTimes from './list';
import { Col, Row, Spinner } from 'reactstrap';
import {
    PREPARATOIN_TIMES,
    PREPARATOIN_TIMES_SUBSCRIPTION,
    ADD_PREPARATOIN_TIME,
    UPDATE_PREPARATOIN_TIME,
    DELETE_PREPARATOIN_TIME,
} from 'queries';

export default function PreparationTimesContainer() {
    const [addPreparationTime] = useMutation(ADD_PREPARATOIN_TIME);
    const [updatePreparationTime] = useMutation(
        UPDATE_PREPARATOIN_TIME
    );
    const [deletePreparationTime] = useMutation(
        DELETE_PREPARATOIN_TIME
    );

    const {
        data: preparationTimesData,
        loading: preparationTimesLoading,
        refetch: preparationTimesRefetch,
    } = useQuery(PREPARATOIN_TIMES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(PREPARATOIN_TIMES_SUBSCRIPTION, {
        onData: () => {
            preparationTimesRefetch();
        },
    });

    const onCreatePreparationTime = (data) => {
        addPreparationTime({
            variables: {
                available: data.available,
                order: data.order,
                title: data.title,
            },
        });
    };

    const onUpdatePreparationTime = (data) => {
        updatePreparationTime({
            variables: {
                updatePreparationTimeId: data.id,
                available: data.available,
                order: data.order,
                title: data.title,
            },
        });
    };
    const onRemovePreparationTime = (id) => {
        deletePreparationTime({
            variables: {
                deletePreparationTimeId: id,
            },
        });
    };

    if (preparationTimesLoading) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    return (
        <Col>
            <PreparationTimes
                preparationTimes={
                    preparationTimesData &&
                        preparationTimesData.preparationTimes
                        ? preparationTimesData.preparationTimes
                        : []
                }
                onCreatePreparationTime={onCreatePreparationTime}
                onUpdatePreparationTime={onUpdatePreparationTime}
                onRemovePreparationTime={onRemovePreparationTime}
            />
        </Col>
    );
}
