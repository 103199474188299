import React from 'react';
import { ADD_ADMIN, GET_LANGUAGES, LANGUAGES_SUBSCRIPTION } from 'queries';
import AdminForm from './form';
import { useQuery, useMutation, useSubscription } from '@apollo/client';

export default function AdminAdd(props) {
    const { onSave, onClose } = props;

    const [addAdmin] = useMutation(ADD_ADMIN);

    const {
        data: languagesData,
        refetch: languagesRefetch,
    } = useQuery(GET_LANGUAGES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(LANGUAGES_SUBSCRIPTION, {
        onData: () => {
            languagesRefetch();
        },
    });

    const createAdmin = (data) => {
        addAdmin({
            variables: {
                ...data,
            },
        }).then((response) => {
            onSave();
        });
    };

    const languageOptions = languagesData && languagesData.languages ?
        [...languagesData.languages]
            .sort((lang1, lang2) => lang1.languageCode > lang2.languageCode ? -1 : 1)
            .map((lang) => ({
                ...lang,
                value: lang.id,
                label: lang.languageCode
            })) : [];

    return (
        <AdminForm
            languages={languageOptions}
            onSave={createAdmin}
            onClose={onClose}
        />
    );
}
