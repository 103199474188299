import React from 'react';
import {
    useMutation,
    useQuery,
    useSubscription,
} from '@apollo/client';
import Diets from './list';
import { Col, Row, Spinner } from 'reactstrap';
import {
    DIETS,
    DIETS_SUBSCRIPTION,
    ADD_DIET,
    UPDATE_DIET,
    DELETE_DIET,
} from 'queries';

export default function DietsContainer() {
    const [addDiet] = useMutation(ADD_DIET);
    const [updateDiet] = useMutation(
        UPDATE_DIET
    );
    const [deleteDiet] = useMutation(
        DELETE_DIET
    );

    const {
        data: dietsData,
        loading: dietsLoading,
        refetch: dietsRefetch,
    } = useQuery(DIETS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(DIETS_SUBSCRIPTION, {
        onData: () => {
            dietsRefetch();
        },
    });

    const onCreateDiet = (data) => {
        addDiet({
            variables: {
                available: data.available,
                order: data.order,
                title: data.title,
            },
        });
    };

    const onUpdateDiet = (data) => {
        updateDiet({
            variables: {
                updateDietId: data.id,
                available: data.available,
                order: data.order,
                title: data.title,
            },
        });
    };
    const onRemoveDiet = (id) => {
        deleteDiet({
            variables: {
                deleteDietId: id,
            },
        });
    };

    if (dietsLoading) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    return (
        <Col>
            <Diets
                diets={
                    dietsData &&
                        dietsData.diets
                        ? dietsData.diets
                        : []
                }
                onCreateDiet={onCreateDiet}
                onUpdateDiet={onUpdateDiet}
                onRemoveDiet={onRemoveDiet}
            />
        </Col>
    );
}
