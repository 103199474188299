import React, { useEffect, useState } from 'react';
import {
    FormGroup,
    Label,
    Input,
    Form,
    Button,
    Card,
    Row,
    Col,
    Spinner,
    Table,
} from 'reactstrap';
import Select from 'react-select';
import translate from 'translate';

const UNITS = [
    {
        label: 'Piece',
        value: 'piece',
    },
    {
        label: 'Liter',
        value: 'liter',
    },
    {
        label: 'Kilo',
        value: 'kilo',
    },
    {
        label: 'Teaspoon',
        value: 'teaspoon',
    },
    {
        label: 'Pinch',
        value: 'pinch',
    },
    {
        label: 'Tablespoon',
        value: 'tablespoon',
    },
    {
        label: 'Handful',
        value: 'handful',
    },
];

export default function IngredientForm(props) {
    const {
        onClose,
        onSave,
        ingredientId,
        ingredientData,
        ingredientLoading,
        languages,
    } = props;

    const [units, setUnits] = useState([]);
    const [conversions, setConversions] = useState([]);
    const [translations, setTranslations] = useState([]);
    const [translating, setTranslating] = useState(false);
    const [originalTranslation, setOriginalTranslation] = useState(null);

    useEffect(() => {
        if (ingredientData && ingredientData.ingredient) {
            setTranslations(languages.map((item) => ({
                language: item,
                translation: ingredientData.ingredient.translations.find((translation) => translation.language.id === item.id)?.title,
            })))
            setUnits(
                ingredientData.ingredient.units.map(
                    (unit) =>
                        UNITS.find((u) => u.value === unit)
                )
            );
            setConversions(
                ingredientData.ingredient.conversions
            );
        } else if (languages) {
            setTranslations(languages.map((item) => ({
                language: item,
                translation: "",
            })))

            setUnits([])
            setConversions([])
        }
    }, [ingredientData, languages]);

    async function getOtherTitles(original) {
        if (!original) {
            return;
        }

        let newTranslations = [...translations];

        for (let index = 0; index < translations.length; index++) {
            const element = translations[index];
            if (element.language.id !== original.language.id) {
                let translatedTitle = await translate(original.translation, { from: original.language.languageCode, to: element.language.languageCode });
                newTranslations[index] = ({
                    ...element,
                    translation: translatedTitle,
                })
            }
        }

        setTranslations(newTranslations);
        setTranslating(false);
        setOriginalTranslation(null);
    }

    if (ingredientLoading) {
        return (
            <Card style={{ padding: '1em' }}>
                <Spinner color="primary" size="sm" />
            </Card>
        );
    }

    return (
        <Card style={{ padding: '1em' }}>
            <Row>
                <Form>
                    <FormGroup>
                        <Label htmlFor="trending-group-title">
                            Názov
                        </Label>
                        <Table>
                            <thead>
                                <tr>

                                    <th>Jazyk</th>
                                    <th>Preklad</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    translations.map((translation) => (
                                        <tr key={translation.language.id}>
                                            <td>{`${translation.language.title} (${translation.language.languageCode})`}</td>
                                            <td>
                                                {
                                                    translating && translation.language.id !== originalTranslation.language.id &&
                                                    <Spinner />
                                                }
                                                {
                                                    (!translating || translation.language.id === originalTranslation.language.id) &&
                                                    <>
                                                        <Input
                                                            id={`ingredient_translation_for_${translation.languageCode}`}
                                                            disabled={translating}
                                                            value={
                                                                translation.translation
                                                            }
                                                            onChange={(
                                                                e
                                                            ) => {
                                                                setTranslations(translations.map((item) => {
                                                                    if (item.language.id === translation.language.id) {
                                                                        return ({
                                                                            ...item,
                                                                            translation: e.target.value,
                                                                        })
                                                                    }
                                                                    return item;
                                                                }))
                                                            }}
                                                        />
                                                        {
                                                            translation.translation.length > 0 &&
                                                            <Button color='link' onClick={() => {
                                                                setTranslating(true);
                                                                setOriginalTranslation(translation);
                                                                getOtherTitles(translation);
                                                            }}>Preložiť podľa tohto prekladu</Button>
                                                        }
                                                    </>
                                                }
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="trending-group-chefs">
                            Jednotky
                        </Label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={true}
                            isSearchable={true}
                            isMulti
                            name="trending-group-chefs"
                            options={UNITS}
                            value={units}
                            onChange={(e) => {
                                setUnits(e);
                                if (
                                    units.length > e.length
                                ) {
                                    const removedUnit =
                                        units.find(
                                            (unit) =>
                                                !e.find(
                                                    (u) =>
                                                        u.value ===
                                                        unit.value
                                                )
                                        ).value;
                                    let newConverstions =
                                        conversions.filter(
                                            (conversion) =>
                                                conversion.fromUnit !==
                                                removedUnit &&
                                                conversion.toUnit !==
                                                removedUnit
                                        );
                                    setConversions(
                                        newConverstions
                                    );
                                } else if (
                                    units.length < e.length
                                ) {
                                    const addedUnit =
                                        e.find(
                                            (unit) =>
                                                !units.find(
                                                    (u) =>
                                                        u.value ===
                                                        unit.value
                                                )
                                        ).value;
                                    let newConverstions = [
                                        ...conversions,
                                    ];
                                    for (
                                        let index = 0;
                                        index <
                                        units.length;
                                        index++
                                    ) {
                                        newConverstions.push(
                                            {
                                                fromUnit:
                                                    addedUnit,
                                                toUnit: units[
                                                    index
                                                ].value,
                                                multiplier: 1,
                                            }
                                        );
                                        setConversions(
                                            newConverstions.sort(
                                                (c1, c2) =>
                                                    `${c1.fromUnit}-to-${c1.toUnit}` <
                                                        `${c2.fromUnit}-to-${c2.toUnit}`
                                                        ? -1
                                                        : 1
                                            )
                                        );
                                    }
                                }
                            }}
                        />
                    </FormGroup>

                    {units.length >= 2 && (
                        <FormGroup>
                            <Label htmlFor="trending-group-title">
                                Prevody jednotiek
                            </Label>
                            <ul>

                                {conversions.map(
                                    (conversion) => (
                                        <li
                                            key={`${conversion.fromUnit}-to-${conversion.toUnit}`}
                                            style={{ display: 'flex', alignItems: "center", marginBottom: "8px" }}
                                        >
                                            <span>{`1 ${conversion.fromUnit} * `}</span>
                                            <Input
                                                id={`${conversion.fromUnit}-to-${conversion.toUnit}`}
                                                value={
                                                    conversion.multiplier
                                                }
                                                style={{ width: "100px", marginLeft: "8px", marginRight: "8px" }}
                                                step={0.05}
                                                min={0.05}
                                                type="number"
                                                onChange={(
                                                    e
                                                ) => {
                                                    setConversions(
                                                        conversions.map(
                                                            (
                                                                conv
                                                            ) =>
                                                                conv.fromUnit ===
                                                                    conversion.fromUnit &&
                                                                    conv.toUnit ===
                                                                    conversion.toUnit
                                                                    ? {
                                                                        ...conversion,
                                                                        multiplier:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    }
                                                                    : conv
                                                        )
                                                    );
                                                }}
                                            />

                                            <span>{` = 1 ${conversion.toUnit}`}</span>
                                        </li>
                                    )
                                )}
                            </ul>

                        </FormGroup>
                    )}
                </Form>
            </Row>

            <Row xs="2">
                <Col>
                    <Button
                        color="secondary"
                        block
                        onClick={onClose}
                    >
                        Zrušiť
                    </Button>
                </Col>
                <Col>
                    <Button
                        color="primary"
                        block
                        disabled={translations.some((translation) => translation.translation.length === 0)}
                        onClick={() => {
                            onSave({
                                id: ingredientId,
                                units,
                                conversions,
                                translations,
                            });
                        }}
                    >
                        Uložiť
                    </Button>
                </Col>
            </Row>
        </Card>
    );
}
