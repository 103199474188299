import { gql } from '@apollo/client';

export const API_SETTING_SUBSCRIPTION = gql`
    subscription apiSettingSubscription {
        apiSettingSubscription
    }
`;

export const API_SETTING = gql`
    query apiSetting {
        apiSetting {
            googleKey
            vimeoId
            vimeoSecret
            vimeoToken
        }
    }
`;

export const UPDATE_API_SETTING = gql`
    mutation updateApiSetting(
        $googleKey: String
        $vimeoId: String
        $vimeoSecret: String
        $vimeoToken: String
    ) {
        updateApiSetting(
            googleKey: $googleKey
            vimeoId: $vimeoId
            vimeoSecret: $vimeoSecret
            vimeoToken: $vimeoToken
        ) {
            vimeoId
        }
    }
`;
