import React, { useEffect } from 'react';
import {
    GET_RECIPE,
} from 'queries';
import {
    useQuery,
} from '@apollo/client';
import {
    Row,
    Col,
    Table,
    Spinner,
    Button,
} from 'reactstrap';

export default function Recipe(props) {

    const {
        closeModal,
        recipeId,
    } = props;

    const {
        data: recipeData,
        loading: recipeLoading,
        refetch: recipeRefetch,
    } = useQuery(GET_RECIPE, {
        variables: {
            id: recipeId,
        },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        recipeRefetch({
            id: recipeId,
        })
    }, [recipeId])

    if (recipeLoading) {
        return <Spinner color="primary"></Spinner>;
    }

    return (
        <>

            <Row>
                <Table>
                    <tbody>
                        <tr>
                            <td>Názov</td>
                            <th>
                            </th>
                        </tr>
                    </tbody>
                </Table>
            </Row>
            <Row>
                <Col className="flex jc-flex-end">
                    <Button
                        color="secondary"
                        block
                        onClick={() => {
                            closeModal()
                        }}
                    >
                        Zavrieť
                    </Button>
                </Col>
            </Row>
        </>
    );

}
