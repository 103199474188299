export const getLabel = (translations, languageId, attribute = "title") => {
    if (!translations || translations.length === 0) {
        return "Bez názvu";
    }
    let translation = translations.find((translation) => translation.language?.languageCode === languageId);
    if (!translation && attribute === "video") {
        return null;
    }
    if (!translation) {
        return `${translations[0][attribute]} (${translations[0].language?.title} - ${translations[0].language?.languageCode})`;
    }
    return translation[attribute];
}