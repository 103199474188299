import React from 'react';
import { useQuery } from '@apollo/client';
import { NEW_INGREDIENT } from 'queries';
import NewIngredientForm from './form';

export default function NewIngredientLoader(props) {
    const { newIngredientId } = props;

    const {
        data: newIngredientData,
        loading: newIngredientLoading,
    } = useQuery(NEW_INGREDIENT, {
        variables: {
            newIngredientId,
        },
        fetchPolicy: 'network-only',
    });

    return (
        <NewIngredientForm
            {...props}
            newIngredientData={newIngredientData}
            newIngredientLoading={newIngredientLoading}
        />
    );
}
