import React, { useState } from 'react';
import {
    useMutation,
    useQuery,
    useSubscription,
} from '@apollo/client';
import {
    ADD_NOTIFICATION,
    GET_NOTIFICATIONS,
    NOTIFICATIONS_SUBSCRIPTION,
    COURIERS,
    COURIERS_SUBSCRIPTION,
    RUNNERS,
    RUNNERS_SUBSCRIPTION,
    STORES,
    STORES_SUBSCRIPTION,
    USERS,
    USERS_SUBSCRIPTION,
    CHEFS,
    CHEFS_SUBSCRIPTION,
    ADMINS,
    ADMINS_SUBSCRIPTION,
    SET_NOTIFICATION_SEEN,
    SET_ALL_NOTIFICATIONS_SEEN,
} from 'queries';
import {
    Input,
    Button,
    Card,
    Row,
    Col,
    Table,
    Form,
    Label,
    FormGroup,
    Spinner,
} from 'reactstrap';
import { timestampToStringFNS } from 'utils';
import { MaterialSymbol } from 'react-material-symbols';
import Select from 'react-select';

export default function Notifications() {
    const [addNotification] = useMutation(ADD_NOTIFICATION);
    const [setNotificationSeen] = useMutation(
        SET_NOTIFICATION_SEEN
    );
    const [setAllNotificationsSeen] = useMutation(
        SET_ALL_NOTIFICATIONS_SEEN
    );

    const [showDetail, setShowDetail] = useState(false);
    const [chosenNotification, setChosenNotification] =
        useState(null);
    const [showAddNotification, setShowAddNotification] =
        useState(false);
    const [message, setMessage] = useState('');
    const [userReceivers, setUserReceivers] = useState([]);
    const [storeReceivers, setStoreReceivers] = useState(
        []
    );
    const [runnerReceivers, setRunnerReceivers] = useState(
        []
    );
    const [courierReceivers, setCourierReceivers] =
        useState([]);
    const [chefReceivers, setChefReceivers] = useState([]);
    const [adminReceivers, setAdminReceivers] = useState(
        []
    );
    const [title, setTitle] = useState('');

    const {
        data: notificationsData,
        loading: notificationsLoading,
        refetch: notificationsRefetch,
    } = useQuery(GET_NOTIFICATIONS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(NOTIFICATIONS_SUBSCRIPTION, {
        onData: () => {
            notificationsRefetch();
        },
    });

    const {
        data: adminsData,
        refetch: adminsRefetch,
    } = useQuery(ADMINS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(ADMINS_SUBSCRIPTION, {
        onData: () => {
            adminsRefetch();
        },
    });

    const {
        data: couriersData,
        refetch: couriersRefetch,
    } = useQuery(COURIERS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(COURIERS_SUBSCRIPTION, {
        onData: () => {
            couriersRefetch();
        },
    });

    const {
        data: runnersData,
        refetch: runnersRefetch,
    } = useQuery(RUNNERS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(RUNNERS_SUBSCRIPTION, {
        onData: () => {
            runnersRefetch();
        },
    });

    const {
        data: storesData,
        refetch: storesRefetch,
    } = useQuery(STORES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(STORES_SUBSCRIPTION, {
        onData: () => {
            storesRefetch();
        },
    });

    const {
        data: usersData,
        refetch: usersRefetch,
    } = useQuery(USERS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(USERS_SUBSCRIPTION, {
        onData: () => {
            usersRefetch();
        },
    });

    const {
        data: chefsData,
        refetch: chefsRefetch,
    } = useQuery(CHEFS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(CHEFS_SUBSCRIPTION, {
        onData: () => {
            chefsRefetch();
        },
    });

    const mapReceiversToBackend = () => {
        let receivers = [];
        if (
            adminReceivers.find(
                (receiver) => receiver.value === null
            )
        ) {
            for (
                let index = 0;
                index < adminsData.admins.length;
                index++
            ) {
                receivers.push({
                    id: adminsData.admins[index].id,
                    type: 'admin',
                });
            }
        } else {
            adminReceivers.forEach((receiver) => {
                receivers.push({
                    id: receiver.id,
                    type: receiver.type,
                });
            });
        }
        if (
            storeReceivers.find(
                (receiver) => receiver.value === null
            )
        ) {
            for (
                let index = 0;
                index < storesData.stores.length;
                index++
            ) {
                receivers.push({
                    id: storesData.stores[index].id,
                    type: 'store',
                });
            }
        } else {
            storeReceivers.forEach((receiver) => {
                receivers.push({
                    id: receiver.id,
                    type: receiver.type,
                });
            });
        }
        if (
            userReceivers.find(
                (receiver) => receiver.value === null
            )
        ) {
            for (
                let index = 0;
                index < usersData.stores.length;
                index++
            ) {
                receivers.push({
                    id: usersData.users[index].id,
                    type: 'user',
                });
            }
        } else {
            userReceivers.forEach((receiver) => {
                receivers.push({
                    id: receiver.id,
                    type: receiver.type,
                });
            });
        }
        if (
            runnerReceivers.find(
                (receiver) => receiver.value === null
            )
        ) {
            for (
                let index = 0;
                index < runnersData.runners.length;
                index++
            ) {
                receivers.push({
                    id: runnersData.runners[index].id,
                    type: 'runner',
                });
            }
        } else {
            runnerReceivers.forEach((receiver) => {
                receivers.push({
                    id: receiver.id,
                    type: receiver.type,
                });
            });
        }
        if (
            chefReceivers.find(
                (receiver) => receiver.value === null
            )
        ) {
            for (
                let index = 0;
                index < chefsData.chefs.length;
                index++
            ) {
                receivers.push({
                    id: chefsData.chefs[index].id,
                    type: 'chef',
                });
            }
        } else {
            chefReceivers.forEach((receiver) => {
                receivers.push({
                    id: receiver.id,
                    type: receiver.type,
                });
            });
        }
        if (
            courierReceivers.find(
                (receiver) => receiver.value === null
            )
        ) {
            for (
                let index = 0;
                index < couriersData.couriers.length;
                index++
            ) {
                receivers.push({
                    id: couriersData.couriers[index].id,
                    type: 'courier',
                });
            }
        } else {
            courierReceivers.forEach((receiver) => {
                receivers.push({
                    id: receiver.id,
                    type: receiver.type,
                });
            });
        }

        return receivers;
    };

    const addNotificationFunc = () => {
        addNotification({
            variables: {
                message,
                receivers: mapReceiversToBackend(),
                title,
            },
        }).then((response) => {
            setTitle('');
            setUserReceivers([]);
            setRunnerReceivers([]);
            setAdminReceivers([]);
            setStoreReceivers([]);
            setChefReceivers([]);
            setChefReceivers([]);
            setMessage('');
        });
    };

    const setNotificationSeenFunc = (
        setNotificationSeenId
    ) => {
        setNotificationSeen({
            variables: {
                id: setNotificationSeenId,
            },
        });
    };

    const setAllNotificationsSeenFunc = () => {
        setAllNotificationsSeen();
    };

    const deleteNotificationFunc = (
        deleteNotificationId
    ) => {
        setNotificationSeen({
            variables: {
                id: deleteNotificationId,
            },
        });
    };

    if (notificationsLoading) {
        return (
            <Card style={{ padding: '1em' }}>
                <Row>
                    <Spinner color="primary" />
                </Row>
            </Card>
        );
    }

    return (
        <Row style={{ padding: '1em' }}>
            <Col>
                <Row className="flex jc-space-between">
                    <Col>
                        <Button
                            color="link"
                            onClick={() => {
                                setAllNotificationsSeenFunc();
                            }}
                        >
                            Označiť všetky ako prečítané
                        </Button>
                    </Col>
                    <Col className="flex jc-flex-end">
                        <Button
                            color="link"
                            onClick={() => {
                                setShowDetail(false);
                                setChosenNotification(null);
                                setShowAddNotification(
                                    true
                                );
                            }}
                        >
                            Nová správa
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Table>
                        <thead>
                            <tr>
                                <th>Predmet</th>
                                <th className="ta-center">
                                    Prečítané
                                </th>
                                <th>Odoslané dňa</th>
                            </tr>
                        </thead>
                        <tbody>
                            {notificationsData.notifications
                                .length === 0 && (
                                    <tr>
                                        <td colSpan={3}>
                                            Nemáte žiadne
                                            notifikácie
                                        </td>
                                    </tr>
                                )}
                            {notificationsData.notifications.map(
                                (notification) => (
                                    <tr
                                        key={
                                            notification.id
                                        }
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            if (
                                                !notification.seen
                                            ) {
                                                setNotificationSeenFunc(
                                                    notification.id
                                                );
                                            }
                                            setChosenNotification(
                                                notification
                                            );
                                            setShowDetail(
                                                true
                                            );
                                            setShowAddNotification(
                                                false
                                            );
                                        }}
                                    >
                                        <td>
                                            {
                                                notification.title
                                            }
                                        </td>
                                        <td className="ta-center">
                                            <MaterialSymbol
                                                icon={
                                                    notification.seen
                                                        ? 'check_circle'
                                                        : 'cancel'
                                                }
                                                size={24}
                                                grade={-25}
                                                color={
                                                    notification.seen
                                                        ? 'green'
                                                        : 'red'
                                                }
                                            />
                                        </td>
                                        <td>
                                            {timestampToStringFNS(
                                                notification.createdAt
                                            )}
                                        </td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </Table>
                </Row>
            </Col>

            {showDetail && (
                <Col>
                    <Row>
                        <h2>
                            {`Predmet: `}
                            <span
                                style={{
                                    fontWeight: 'bold',
                                }}
                            >
                                {chosenNotification?.title
                                    ? chosenNotification.title
                                    : 'Bez predmetu'}
                            </span>
                        </h2>
                    </Row>
                    <Row>
                        <p>
                            <span
                                style={{
                                    fontWeight: 'bold',
                                }}
                            >
                                {`Správa: `}
                            </span>
                            {chosenNotification?.message
                                ? chosenNotification.message
                                : 'Bez obsahu'}
                        </p>
                    </Row>
                    <Row xs="2">
                        <Col></Col>
                        <Col className="flex jc-flex-end">
                            <Button
                                color="danger"
                                block
                                onClick={() => {
                                    deleteNotificationFunc(
                                        chosenNotification.id
                                    );
                                }}
                            >
                                Odstrániť
                            </Button>
                        </Col>
                    </Row>
                </Col>
            )}
            {showAddNotification && (
                <Col>
                    <Form>
                        <FormGroup>
                            <Label htmlFor="notification-title">
                                Predmet
                            </Label>
                            <Input
                                id="notification-title"
                                value={title}
                                onChange={(e) => {
                                    setTitle(
                                        e.target.value
                                    );
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="receivers-admin">
                                Prijímatelia - admini
                            </Label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={true}
                                isSearchable={true}
                                isMulti
                                name="receivers-admin"
                                options={[
                                    {
                                        label: 'Všetci admini',
                                        value: null,
                                    },
                                    ...adminsData.admins.map(
                                        (admin) => ({
                                            ...admin,
                                            label: admin.fullName,
                                            value: admin.id,
                                            type: 'admin',
                                        })
                                    ),
                                ]}
                                value={adminReceivers}
                                onChange={(options) => {
                                    let wasSendToAll =
                                        adminReceivers.find(
                                            (option) =>
                                                option.value ===
                                                null
                                        );
                                    let sendToAll =
                                        options.find(
                                            (option) =>
                                                option.value ===
                                                null
                                        );
                                    if (
                                        wasSendToAll &&
                                        options.length > 0
                                    ) {
                                        setAdminReceivers(
                                            options.filter(
                                                (option) =>
                                                    option.value !==
                                                    null
                                            )
                                        );
                                    } else if (sendToAll) {
                                        setAdminReceivers([
                                            {
                                                label: 'Všetci admini',
                                                value: null,
                                            },
                                        ]);
                                    } else {
                                        setAdminReceivers(
                                            options
                                        );
                                    }
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="receivers-store">
                                Prijímatelia - obchody
                            </Label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={true}
                                isSearchable={true}
                                isMulti
                                name="receivers-store"
                                options={[
                                    {
                                        label: 'Všetky obchody',
                                        value: null,
                                    },
                                    ...storesData.stores.map(
                                        (store) => ({
                                            ...store,
                                            label: store.title,
                                            value: store.id,
                                            type: 'store',
                                        })
                                    ),
                                ]}
                                value={storeReceivers}
                                onChange={(options) => {
                                    let wasSendToAll =
                                        storeReceivers.find(
                                            (option) =>
                                                option.value ===
                                                null
                                        );
                                    let sendToAll =
                                        options.find(
                                            (option) =>
                                                option.value ===
                                                null
                                        );
                                    if (
                                        wasSendToAll &&
                                        options.length > 0
                                    ) {
                                        setStoreReceivers(
                                            options.filter(
                                                (option) =>
                                                    option.value !==
                                                    null
                                            )
                                        );
                                    } else if (sendToAll) {
                                        setStoreReceivers([
                                            {
                                                label: 'Všetky obchody',
                                                value: null,
                                            },
                                        ]);
                                    } else {
                                        setStoreReceivers(
                                            options
                                        );
                                    }
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="receivers-customer">
                                Prijímatelia - zákazníci
                            </Label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={true}
                                isSearchable={true}
                                isMulti
                                name="receivers-customer"
                                options={[
                                    {
                                        label: 'Všetci zákazníci',
                                        value: null,
                                    },
                                    ...usersData.users.map(
                                        (user) => ({
                                            ...user,
                                            label: user.fullName,
                                            value: user.id,
                                            type: 'user',
                                        })
                                    ),
                                ]}
                                value={userReceivers}
                                onChange={(options) => {
                                    let wasSendToAll =
                                        userReceivers.find(
                                            (option) =>
                                                option.value ===
                                                null
                                        );
                                    let sendToAll =
                                        options.find(
                                            (option) =>
                                                option.value ===
                                                null
                                        );
                                    if (
                                        wasSendToAll &&
                                        options.length > 0
                                    ) {
                                        setUserReceivers(
                                            options.filter(
                                                (option) =>
                                                    option.value !==
                                                    null
                                            )
                                        );
                                    } else if (sendToAll) {
                                        setUserReceivers([
                                            {
                                                label: 'Všetci zákazníci',
                                                value: null,
                                            },
                                        ]);
                                    } else {
                                        setUserReceivers(
                                            options
                                        );
                                    }
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="receivers-runner">
                                Prijímatelia - runneri
                            </Label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={true}
                                isSearchable={true}
                                name="receivers-runner"
                                isMulti
                                options={[
                                    {
                                        label: 'Všetci runneri',
                                        value: null,
                                    },
                                    ...runnersData.runners.map(
                                        (runner) => ({
                                            ...runner,
                                            label: runner.fullName,
                                            value: runner.id,
                                            type: 'runner',
                                        })
                                    ),
                                ]}
                                value={runnerReceivers}
                                onChange={(options) => {
                                    let wasSendToAll =
                                        runnerReceivers.find(
                                            (option) =>
                                                option.value ===
                                                null
                                        );
                                    let sendToAll =
                                        options.find(
                                            (option) =>
                                                option.value ===
                                                null
                                        );
                                    if (
                                        wasSendToAll &&
                                        options.length > 0
                                    ) {
                                        setRunnerReceivers(
                                            options.filter(
                                                (option) =>
                                                    option.value !==
                                                    null
                                            )
                                        );
                                    } else if (sendToAll) {
                                        setRunnerReceivers([
                                            {
                                                label: 'Všetci runneri',
                                                value: null,
                                            },
                                        ]);
                                    } else {
                                        setRunnerReceivers(
                                            options
                                        );
                                    }
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="receivers-courier">
                                Prijímatelia - kurieri
                            </Label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={true}
                                isSearchable={true}
                                name="receivers-courier"
                                isMulti
                                options={[
                                    {
                                        label: 'Všetci kurieri',
                                        value: null,
                                    },
                                    ...couriersData.couriers.map(
                                        (courier) => ({
                                            ...courier,
                                            label: courier.fullName,
                                            value: courier.id,
                                            type: 'courier',
                                        })
                                    ),
                                ]}
                                value={courierReceivers}
                                onChange={(options) => {
                                    let wasSendToAll =
                                        courierReceivers.find(
                                            (option) =>
                                                option.value ===
                                                null
                                        );
                                    let sendToAll =
                                        options.find(
                                            (option) =>
                                                option.value ===
                                                null
                                        );
                                    if (
                                        wasSendToAll &&
                                        options.length > 0
                                    ) {
                                        setCourierReceivers(
                                            options.filter(
                                                (option) =>
                                                    option.value !==
                                                    null
                                            )
                                        );
                                    } else if (sendToAll) {
                                        setCourierReceivers(
                                            [
                                                {
                                                    label: 'Všetci kurieri',
                                                    value: null,
                                                },
                                            ]
                                        );
                                    } else {
                                        setCourierReceivers(
                                            options
                                        );
                                    }
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="receivers-chef">
                                Prijímatelia - šéfkuchári
                            </Label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={true}
                                isSearchable={true}
                                name="receivers-chef"
                                isMulti
                                options={[
                                    {
                                        label: 'Všetci šéfkuchári',
                                        value: null,
                                    },
                                    ...chefsData.chefs.map(
                                        (chef) => ({
                                            ...chef,
                                            label: chef.fullName,
                                            value: chef.id,
                                            type: 'chef',
                                        })
                                    ),
                                ]}
                                value={chefReceivers}
                                onChange={(options) => {
                                    let wasSendToAll =
                                        chefReceivers.find(
                                            (option) =>
                                                option.value ===
                                                null
                                        );
                                    let sendToAll =
                                        options.find(
                                            (option) =>
                                                option.value ===
                                                null
                                        );
                                    if (
                                        wasSendToAll &&
                                        options.length > 0
                                    ) {
                                        setChefReceivers(
                                            options.filter(
                                                (option) =>
                                                    option.value !==
                                                    null
                                            )
                                        );
                                    } else if (sendToAll) {
                                        setChefReceivers([
                                            {
                                                label: 'Všetci šéfkuchári',
                                                value: null,
                                            },
                                        ]);
                                    } else {
                                        setChefReceivers(
                                            options
                                        );
                                    }
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="notification-message">
                                Správa *
                            </Label>
                            <Input
                                id="notification-message"
                                value={message}
                                onChange={(e) => {
                                    setMessage(
                                        e.target.value
                                    );
                                }}
                            />
                        </FormGroup>
                        <Row xs="2">
                            <Col>
                                <Button
                                    color="secondary"
                                    block
                                    onClick={() => {
                                        setTitle('');
                                        setUserReceivers(
                                            []
                                        );
                                        setRunnerReceivers(
                                            []
                                        );
                                        setAdminReceivers(
                                            []
                                        );
                                        setStoreReceivers(
                                            []
                                        );
                                        setChefReceivers(
                                            []
                                        );
                                        setChefReceivers(
                                            []
                                        );
                                        setMessage('');
                                    }}
                                >
                                    Zrušiť
                                </Button>
                            </Col>
                            <Col className="flex jc-flex-end">
                                <Button
                                    color="primary"
                                    block
                                    disabled={
                                        userReceivers.length +
                                        storeReceivers.length +
                                        adminReceivers.length +
                                        chefReceivers.length +
                                        runnerReceivers.length +
                                        courierReceivers.length ===
                                        0
                                    }
                                    onClick={() => {
                                        addNotificationFunc();
                                    }}
                                >
                                    Poslať notifikáciu
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            )}
        </Row>
    );
}
