import { gql } from '@apollo/client';

export const COURIERS_SUBSCRIPTION = gql`
    subscription couriersSubscription {
        couriersSubscription
    }
`;

export const COURIERS = gql`
    query couriers {
        couriers {
            id
            email
            fullName
            active
            verified
        }
    }
`;

export const GET_COURIER = gql`
    query courier($courierId: Int!) {
        courier(id: $courierId) {
            id
            active
            deactivationDate
            verified
            email
            name
            surname
            fullName
            iban
            phoneNumber
            siteLanguage
            iconPath
            stores {
                id
                title
            }
        }
    }
`;

export const REGISTER_COURIER = gql`
    mutation registerCourier(
        $email: String!
        $name: String!
        $surname: String!
        $iban: String!
        $phoneNumber: String!
        $siteLanguage: LanguageEnum!
        $icon: String
        $password: String
        $stores: [Int]
    ) {
        registerCourier(
            email: $email
            name: $name
            surname: $surname
            iban: $iban
            phoneNumber: $phoneNumber
            siteLanguage: $siteLanguage
            icon: $icon
            password: $password
            stores: $stores
        ) {
            id
        }
    }
`;

export const UPDATE_COURIER = gql`
    mutation updateCourier(
        $updateCourierId: Int!
        $active: Boolean
        $verified: Boolean
        $email: String
        $password: String
        $name: String
        $surname: String
        $iban: String
        $phoneNumber: String
        $siteLanguage: LanguageEnum
        $stores: [Int]
        $icon: String
    ) {
        updateCourier(
            id: $updateCourierId
            active: $active
            verified: $verified
            email: $email
            password: $password
            name: $name
            surname: $surname
            iban: $iban
            phoneNumber: $phoneNumber
            siteLanguage: $siteLanguage
            stores: $stores
            icon: $icon
        ) {
            id
        }
    }
`;

export const CHANGE_COURIER_ACTIVE = gql`
    mutation changeCourierActive(
        $changeCourierActiveId: Int
    ) {
        changeCourierActive(id: $changeCourierActiveId) {
            id
        }
    }
`;
