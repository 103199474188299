import React, { useState, useMemo } from 'react';
import {
    Row,
    Col,
    Label,
    FormGroup,
    Input,
    Table,
    Spinner,
    Pagination,
    PaginationItem,
    PaginationLink
} from 'reactstrap';
import {
    useMutation,
} from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useQuery, useSubscription } from '@apollo/client';
import { GET_LANGUAGES, GET_RECIPES_TO_TRANSLATE, RECIPES_TO_TRANSLATE_SUBSCRIPTION, LANGUAGES_SUBSCRIPTION, ADMIN_PUBLISH_RECIPE } from 'queries';
import { MaterialSymbol } from 'react-material-symbols';

const limit = 10;

export default function RecipeTranslationsTable() {
    const navigate = useNavigate();

    const [adminPublishRecipe] = useMutation(ADMIN_PUBLISH_RECIPE);

    const [showPublished, setShowPublished] = useState(false);
    const [page, setPage] = useState(0);

    const {
        data: languagesData,
        loading: languagesLoading,
        refetch: languagesRefetch,
    } = useQuery(GET_LANGUAGES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(LANGUAGES_SUBSCRIPTION, {
        onData: () => {
            languagesRefetch();
        },
    });

    const {
        data: recipesData,
        loading: recipesLoading,
        refetch: recipesRefetch,
    } = useQuery(GET_RECIPES_TO_TRANSLATE, {
        variables: {
            visible: showPublished,
            offset: page * limit,
            limit,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(RECIPES_TO_TRANSLATE_SUBSCRIPTION, {
        onData: () => {
            recipesRefetch({
                visible: showPublished,
                offset: page * limit,
                limit,
            });
        },
    });

    const pages = useMemo(() => {
        if (!recipesData || !recipesData.recipes) {
            return []
        }
        let result = [];
        for (let index = 0; index < Math.ceil(recipesData.recipes.count / limit); index++) {
            result.push({
                label: index + 1,
                value: index,
            });
        }
        return result;
    }, [recipesData]);

    const publishRecipe = (id) => {
        adminPublishRecipe({
            variables: {
                adminPublishRecipeId: id,
            }
        })
    }

    if (recipesLoading || languagesLoading) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    const languageOptions = languagesData && languagesData.languages ?
        [...languagesData.languages]
            .sort((lang1, lang2) => lang1.languageCode > lang2.languageCode ? 1 : -1)
            .map((lang) => ({
                ...lang,
                value: lang.id,
                label: lang.languageCode
            })) : [];

    const recipes = recipesData && recipesData.recipes && recipesData.recipes.recipes ? [...recipesData.recipes.recipes].sort((r1, r2) => r1.updatedAt > r2.updatedAt ? -1 : 1) : [];

    const getRecipeHasTranslation = (recipe) => {

        let translationRow = {};
        for (let index = 0; index < languageOptions.length; index++) {
            const language = languageOptions[index];
            translationRow[language.languageCode] = false;
        }

        for (let index = 0; index < recipe.recipeTranslations.length; index++) {
            const translation = recipe.recipeTranslations[index];
            translationRow[translation.language.languageCode] = translation.done;
        }

        return <>
            {
                Object.keys(translationRow).map((key) => (
                    <td key={key}>{translationRow[key] ? <MaterialSymbol
                        icon={"verified"}
                        size={24}
                        grade={-25}
                        color={"green"}
                        style={{ outline: "none", border: "none", backgroundColor: "transparent" }}
                    /> : <MaterialSymbol
                        icon={"close"}
                        size={24}
                        grade={-25}
                        color={"grey"}
                        style={{ outline: "none", border: "none", backgroundColor: "transparent" }}
                    />}</td>
                ))
            }
        </>
    }

    return (
        <Row
            style={{
                paddingTop: '1em',
            }}
        >
            <Col>
                <FormGroup check>
                    <Input
                        id="show-published-recipes"
                        type="checkbox"
                        checked={showPublished}
                        onChange={() => {
                            setShowPublished(!showPublished);
                        }}
                    />
                    <Label
                        check
                        htmlFor="show-published-recipes"
                    >
                        Ukázať už zverejnené recepty
                    </Label>
                </FormGroup>

                <Table hover>
                    <thead>
                        <tr>
                            <th width="5%">ID</th>
                            {
                                languageOptions.map((lang) => (
                                    <th key={lang.value} width={`${Math.floor(80 / languageOptions.length)}%`}>{lang.label}</th>
                                ))
                            }
                            <th width={'5%'}>Akcia</th>
                        </tr>
                    </thead>
                    <tbody>
                        {recipes.map((recipe) => (
                            <tr
                                key={recipe.id}
                            >
                                <td>{recipe.id}</td>
                                {getRecipeHasTranslation(recipe)}

                                <td>
                                    <Row xs="2">
                                        <Col>
                                            <MaterialSymbol
                                                icon={'edit'}
                                                size={24}
                                                grade={-25}
                                                color={'orange'}
                                                style={{ outline: "none", border: "none", backgroundColor: "transparent" }}
                                                onClick={() => {
                                                    navigate(
                                                        `/recipe-translations/${recipe.id}`
                                                    )
                                                }}
                                            />
                                        </Col>
                                        <Col>
                                            <MaterialSymbol
                                                icon={'send'}
                                                size={24}
                                                grade={-25}
                                                color={'green'}

                                                style={{ outline: "none", border: "none", backgroundColor: "transparent" }}
                                                onClick={() => {
                                                    if (
                                                        window.confirm(
                                                            'Ste si isty?'
                                                        )
                                                    ) {
                                                        publishRecipe(recipe.id)
                                                    }
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Pagination>
                    <PaginationItem>
                        <PaginationLink
                            first
                            onClick={() => { setPage(0) }}
                        />
                    </PaginationItem>
                    <PaginationItem>
                        <PaginationLink
                            previous
                            onClick={() => { setPage(page - 1) }}
                        />
                    </PaginationItem>

                    {
                        pages.map((p) => (
                            <PaginationItem
                                key={p.value}
                                onClick={() => { setPage(p.value) }}
                                active={page === p.value}
                            >
                                <PaginationLink
                                    tag={'button'}
                                >{p.label}</PaginationLink>
                            </PaginationItem>
                        ))
                    }

                    <PaginationItem>
                        <PaginationLink
                            onClick={() => { setPage(page + 1) }}
                            next
                        />
                    </PaginationItem>
                    <PaginationItem>
                        <PaginationLink
                            last
                            onClick={() => { setPage(Math.ceil(recipesData.recipes.count / limit) - 1) }}
                        />
                    </PaginationItem>
                </Pagination>
            </Col>
        </Row>
    );
}
