import React, { useState } from 'react';

import { useQuery, useSubscription } from '@apollo/client';
import { ADMINS, ADMINS_SUBSCRIPTION } from 'queries';
import { useGetMyData } from 'utils';

import {
    Row,
    Col,
    Table,
    Spinner,
    Modal,
} from 'reactstrap';
import AdminAdd from './add';
import AdminEdit from './edit';
import { MaterialSymbol } from 'react-material-symbols';
import Select from 'react-select';
import { sortBasic, sortName } from 'configs/sortOptions';

export default function AdminTable() {
    const currentAdmin = useGetMyData();

    const {
        data: adminsData,
        loading: adminsLoading,
        refetch: adminsRefetch,
    } = useQuery(ADMINS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(ADMINS_SUBSCRIPTION, {
        onData: () => {
            adminsRefetch();
        },
    });

    const [chosenAdminID, setChosenAdminID] =
        useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [sortOption, setSortOption] = useState(sortBasic);

    const toggle = () => {
        setModalOpen(!modalOpen);
    };

    if (adminsLoading) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    return (
        <Row
            style={{
                paddingTop: '1em',
            }}
        >
            <Col>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={false}
                    isSearchable={false}
                    name="admin-sort"
                    options={[sortBasic, sortName]}
                    value={sortOption}
                    onChange={(option) => {
                        setSortOption(option);
                    }}
                />
                <Table hover>
                    <thead>
                        <tr>
                            <th width="10%">ID</th>
                            <th width="30%">Meno</th>
                            <th width="20%">Email</th>
                            <th
                                width="20%"
                                className="ta-center"
                            >
                                Právo Nastavenia
                            </th>
                            <th
                                width="10%"
                                className="ta-center"
                            >
                                Aktívny
                            </th>
                            <th
                                width="10%"
                                className="ta-center"
                            >
                                Overený
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{ cursor: 'pointer' }}>
                            <th
                                colSpan={6}
                                onClick={() => {
                                    toggle();
                                }}
                            >
                                + Admin
                            </th>
                        </tr>
                        {[
                            ...adminsData.admins.filter(
                                (admin) =>
                                    admin.id !==
                                    currentAdmin.id
                            ),
                        ]
                            .sort((a1, a2) => {
                                if (
                                    sortOption.value ===
                                    'id'
                                ) {
                                    return a1[
                                        sortOption.value
                                    ] < a2[sortOption.value]
                                        ? -1
                                        : 1;
                                }
                                return a1[
                                    sortOption.value
                                ].toLowerCase() <
                                    a2[
                                        sortOption.value
                                    ].toLowerCase()
                                    ? -1
                                    : 1;
                            })
                            .map((admin) => (
                                <tr
                                    key={admin.id}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        setChosenAdminID(
                                            admin.id
                                        );
                                        toggle();
                                    }}
                                >
                                    <th scope="row">
                                        {admin.id}
                                    </th>
                                    <td>
                                        {admin.fullName}
                                    </td>
                                    <td>{admin.email}</td>
                                    <td className="ta-center">
                                        <MaterialSymbol
                                            icon={
                                                admin.settings
                                                    ? 'check_circle'
                                                    : 'cancel'
                                            }
                                            size={24}
                                            grade={-25}
                                            color={
                                                admin.settings
                                                    ? 'green'
                                                    : 'red'
                                            }
                                        />
                                    </td>
                                    <td className="ta-center">
                                        <MaterialSymbol
                                            icon={
                                                admin.active
                                                    ? 'check_circle'
                                                    : 'cancel'
                                            }
                                            size={24}
                                            grade={-25}
                                            color={
                                                admin.active
                                                    ? 'green'
                                                    : 'red'
                                            }
                                        />
                                    </td>
                                    <td className="ta-center">
                                        <MaterialSymbol
                                            icon={
                                                admin.verified
                                                    ? 'check_circle'
                                                    : 'cancel'
                                            }
                                            size={24}
                                            grade={-25}
                                            color={
                                                admin.verified
                                                    ? 'green'
                                                    : 'red'
                                            }
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>

                <Modal isOpen={modalOpen} toggle={toggle}>
                    {chosenAdminID && (
                        <AdminEdit
                            adminId={chosenAdminID}
                            onClose={() => {
                                setChosenAdminID(null);
                                toggle();
                            }}
                            onSave={() => {
                                setChosenAdminID(null);
                                toggle();
                            }}
                        />
                    )}
                    {!chosenAdminID && (
                        <AdminAdd
                            onClose={() => {
                                setChosenAdminID(null);
                                toggle();
                            }}
                            onSave={() => {
                                setChosenAdminID(null);
                                toggle();
                            }}
                        />
                    )}
                </Modal>
            </Col>
        </Row>
    );
}
