import React, { useState } from 'react';
import { Table, Form, FormGroup, Label, Button, Row, Col, Modal, ModalBody, Card } from 'reactstrap';
import ProductCategoryForm from './form';
import { useGetMyData } from 'utils';
import Select from 'react-select';


export default function ProductCategories(props) {
    const {
        productCategories,
        onCreateProductCategory,
        onUpdateProductCategory,
        onRemoveProductCategory,
    } = props;

    const currentAdmin = useGetMyData();

    const [editProductCategory, setEditProductCategory] =
        useState(null);
    const [parentProductCategory, setParentProductCategory] =
        useState(null);        
    const [deleteProductCategory, setDeleteProductCategory] =
    useState(null);
    const [replacementProductCategory, setReplacementProductCategory] =
    useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const toggle = () => {
        setModalOpen(!modalOpen);
    };

    const getTitle = (productCategory) => {
        const currentAdminTranslation =
            productCategory.translations.find(
                (translation) =>
                    translation.language.languageCode ===
                    currentAdmin.siteLanguage
            );
        if (currentAdminTranslation) {
            return currentAdminTranslation.title;
        }
        if (productCategory.translations.length > 0) {
            return productCategory.translations
                .map(
                    (translation) =>
                        `${translation.title} (${translation.language.title})`
                )
                .join(', ');
        }
        return 'Bez názvu';
    };

    const hasMotherLeafInTree = (tree, leaf) => {
        for (let motherIndex = 0; motherIndex < tree.length; motherIndex++) {
            const element = tree[motherIndex];
            if (element.id === leaf.supercategories[0].id){
                return {motherIndex, daugtherIndex: null};
            }
            if (element.leaves && element.leaves.length > 0){
                for (let daugtherIndex = 0; daugtherIndex < element.leaves.length; daugtherIndex++) {
                    const subElement = element.leaves[daugtherIndex];
                    if (subElement.id === leaf.supercategories[0].id){
                        return {motherIndex, daugtherIndex};
                    }
                    
                }
            }
        }
        return {motherIndex: null, daugtherIndex: null};
    }

    const getTree = () => {
        const sortedProductCategories = [...productCategories].sort((p1, p2) => p1.order < p2.order ? -1 : 1)
        let tree = sortedProductCategories.filter((category) => category.topLevelCategory).map((category) => ({...category, leaves: []}));
        let leaves = sortedProductCategories.filter((category) => !category.topLevelCategory).map((category) => ({...category, leaves: []}));
        
        let index = leaves.length - 1;
        let currentLeaf = null;

        let assignHappened = false;
        let oneCycleWithoutAssign = false;

        while (leaves.length > 0 && !oneCycleWithoutAssign){ 

            if (index === -1){
                index = leaves.length - 1;
                oneCycleWithoutAssign = assignHappened ? false : true;
                assignHappened = false;
            }       

            currentLeaf = leaves[index];

            const {motherIndex, daugtherIndex} = hasMotherLeafInTree(tree, currentLeaf);

            if (motherIndex !== null){
                if (daugtherIndex === null){
                    tree[motherIndex].leaves.push(currentLeaf);
                    leaves.splice(index, 1);
                } else {
                    tree[motherIndex].leaves[daugtherIndex].leaves.push(currentLeaf);
                    leaves.splice(index, 1);
                }
                assignHappened = true;
            } 
            index--;
        }
        
        return tree;
    }

    const treeProductCategories = getTree();

    const getReplacementProductCategories = () => {
        let result = [];
        treeProductCategories.forEach((node) => {
            if (node.id !== deleteProductCategory.id){
                result.push({
                    id: node.id,
                    value: node.id,
                    label: getTitle(node)
                });
                if (deleteProductCategory.level > 0){
                    node.leaves.forEach((leaf) => {
                        if (leaf.id !== deleteProductCategory.id){                            
                            result.push({
                                id: leaf.id,
                                value: leaf.id,
                                label: getTitle(leaf)
                            });
                            if (deleteProductCategory.level > 1){

                                leaf.leaves.forEach((subleaf) => {
                                    if (subleaf.id !== deleteProductCategory.id){                            
                                        result.push({
                                            id: subleaf.id,
                                            value: subleaf.id,
                                            label: getTitle(subleaf)
                                        });
                                        
                                    }
                                })
                            }
                        }
                    })
                }
            }
        })
        return result;
    }

    const renderRow = (productCategory, level) => {
        return (
            <tr
                key={productCategory.id}
                style={{
                    cursor: 'pointer',
                }}
            >
                {
                    level > 0 &&
                    <td></td>
                }
                {
                    level > 1 &&
                    <td></td>
                }
                <td colSpan={3-level}>{getTitle(productCategory)}</td>
                <td>
                    <Row xs="2">
                        <Col>
                            <Button
                                color="warning"
                                block
                                onClick={() => {
                                    setEditProductCategory(
                                        productCategory
                                    );                                    
                                    toggle();
                                }}
                            >
                                Upraviť
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                color="danger"
                                block
                                onClick={() => {
                                    setDeleteProductCategory({
                                        ...productCategory,
                                    level,
                                });    
                                    toggle();                                
                                }}
                            >
                                Odstrániť
                            </Button>
                        </Col>
                    </Row>
                </td>
            </tr>
        )
    }

    return (
        <>
            <Table hover>
                <thead>
                    <tr>
                        <th width={'25%'}>Názov</th>
                        <th width={'25%'}></th>
                        <th width={'25%'}></th>
                        <th width={'25%'}>Akcia</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key="add-productCategory-btn">
                        <td
                            colSpan={4}
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setParentProductCategory(null);
                                setEditProductCategory(null);
                                setDeleteProductCategory(null);
                                setReplacementProductCategory(null);
                                toggle()
                            }}
                        >
                            + Kategória produktu
                        </td>
                    </tr>
                    {treeProductCategories.map((productCategory) => (
                        <>
                            <tr>
                                <td colSpan={4}></td>
                            </tr>
                            {renderRow(productCategory, 0)}
                            <tr>
                                <td
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        setParentProductCategory(productCategory.id);
                                        toggle();
                                    }}
                                >
                                </td>
                                <td
                                    colSpan={3}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        setParentProductCategory(productCategory.id);
                                        toggle();
                                    }}
                                >
                                    + Podkategória produktu
                                </td>
                            </tr>
                            {
                                [...productCategory.leaves].sort((n1, n2) => n1.order < n2.order ? -1 : 1).map((daugtherProductCategory) => (
                                    <>
                                        {renderRow(daugtherProductCategory, 1)}
                                        <tr>
                                            <td
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    setParentProductCategory(daugtherProductCategory.id);
                                                    toggle();
                                                }}
                                            >                                                    
                                            </td>
                                            <td
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    setParentProductCategory(daugtherProductCategory.id);
                                                    toggle();
                                                }}
                                            >                                                    
                                            </td>
                                            <td
                                                colSpan={2}
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    setParentProductCategory(daugtherProductCategory.id);
                                                    toggle();
                                                }}
                                            >
                                            + Podpodkategória produktu
                                            </td>
                                        </tr>                                        
                                        {
                                            [...daugtherProductCategory.leaves].sort((n1, n2) => n1.order < n2.order ? -1 : 1).map((grandaughterProductCategory) => 
                                                renderRow(grandaughterProductCategory, 2)
                                            )
                                        }
                                    </>
                                ))
                            }
                        </>
                    ))}
                </tbody>
            </Table>
            <Modal
                isOpen={modalOpen}
                toggle={() => {
                    if (modalOpen) {
                        setEditProductCategory(null);
                        setDeleteProductCategory(null);
                        setReplacementProductCategory(null);
                    }
                    toggle();
                }}
            >
                {editProductCategory &&
                !deleteProductCategory && (
                    <ProductCategoryForm
                        onClose={() => {
                            setEditProductCategory(null);
                            toggle();
                        }}
                        onSave={(data) => {
                            console.log(data);
                            
                            onUpdateProductCategory(data);
                            toggle();
                        }}
                        productCategory={
                            editProductCategory
                        }
                    />
                )}
                {!editProductCategory &&
                !deleteProductCategory && (
                    <ProductCategoryForm
                        onClose={() => {
                            toggle();
                        }}
                        onSave={(data) => {
                            console.log(data);
                            
                            onCreateProductCategory(data);
                            toggle();
                        }}
                        productCategoryId={null}
                        parentProductCategory={parentProductCategory}
                    />
                )}
                {
                    deleteProductCategory &&
                    <Card style={{ padding: '1em' }}>
                        <Form>
                    <FormGroup>
                        <Label htmlFor="trending-group-title">
                            Vyberte náhradnú kategóriu
                        </Label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isSearchable={
                                true
                            }
                            name="replacement_product_category"
                            options={
                                getReplacementProductCategories()
                            }
                            value={
                                replacementProductCategory
                            }
                            onChange={(
                                e
                            ) => {
                                setReplacementProductCategory(e)
                            }}
                        />
                        </FormGroup>
                        </Form>

                        <Row xs="2">
                            <Col>
                                <Button
                                    color="secondary"
                                    block
                                    onClick={() => {
                                        setDeleteProductCategory(null);
                                        setReplacementProductCategory(null);
                                        toggle();
                                    }}
                                >
                                    Zrušiť
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    color="primary"
                                    block
                                    disabled={!replacementProductCategory}
                                    onClick={() => {
                                        onRemoveProductCategory(
                                            deleteProductCategory.id,
                                            replacementProductCategory.id
                                        );
                                        setDeleteProductCategory(null);
                                        setReplacementProductCategory(null);
                                        toggle();
                                    }}
                                >
                                    Uložiť
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                }
            </Modal>
        </>
    );
}
