import React, { useState, useEffect } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import {
    LOGIN_ADMIN,
    RESEND_ADMIN_VERIFICATION_EMAIL,
} from 'queries';
import { GET_TESTED_TOKEN } from 'apollo/queries';
import refreshToken from 'apollo/refreshToken';
import {
    setIsLoggedIn,
    setTestedToken,
} from 'apollo/localSchema/actions';

import { MaterialSymbol } from 'react-material-symbols';

import {
    FormGroup,
    Label,
    Input,
    Button,
    Card,
    CardHeader,
    CardBody,
    InputGroup,
    Row,
    Col,
    Container,
    Spinner,
} from 'reactstrap';

export default function Login() {
    const isEmail = (email) =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            email
        );

    const {
        data: testedTokenData,
        loading: testedTokenLoading,
    } = useQuery(GET_TESTED_TOKEN);

    const [loginAdmin] = useMutation(LOGIN_ADMIN);
    const [resendAdminEmailVerification] = useMutation(
        RESEND_ADMIN_VERIFICATION_EMAIL
    );

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVisible, setPasswordVisible] =
        useState(false);
    const [signingIn, setSigningIn] = useState(false);
    const [error, setError] = useState(null);

    const [showVerificationCode, setShowVerificationCode] =
        useState(false);
    const [confirmationCode, setConfirmationCode] =
        useState('');
    const [
        resentConfirmationCode,
        setResentConfirmationCode,
    ] = useState(false);

    const login = () => {
        setResentConfirmationCode(false);
        setSigningIn(true);
        let data = {
            email,
            password,
        };
        if (confirmationCode.length > 0) {
            data = {
                ...data,
                confirmationCode,
            };
        }
        loginAdmin({
            variables: data,
        })
            .then((response) => {
                setSigningIn(false);
                sessionStorage.setItem(
                    'acctok',
                    response.data.loginAdmin.accessToken
                );
                setIsLoggedIn(true);
            })
            .catch((err) => {
                setSigningIn(false);
                setError(err.message);
            });

        setSigningIn(false);
        setError(null);
    };

    useEffect(() => {
        if (!testedTokenData.testedToken) {
            setTestedToken(true);
            tryLogin();
        }
    }, [testedTokenLoading, testedTokenData.testedToken]);

    const tryLogin = () => {
        setSigningIn(true);
        refreshToken()
            .then((response) => {
                const { ok, accessToken } = response.data;
                if (ok) {
                    sessionStorage.setItem(
                        'acctok',
                        accessToken
                    );
                    setIsLoggedIn(true);
                    setSigningIn(false);
                } else {
                    sessionStorage.removeItem('acctok');
                    setIsLoggedIn(false);
                    setSigningIn(false);
                }
            })
            .catch(() => {
                setIsLoggedIn(false);
                setSigningIn(false);
            });
    };

    return (
        <Container
            onKeyDown={(e) => {
                if (
                    !signingIn &&
                    email.length > 0 &&
                    password.length > 0 &&
                    e.key === 'Enter'
                ) {
                    login();
                }
            }}
        >
            <Row>
                <Col
                    className=""
                    md={{
                        offset: 3,
                        size: 6,
                    }}
                    sm="12"
                >
                    <Card className="my-2">
                        <CardHeader>Prihlasenie</CardHeader>
                        <CardBody>
                            <FormGroup>
                                <Label for="exampleEmail">
                                    Email
                                </Label>
                                <Input
                                    invalid={error}
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(
                                            e.target.value
                                        );
                                    }}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="exampleEmail">
                                    Heslo
                                </Label>
                                <InputGroup>
                                    <Input
                                        invalid={
                                            error !== null
                                        }
                                        value={password}
                                        type={
                                            passwordVisible
                                                ? 'text'
                                                : 'password'
                                        }
                                        onChange={(e) => {
                                            setPassword(
                                                e.target
                                                    .value
                                            );
                                        }}
                                    />
                                    <Button
                                        color="primary"
                                        className="flex"
                                        onClick={() => {
                                            setPasswordVisible(
                                                !passwordVisible
                                            );
                                        }}
                                    >
                                        <MaterialSymbol
                                            icon={
                                                passwordVisible
                                                    ? 'visibility_off'
                                                    : 'visibility'
                                            }
                                            size={24}
                                            fill
                                            grade={-25}
                                            color="white"
                                        />
                                    </Button>
                                </InputGroup>
                            </FormGroup>

                            <Button
                                color="link"
                                block
                                onClick={() => {
                                    setShowVerificationCode(
                                        true
                                    );
                                }}
                            >
                                Toto je moje prve
                                prihlasenie
                            </Button>
                            {showVerificationCode && (
                                <p>
                                    Na Váš email sme zaslali
                                    overovací kód! Ak Vám
                                    nepríde do pár minút,
                                    kliknite nižšie pre
                                    preposlanie.
                                </p>
                            )}

                            {showVerificationCode && (
                                <FormGroup>
                                    <Label for="confirmationCode">
                                        Overovací kód
                                    </Label>
                                    <Input
                                        id="confirmationCode"
                                        invalid={error}
                                        value={
                                            confirmationCode
                                        }
                                        onChange={(e) => {
                                            setConfirmationCode(
                                                e.target
                                                    .value
                                            );
                                        }}
                                    />
                                </FormGroup>
                            )}
                            {showVerificationCode && (
                                <Button
                                    color="link"
                                    block
                                    onClick={() => {
                                        if (
                                            !isEmail(email)
                                        ) {
                                            setError(
                                                'Toto nie je validný email!'
                                            );
                                        } else {
                                            setResentConfirmationCode(
                                                true
                                            );
                                            resendAdminEmailVerification(
                                                {
                                                    variables:
                                                        {
                                                            email,
                                                        },
                                                }
                                            );
                                        }
                                    }}
                                >
                                    Preposlat Overovací kód
                                </Button>
                            )}
                            {resentConfirmationCode && (
                                <p>
                                    Overovací kód
                                    preposlaný!
                                </p>
                            )}

                            {error && <p>{error}</p>}
                            {signingIn && (
                                <Spinner color="primary"></Spinner>
                            )}
                            <Button
                                color="primary"
                                outline
                                block
                                disabled={signingIn}
                                onClick={() => {
                                    login();
                                }}
                            >
                                Prihlásiť
                            </Button>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}
