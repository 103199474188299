import React from 'react';
import ReactDOM from 'react-dom/client';

import { ApolloProvider, useQuery } from '@apollo/client';
import createClient from 'apollo/createClient';
import { GET_IS_LOGGED_IN } from 'apollo/queries';


import Navigation from 'navigation';
import Login from 'login';

import 'react-material-symbols/dist/rounded.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/index.css';

const root = ReactDOM.createRoot(
    document.getElementById('root')
);

const client = createClient();
const IsLoggedIn = () => {
    const { data } = useQuery(GET_IS_LOGGED_IN);

    return data.isLoggedIn ? <Navigation /> : <Login />;
};

root.render(
    <ApolloProvider client={client}>
        <IsLoggedIn />
    </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
