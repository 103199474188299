import React from 'react';
import {
    useMutation,
    useQuery,
    useSubscription,
} from '@apollo/client';
import NewIngredients from './list';
import { Col, Row, Spinner } from 'reactstrap';
import {
    NEW_INGREDIENTS,
    NEW_INGREDIENTS_SUBSCRIPTION,
    ADD_NEW_INGREDIENT,
    UPDATE_NEW_INGREDIENT,
    DELETE_NEW_INGREDIENT,
} from 'queries';

export default function NewIngredientsContainer() {
    const [addNewIngredient] = useMutation(ADD_NEW_INGREDIENT);
    const [updateNewIngredient] = useMutation(
        UPDATE_NEW_INGREDIENT
    );
    const [deleteNewIngredient] = useMutation(
        DELETE_NEW_INGREDIENT
    );

    const {
        data: newIngredientsData,
        loading: newIngredientsLoading,
        refetch: newIngredientsRefetch,
    } = useQuery(NEW_INGREDIENTS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(NEW_INGREDIENTS_SUBSCRIPTION, {
        onData: () => {
            newIngredientsRefetch();
        },
    });

    const onCreateNewIngredient = (data) => {
        addNewIngredient({
            variables: {
            },
        });
    };

    const onUpdateNewIngredient = (data) => {
        updateNewIngredient({
            variables: {
            },
        });
    };
    const onRemoveNewIngredient = (id) => {
        deleteNewIngredient({
            variables: {
                id,
            },
        });
    };

    if (newIngredientsLoading) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    return (
        <Col>
            <NewIngredients
                newIngredients={
                    newIngredientsData &&
                        newIngredientsData.newIngredients
                        ? newIngredientsData.newIngredients
                        : []
                }
                onCreateNewIngredient={onCreateNewIngredient}
                onUpdateNewIngredient={onUpdateNewIngredient}
                onRemoveNewIngredient={onRemoveNewIngredient}
            />
        </Col>
    );
}
