import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
    FormGroup,
    InputGroup,
    Label,
    Input,
    Form,
    Button,
    Card,
    Row,
    Col,
    Modal,
} from 'reactstrap';
import { ADMIN_CHANGE_PASSWORD } from 'queries';
import { MaterialSymbol } from 'react-material-symbols';

export default function AdminChangePassword() {
    const [adminChangePassword] = useMutation(
        ADMIN_CHANGE_PASSWORD
    );

    const [newPassword, setNewPassword] = useState('');
    const [newPasswordVisible, setNewPasswordVisible] =
        useState(false);

    const [newPassword2, setNewPassword2] = useState('');
    const [newPassword2Visible, setNewPassword2Visible] =
        useState(false);

    const [password, setPassword] = useState('');
    const [passwordVisible, setPasswordVisible] =
        useState(false);

    const [passwordErrors, setPasswordErrors] = useState(
        []
    );

    const [modalOpen, setModalOpen] = useState(false);
    const [saveError, setSaveError] = useState(null);
    const [passChanged, setPassChanged] = useState(false);

    const toggle = () => {
        setModalOpen(!modalOpen);
    };

    const checkPasswords = (calledFromFirstPassword) => {
        if (
            newPassword.length + newPassword2.length ===
            0
        ) {
            return;
        }
        let newErrors = [];
        if (
            (!calledFromFirstPassword &&
                newPassword !== newPassword2) ||
            (newPassword !== newPassword2 &&
                newPassword2.length > 0)
        ) {
            newErrors.push('Heslá nie sú rovnaké!');
        }
        if (newPassword.length < 6) {
            newErrors.push(
                'Heslo musí mať aspoň 6 znakov!'
            );
        }
        setPasswordErrors(newErrors);
    };

    const adminChangePasswordFunc = () => {
        adminChangePassword({
            variables: {
                newPass: newPassword,
                password,
            },
        })
            .then((response) => {
                setPassChanged(true);
                setSaveError(null);
            })
            .catch((error) => {
                setSaveError(error.message);
            });
    };

    return (
        <>
            <Button
                color="link"
                onClick={() => {
                    setModalOpen(true);
                }}
            >
                Zmeniť heslo
            </Button>
            <Modal isOpen={modalOpen} toggle={toggle}>
                <Card style={{ padding: '1em' }}>
                    <Row>
                        <Form>
                            <FormGroup>
                                <Label htmlFor="admin-new-pass">
                                    Nové heslo
                                </Label>
                                <InputGroup>
                                    <Input
                                        id="admin-new-pass"
                                        invalid={
                                            passwordErrors.length >
                                            0
                                        }
                                        value={newPassword}
                                        type={
                                            newPasswordVisible
                                                ? 'text'
                                                : 'password'
                                        }
                                        onChange={(e) => {
                                            setNewPassword(
                                                e.target
                                                    .value
                                            );
                                            setSaveError(
                                                null
                                            );

                                            setPassChanged(
                                                false
                                            );
                                        }}
                                        onBlur={() =>
                                            checkPasswords(
                                                true
                                            )
                                        }
                                    />
                                    <Button
                                        onClick={() => {
                                            setNewPasswordVisible(
                                                !newPasswordVisible
                                            );
                                        }}
                                    >
                                        <MaterialSymbol
                                            icon={
                                                newPasswordVisible
                                                    ? 'visibility_off'
                                                    : 'visibility'
                                            }
                                            size={24}
                                            fill
                                            grade={-25}
                                            color="white"
                                        />
                                    </Button>
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="admin-new-pass-2">
                                    Zopakovať nové heslo
                                </Label>
                                <InputGroup>
                                    <Input
                                        id="admin-new-pass-2"
                                        value={newPassword2}
                                        invalid={
                                            passwordErrors.length >
                                            0
                                        }
                                        type={
                                            newPassword2Visible
                                                ? 'text'
                                                : 'password'
                                        }
                                        onChange={(e) => {
                                            setNewPassword2(
                                                e.target
                                                    .value
                                            );
                                            setSaveError(
                                                null
                                            );
                                            setPassChanged(
                                                false
                                            );
                                        }}
                                        onBlur={() =>
                                            checkPasswords()
                                        }
                                    />
                                    <Button
                                        onClick={() => {
                                            setNewPassword2Visible(
                                                !newPassword2Visible
                                            );
                                        }}
                                    >
                                        <MaterialSymbol
                                            icon={
                                                newPassword2Visible
                                                    ? 'visibility_off'
                                                    : 'visibility'
                                            }
                                            size={24}
                                            fill
                                            grade={-25}
                                            color="white"
                                        />
                                    </Button>
                                </InputGroup>
                            </FormGroup>

                            {passwordErrors.map(
                                (error, index) => (
                                    <p key={index}>
                                        {error}
                                    </p>
                                )
                            )}

                            <FormGroup>
                                <Label htmlFor="admin-pass">
                                    Staré heslo
                                </Label>
                                <InputGroup>
                                    <Input
                                        id="admin-pass"
                                        value={password}
                                        type={
                                            passwordVisible
                                                ? 'text'
                                                : 'password'
                                        }
                                        onChange={(e) => {
                                            setPassword(
                                                e.target
                                                    .value
                                            );
                                            setSaveError(
                                                null
                                            );
                                        }}
                                    />
                                    <Button
                                        onClick={() => {
                                            setPasswordVisible(
                                                !passwordVisible
                                            );
                                        }}
                                    >
                                        <MaterialSymbol
                                            icon={
                                                passwordVisible
                                                    ? 'visibility_off'
                                                    : 'visibility'
                                            }
                                            size={24}
                                            fill
                                            grade={-25}
                                            color="white"
                                        />
                                    </Button>
                                </InputGroup>
                            </FormGroup>
                        </Form>
                    </Row>
                    {saveError && (
                        <Row>
                            <Col>
                                <p>{saveError}</p>
                            </Col>
                        </Row>
                    )}

                    {passChanged && (
                        <Row>
                            <Col>
                                <p>
                                    Heslo bolo úspešne
                                    zmenené!
                                </p>
                            </Col>
                        </Row>
                    )}

                    <Row xs="2">
                        <Col>
                            <Button
                                color="secondary"
                                block
                                onClick={() => {
                                    setPassChanged(false);
                                    setSaveError(null);
                                    setPasswordVisible(
                                        false
                                    );
                                    setNewPasswordVisible(
                                        false
                                    );
                                    setNewPassword2Visible(
                                        false
                                    );
                                    setPasswordErrors([]);
                                    setModalOpen(false);
                                }}
                            >
                                Zavrieť
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                color="primary"
                                block
                                onClick={() => {
                                    adminChangePasswordFunc();
                                }}
                            >
                                Uložiť
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </Modal>
        </>
    );
}
