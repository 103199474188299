import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';

export default function StatisticsSidebar() {
    return (
        <Nav fill pills vertical>
            <NavItem>
                <NavLink tag={Link} to={`/system/orders`}>
                    Objednávky
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    tag={Link}
                    to={`/system/notifications`}
                >
                    Systémové správy
                </NavLink>
            </NavItem>
        </Nav>
    );
}
