import { gql } from '@apollo/client';

export const ADDRESSES_SUBSCRIPTION = gql`
    subscription addressesSubscription {
        addressesSubscription
    }
`;

export const ADDRESSES = gql`
    query addresses($userId: Int!) {
        addresses(userId: $userId) {
            id
            current
            title
            address
            longitude
            latitude
        }
    }
`;

export const ADD_ADDRESS = gql`
    mutation addAddress(
        $current: Boolean!
        $title: String!
        $address: String!
        $longitude: Float!
        $latitude: Float!
        $userId: Int
    ) {
        addAddress(
            current: $current
            title: $title
            address: $address
            longitude: $longitude
            latitude: $latitude
            userId: $userId
        ) {
            id
        }
    }
`;

export const UPDATE_ADDRESS = gql`
    mutation updateAddress(
        $id: Int!
        $current: Boolean
        $title: String
        $address: String
        $longitude: Float
        $latitude: Float
    ) {
        updateAddress(
            id: $id
            current: $current
            title: $title
            address: $address
            longitude: $longitude
            latitude: $latitude
        ) {
            id
        }
    }
`;

export const DELETE_ADDRESS = gql`
    mutation deleteAddress($id: Int!) {
        deleteAddress(id: $id) {
            id
        }
    }
`;
