import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import {
    NavLink as Link,
    useParams,
} from 'react-router-dom';

export default function RecipeTranslationsSidebar() {
    const { recipeID } = useParams();

    return (
        <Nav fill pills vertical>
            <NavItem>
                <NavLink tag={Link} to={`/recipe-translations`} end>
                    ← Naspäť na zoznam
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    tag={Link}
                    to={`/recipe-translations/${recipeID}`}
                >
                    Detail receptu
                </NavLink>
            </NavItem>
        </Nav>
    );
}
