import React, { useState } from 'react';
import { Row, Col, Table, Spinner } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useQuery, useSubscription } from '@apollo/client';
import { CHEFS, CHEFS_SUBSCRIPTION } from 'queries';
import { MaterialSymbol } from 'react-material-symbols';
import Select from 'react-select';
import { sortBasic, sortName } from 'configs/sortOptions';

export default function ChefsTable() {
    const navigate = useNavigate();

    const [sortOption, setSortOption] = useState(sortBasic);

    const {
        data: chefsData,
        loading: chefsLoading,
        refetch: chefsRefetch,
    } = useQuery(CHEFS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(CHEFS_SUBSCRIPTION, {
        onData: () => {
            chefsRefetch();
        },
    });

    if (chefsLoading) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    return (
        <Row
            style={{
                paddingTop: '1em',
            }}
        >
            <Col>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={false}
                    isSearchable={false}
                    name="chef-sort"
                    options={[sortBasic, sortName]}
                    value={sortOption}
                    onChange={(option) => {
                        setSortOption(option);
                    }}
                />
                <Table hover>
                    <thead>
                        <tr>
                            <th width="10%">ID</th>
                            <th width="30%">Meno</th>
                            <th width="40%">Email</th>
                            <th
                                width="10%"
                                className="ta-center"
                            >
                                Aktívny
                            </th>
                            <th
                                width="10%"
                                className="ta-center"
                            >
                                Overený
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {[...chefsData.chefs]
                            .sort((c1, c2) => {
                                if (
                                    sortOption.value ===
                                    'id'
                                ) {
                                    return c1[
                                        sortOption.value
                                    ] < c2[sortOption.value]
                                        ? -1
                                        : 1;
                                }
                                return c1[
                                    sortOption.value
                                ].toLowerCase() <
                                    c2[
                                        sortOption.value
                                    ].toLowerCase()
                                    ? -1
                                    : 1;
                            })
                            .map((chef) => (
                                <tr
                                    key={chef.id}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                        navigate(
                                            `/chefs/${chef.id}/profile`
                                        )
                                    }
                                >
                                    <th scope="row">
                                        {chef.id}
                                    </th>
                                    <td>{chef.fullName}</td>
                                    <td>{chef.email}</td>
                                    <td className="ta-center">
                                        <MaterialSymbol
                                            icon={
                                                chef.active
                                                    ? 'check_circle'
                                                    : 'cancel'
                                            }
                                            size={24}
                                            grade={-25}
                                            color={
                                                chef.active
                                                    ? 'green'
                                                    : 'red'
                                            }
                                        />
                                    </td>
                                    <td className="ta-center">
                                        <MaterialSymbol
                                            icon={
                                                chef.verified
                                                    ? 'check_circle'
                                                    : 'cancel'
                                            }
                                            size={24}
                                            grade={-25}
                                            color={
                                                chef.verified
                                                    ? 'green'
                                                    : 'red'
                                            }
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
}
