import React, { useState, useEffect } from 'react';
import {
    FormGroup,
    InputGroup,
    Label,
    Input,
    Form,
    Button,
    Card,
    Row,
    Col,
} from 'reactstrap';
import Select from 'react-select';
import wellKnownOptions from 'configs/wellKnown';

export default function SMTPForm(props) {
    const { smtp, onSave, onClose } = props;

    const [title, setTitle] = useState('');
    const [host, setHost] = useState('');
    const [port, setPort] = useState(465);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVisible, setPasswordVisible] =
        useState(false);
    const [rejectUnauthorized, setRejectUnauthorized] =
        useState(false);
    const [secure, setSecure] = useState(true);

    const [saving, setSaving] = useState(false);
    const [wellKnown, setWellKnown] = useState(
        wellKnownOptions[0]
    );

    useEffect(() => {
        if (smtp) {
            setTitle(smtp.title);
            setHost(smtp.host);
            setPort(smtp.port);
            setUsername(smtp.username);
            setPassword(smtp.password);
            setRejectUnauthorized(smtp.rejectUnauthorized);
            setSecure(smtp.secure);
            setWellKnown(
                wellKnownOptions.find(
                    (option) =>
                        option.value === smtp.wellKnown
                )
            );
        }
    }, [smtp]);

    const wellKnownBlock = wellKnown.id !== null;

    const cannotSave = () =>
        (wellKnownBlock &&
            (saving ||
                password === '' ||
                username === '')) ||
        (!wellKnownBlock &&
            (saving ||
                title === '' ||
                host === '' ||
                port === '' ||
                password === '' ||
                username === ''));

    return (
        <Card style={{ padding: '1em' }}>
            <Row>
                <Form>
                    <FormGroup>
                        <Label htmlFor="smtp-title">
                            Názov{' '}
                            {!wellKnownBlock && (
                                <span>*</span>
                            )}
                        </Label>
                        <Input
                            id="smtp-title"
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="smtp-well-known">
                            Well Known
                        </Label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={false}
                            isSearchable={true}
                            name="smtp-well-known"
                            options={wellKnownOptions}
                            value={wellKnown}
                            onChange={(option) => {
                                setWellKnown(option);
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="smtp-host">
                            Hostiteľ{' '}
                            {!wellKnownBlock && (
                                <span>*</span>
                            )}
                        </Label>
                        <Input
                            id="smtp-host"
                            value={host}
                            onChange={(e) => {
                                setHost(e.target.value);
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="smtp-port">
                            Port{' '}
                            {!wellKnownBlock && (
                                <span>*</span>
                            )}
                        </Label>
                        <Input
                            id="smtp-port"
                            value={port}
                            onChange={(e) => {
                                setPort(e.target.value);
                            }}
                        />
                    </FormGroup>

                    <FormGroup check>
                        <Input
                            id="smtp-secure"
                            type="checkbox"
                            checked={secure}
                            onChange={() => {
                                setSecure(!secure);
                            }}
                        />
                        <Label check htmlFor="smtp-secure">
                            Zabezpečený
                        </Label>
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="smtp-username">
                            Používateľské meno{' '}
                            <span>*</span>
                        </Label>
                        <Input
                            id="smtp-username"
                            value={username}
                            onChange={(e) => {
                                setUsername(e.target.value);
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="smtp-pass">
                            Heslo <span>*</span>
                        </Label>
                        <InputGroup>
                            <Input
                                id="smtp-pass"
                                invalid={
                                    password.length < 6
                                }
                                value={password}
                                type={
                                    passwordVisible
                                        ? 'text'
                                        : 'password'
                                }
                                onChange={(e) => {
                                    setPassword(
                                        e.target.value
                                    );
                                }}
                            />
                            <Button
                                onClick={() => {
                                    setPasswordVisible(
                                        !passwordVisible
                                    );
                                }}
                            >
                                {passwordVisible
                                    ? 'Schovat'
                                    : 'Ukazat'}
                            </Button>
                        </InputGroup>
                    </FormGroup>

                    <FormGroup check>
                        <Input
                            id="smtp-rejectUnauthorized"
                            type="checkbox"
                            checked={rejectUnauthorized}
                            onChange={() => {
                                setRejectUnauthorized(
                                    !rejectUnauthorized
                                );
                            }}
                        />
                        <Label
                            check
                            htmlFor="smtp-rejectUnauthorized"
                        >
                            Odmietnuť neautorizované
                        </Label>
                    </FormGroup>

                    {cannotSave() && (
                        <p>
                            {
                                'Pre ulozenie najprv vyplnte vsetky povinne udaje!'
                            }
                        </p>
                    )}
                </Form>
            </Row>

            <Row xs="2">
                <Col>
                    <Button block onClick={onClose}>
                        Zrušiť
                    </Button>
                </Col>
                <Col>
                    <Button
                        block
                        disabled={cannotSave()}
                        onClick={() => {
                            setSaving(true);
                            onSave({
                                title,
                                order: 1,
                                host,
                                port:
                                    port !== ''
                                        ? parseInt(port)
                                        : 465,
                                username,
                                password,
                                rejectUnauthorized,
                                secure,
                                wellKnown: wellKnown.value,
                            });
                            setSaving(false);
                        }}
                    >
                        Uložiť
                    </Button>
                </Col>
            </Row>
        </Card>
    );
}
