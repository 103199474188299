import React, { useState, useEffect } from 'react';
import {
    useMutation,
    useQuery,
} from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
    Button,
    Col,
    Row,
    Spinner,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Form,
    FormGroup,
    Input,
} from 'reactstrap';

import {
    GET_POTENTIAL_CHEF,
    ACCEPT_POTENTIAL_CHEFS,
    DECLINE_POTENTIAL_CHEFS,
} from 'queries';
import { timestampToStringFNS } from 'utils';

const modalContent = Object.freeze({
    accept: "accept",
    decline: "decline",
})

export default function PotentialChefProfile() {
    const { chefID } = useParams();
    const navigate = useNavigate();

    const [acceptPotentialChef] = useMutation(ACCEPT_POTENTIAL_CHEFS);
    const [declinePotentialChef] = useMutation(DECLINE_POTENTIAL_CHEFS);

    const {
        data: chefData,
        loading: chefLoading,
        refetch: chefRefetch,
    } = useQuery(GET_POTENTIAL_CHEF, {
        variables: {
            id: parseInt(chefID),
        },
        fetchPolicy: 'network-only',
    });

    const [saving, setSaving] = useState(false);
    const [declineReason, setDeclineReason] = useState("");
    const [confirmModalContent, setConfirmModalContent] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        setError(null);
        chefRefetch({
            chefId: parseInt(chefID),
        });
    }, [chefID]);

    const acceptChef = () => {
        acceptPotentialChef({
            variables: {
                id: parseInt(chefID),
            },
        }).then(() => {
            setSaving(false);
            navigate(
                `/potential-chefs`
            );
        }).catch((error) => {
            setSaving(false);
            setError(error.message);
        });
    };

    const declineChef = () => {
        declinePotentialChef({
            variables: {
                id: parseInt(chefID),
                reason: declineReason
            },
        }).then(() => {
            setSaving(false);
            navigate(
                `/potential-chefs`
            );
        }).catch((error) => {
            setSaving(false);
            setError(error.message);
        });
    };

    if (chefLoading || saving) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    return (
        <Col>

            <Row xs="2">
                <Col>Žiadosť vytvorená dňa</Col>
                <Col>{timestampToStringFNS(chefData.potentialChef?.createdAt)}</Col>
            </Row>

            <Row xs="2">
                <Col>Nickname</Col>
                <Col>{chefData.potentialChef?.nickname}</Col>
            </Row>

            <Row xs="2">
                <Col>Full name</Col>
                <Col>{chefData.potentialChef?.fullName}</Col>
            </Row>

            <Row xs="2">
                <Col>E-mail</Col>
                <Col>{chefData.potentialChef?.email}</Col>
            </Row>

            <Row xs="2">
                <Col>Phone number</Col>
                <Col>{chefData.potentialChef?.phoneNumber}</Col>
            </Row>

            <Row xs="2">
                <Col>Instagram</Col>
                <Col><a href={chefData.potentialChef?.instagram} target="_blank">{chefData.potentialChef?.instagram}</a></Col>
            </Row>

            <Row xs="2">
                <Col>YouTube</Col>
                <Col><a href={chefData.potentialChef?.youtube} target="_blank">{chefData.potentialChef?.youtube}</a></Col>
            </Row>

            <Row xs="2">
                <Col>Jazyk</Col>
                <Col>{chefData.potentialChef?.language.title}</Col>
            </Row>

            {
                error &&
                <Row>
                    <Col>error</Col>
                </Row>
            }

            <Row xs="2">
                <Col>
                    <Button
                        color="danger"
                        block
                        onClick={() => {
                            setConfirmModalContent(modalContent.decline);
                        }}
                    >
                        Odmietnuť
                    </Button>
                </Col>
                <Col className="flex jc-flex-end">
                    <Button
                        color="success"
                        block
                        onClick={() => {
                            setConfirmModalContent(modalContent.accept);
                        }}
                    >
                        Prijať
                    </Button>
                </Col>
            </Row>

            <Modal isOpen={confirmModalContent !== null} toggle={() => { setConfirmModalContent(null) }}>
                <ModalHeader>
                    {
                        `${confirmModalContent === modalContent.accept ? "Prijať" : "Odmietnuť"} žiadosť?`
                    }
                </ModalHeader>
                <ModalBody>
                    <Form>

                        {
                            confirmModalContent === modalContent.decline &&
                            <FormGroup>
                                <Label htmlFor="decline-reason">
                                    Dôvod?
                                </Label>
                                <Input
                                    id="decline-reason"
                                    value={declineReason}
                                    onChange={(e) => {
                                        setDeclineReason(e.target.value);
                                    }}
                                />
                            </FormGroup>

                        }
                        <Row xs="2">
                            <Col>
                                <Button
                                    color="secondary"
                                    block
                                    onClick={() => {
                                        setConfirmModalContent(null)
                                    }}
                                >
                                    Zavrieť
                                </Button>
                            </Col>
                            <Col className="flex jc-flex-end">
                                <Button
                                    color={confirmModalContent === modalContent.accept ? "success" : "danger"}
                                    block
                                    onClick={() => {
                                        setSaving(true);
                                        if (confirmModalContent === modalContent.accept) {
                                            acceptChef();
                                        } else {
                                            declineChef();
                                        }
                                    }}
                                >
                                    {confirmModalContent === modalContent.accept ? "Prijať" : "Odmietnuť"}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>

            </Modal>
        </Col>
    );
}
