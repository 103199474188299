import React from 'react';

import { Image } from './image';

export default function ImageLoader(props) {
    const {
        path,
        imageSource,
        size, //Enum - "icon" or "image"
        label,
    } = props;

    return (
        <Image
            {...props}
            image={path ? path : imageSource}
            marginRight={label ? true : false}
            size={size}
        />
    );
}
