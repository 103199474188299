import React, { useEffect, useState } from 'react';
import {
    FormGroup,
    Label,
    Input,
    Form,
    Button,
    Card,
    Row,
    Col,
    Spinner,
    Table,
} from 'reactstrap';
import Select from 'react-select';
import { useQuery } from '@apollo/client';
import { GET_LANGUAGES } from 'queries';

const UNITS = [
    {
        label: 'Piece',
        value: 'piece',
    },
    {
        label: 'Liter',
        value: 'liter',
    },
    {
        label: 'Kilo',
        value: 'kilo',
    },
    {
        label: 'Teaspoon',
        value: 'teaspoon',
    },
    {
        label: 'Pinch',
        value: 'pinch',
    },
    {
        label: 'Tablespoon',
        value: 'tablespoon',
    },
];

let fakeID = -1;

export default function IngredientForm(props) {
    const {
        onClose,
        onSave,
        ingredientId,
        newIngredientData,
        newIngredientLoading,
    } = props;

    const [units, setUnits] = useState([]);
    const [conversions, setConversions] = useState([]);
    const [translations, setTranslations] = useState([]);

    const [newTranslation, setNewTranslation] = useState({
        title: '',
        language: null,
    });

    const {
        data: languagesData,
        loading: languagesLoading,
    } = useQuery(GET_LANGUAGES, {
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (newIngredientData && newIngredientData.ingredient) {
            setUnits(
                newIngredientData.ingredient.units.map(
                    (unit) =>
                        UNITS.find((u) => u.value === unit)
                )
            );
            setConversions(
                newIngredientData.ingredient.conversions
            );
            setTranslations(
                newIngredientData.ingredient.translations
            );
        } else {
            setUnits([])
            setConversions([])
            setTranslations([])
            setNewTranslation({
                title: '',
                language: null,
            })
        }
    }, [newIngredientData]);

    if (newIngredientLoading || languagesLoading) {
        return (
            <Card style={{ padding: '1em' }}>
                <Spinner color="primary" size="sm" />
            </Card>
        );
    }

    const selectedLanguages = translations.map(
        (translation) => translation.language.id
    );
    const languageOptions = languagesData.languages
        .filter(
            (lang) => !selectedLanguages.includes(lang.id)
        )
        .map((item) => ({
            ...item,
            label: item.title,
            value: item.id,
        }));

    return (
        <Card style={{ padding: '1em' }}>
            <Row>
                <Form>
                    <FormGroup>
                        <Label htmlFor="trending-group-title">
                            Názov
                        </Label>
                        <Table>
                            <thead>
                                <tr>

                                    <th>Jazyk</th>
                                    <th>Preklad</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr key={'new-translation'}>
                                    <td>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isSearchable={
                                                true
                                            }
                                            name="trending-group-recipes"
                                            options={
                                                languageOptions
                                            }
                                            value={
                                                newTranslation.language
                                            }
                                            onChange={(
                                                e
                                            ) => {
                                                setNewTranslation(
                                                    {
                                                        ...newTranslation,
                                                        language:
                                                            e,
                                                    }
                                                );
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <Input
                                            id="trending-group-title"
                                            value={
                                                newTranslation.title
                                            }
                                            onChange={(
                                                e
                                            ) => {
                                                setNewTranslation(
                                                    {
                                                        ...newTranslation,
                                                        title: e
                                                            .target
                                                            .value,
                                                    }
                                                );
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <Button
                                            color="primary"
                                            onClick={() => {
                                                setTranslations(
                                                    [
                                                        ...translations,
                                                        {
                                                            ...newTranslation,
                                                            id: --fakeID,
                                                        },
                                                    ]
                                                );
                                                setNewTranslation(
                                                    {
                                                        title: '',
                                                        language:
                                                            null,
                                                    }
                                                );
                                            }}
                                        >
                                            +
                                        </Button>
                                    </td>
                                </tr>
                                {translations.map(
                                    (translation) => (
                                        <tr
                                            key={
                                                translation.language.id
                                            }
                                        >
                                            <td>
                                                {
                                                    translation
                                                        .language
                                                        .title
                                                }
                                            </td>
                                            <td>
                                                <Input
                                                    id={"trending-group-title" + translation.language.id}
                                                    value={
                                                        translation.title
                                                    }
                                                    onChange={(
                                                        e
                                                    ) => {
                                                        setTranslations(
                                                            translations.map(
                                                                (
                                                                    t
                                                                ) =>
                                                                    t.language.id ===
                                                                        translation.language.id
                                                                        ? {
                                                                            ...translation,
                                                                            title: e
                                                                                .target
                                                                                .value,
                                                                        }
                                                                        : t
                                                            )
                                                        );
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <Button
                                                    color="link"
                                                    onClick={() => {
                                                        setTranslations(
                                                            translations.filter(
                                                                (
                                                                    t
                                                                ) =>
                                                                    t.id !==
                                                                    translation.id
                                                            )
                                                        );
                                                    }}
                                                >
                                                    X
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </Table>
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="trending-group-chefs">
                            Jednotky
                        </Label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={true}
                            isSearchable={true}
                            isMulti
                            name="trending-group-chefs"
                            options={UNITS}
                            value={units}
                            onChange={(e) => {
                                setUnits(e);
                                if (
                                    units.length > e.length
                                ) {
                                    const removedUnit =
                                        units.find(
                                            (unit) =>
                                                !e.find(
                                                    (u) =>
                                                        u.value ===
                                                        unit.value
                                                )
                                        ).value;
                                    let newConverstions =
                                        conversions.filter(
                                            (conversion) =>
                                                conversion.fromUnit !==
                                                removedUnit &&
                                                conversion.toUnit !==
                                                removedUnit
                                        );
                                    setConversions(
                                        newConverstions
                                    );
                                } else if (
                                    units.length < e.length
                                ) {
                                    const addedUnit =
                                        e.find(
                                            (unit) =>
                                                !units.find(
                                                    (u) =>
                                                        u.value ===
                                                        unit.value
                                                )
                                        ).value;
                                    let newConverstions = [
                                        ...conversions,
                                    ];
                                    for (
                                        let index = 0;
                                        index <
                                        units.length;
                                        index++
                                    ) {
                                        newConverstions.push(
                                            {
                                                fromUnit:
                                                    addedUnit,
                                                toUnit: units[
                                                    index
                                                ].value,
                                                multiplier: 1,
                                            }
                                        );
                                        setConversions(
                                            newConverstions.sort(
                                                (c1, c2) =>
                                                    `${c1.fromUnit}-to-${c1.toUnit}` <
                                                        `${c2.fromUnit}-to-${c2.toUnit}`
                                                        ? -1
                                                        : 1
                                            )
                                        );
                                    }
                                }
                            }}
                        />
                    </FormGroup>

                    {units.length >= 2 && (
                        <FormGroup>
                            <Label htmlFor="trending-group-title">
                                Prevody jednotiek
                            </Label>
                            <Table>
                                <thead>
                                    <th>Z jednotky</th>
                                    <th>Do jednotky</th>
                                    <th>Násobok</th>
                                </thead>
                                <tbody>
                                    {conversions.map(
                                        (conversion) => (
                                            <tr
                                                key={`${conversion.fromUnit}-to-${conversion.toUnit}`}
                                            >
                                                <td>
                                                    {
                                                        conversion.fromUnit
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        conversion.toUnit
                                                    }
                                                </td>
                                                <td>
                                                    <Input
                                                        id={`${conversion.fromUnit}-to-${conversion.toUnit}`}
                                                        value={
                                                            conversion.multiplier
                                                        }
                                                        type="number"
                                                        onChange={(
                                                            e
                                                        ) => {
                                                            setConversions(
                                                                conversions.map(
                                                                    (
                                                                        conv
                                                                    ) =>
                                                                        conv.fromUnit ===
                                                                            conversion.fromUnit &&
                                                                            conv.toUnit ===
                                                                            conversion.toUnit
                                                                            ? {
                                                                                ...conversion,
                                                                                multiplier:
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                            }
                                                                            : conv
                                                                )
                                                            );
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </Table>
                        </FormGroup>
                    )}
                </Form>
            </Row>

            <Row xs="2">
                <Col>
                    <Button
                        color="secondary"
                        block
                        onClick={onClose}
                    >
                        Zrušiť
                    </Button>
                </Col>
                <Col>
                    <Button
                        color="primary"
                        block
                        disabled={translations.length === 0}
                        onClick={() => {
                            onSave({
                                id: ingredientId,
                                units,
                                conversions,
                                translations,
                            });
                        }}
                    >
                        Uložiť
                    </Button>
                </Col>
            </Row>
        </Card>
    );
}
