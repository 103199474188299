import * as fns from 'date-fns';

export const getMilisecondsFromMidnight = (stringTime) => {
    if (!stringTime || stringTime.length === 0) {
        return 0;
    }

    let hours = parseInt(stringTime.substring(0, 2));
    let minutes = parseInt(stringTime.substring(3));
    return hours * 60 * 60 * 1000 + minutes * 60 * 1000;
};

export const getTimeFromMilisecondsFromMidnight = (
    miliseconds
) => {
    if (!miliseconds || miliseconds === 0) {
        return '';
    }
    let totalMinutes = miliseconds / 1000 / 60;
    let hours = ~~(totalMinutes / 60);
    let minutes = totalMinutes % 60;
    return `${hours < 10 ? '0' : ''}${hours}:${
        minutes < 10 ? '0' : ''
    }${minutes}`;
};

export const timestampToStringFNS = (date, format = '') => {
    if (!date) {
        return '';
    }
    if (
        typeof date === 'string' &&
        !isNaN(parseInt(date))
    ) {
        date = new Date(parseInt(date));
    } else if (
        typeof date === 'number' ||
        date === 'string'
    ) {
        date = new Date(date);
    }
    if (isNaN(date.valueOf())) {
        return 'Not a date';
    }
    if (format) {
        return fns.format(date, format);
    }
    return fns.format(date, 'HH:mm dd.MM.y');
};
