import { gql } from '@apollo/client';

export const DIETS_SUBSCRIPTION = gql`
    subscription dietsSubscription {
        dietsSubscription
    }
`;

export const DIETS = gql`
query diets($available: Boolean) {
    diets(available: $available) {
        available
        id
        order
        title
    }
}
`;

export const ADD_DIET = gql`
mutation addDiet(
    $available: Boolean, 
    $order: Int, 
    $title: String!
) {
  addDiet(
  available: $available, 
  order: $order, 
  title: $title
  ) {
    id
  }
}
`;

export const UPDATE_DIET = gql`
mutation updateDiet(
    $updateDietId: Int!, 
    $available: Boolean, 
    $title: String, 
    $order: Int
) {
  updateDiet(
  id: $updateDietId, 
  available: $available, 
  title: $title, 
  order: $order
  ) {
    id
  }
}
`;

export const DELETE_DIET = gql`
    mutation deleteDiet($deleteDietId: Int!) {
        deleteDiet(id: $deleteDietId)
    }
`;
