import React, { useEffect, useState } from 'react';
import {
    FormGroup,
    Label,
    Input,
    Form,
    Button,
    Card,
    Row,
    Col,
    Spinner,
    Table,
} from 'reactstrap';
import Select from 'react-select';
import { useQuery } from '@apollo/client';
import { GET_LANGUAGES } from 'queries';
import ImageInput from 'components/imageInput';

let fakeID = -1;

export default function ProductCategoryForm(props) {
    const {
        onClose,
        onSave,
        productCategory,
        parentProductCategory,
    } = props;

    const [order, setOrder] = useState([]);
    const [iconPath, setIconPath] = useState(null);
    const [image, setImage] = useState(null);
    const [translations, setTranslations] = useState([]);

    const [newTranslation, setNewTranslation] = useState({
        title: '',
        language: null,
    });

    const {
        data: languagesData,
        loading: languagesLoading,
    } = useQuery(GET_LANGUAGES, {
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (productCategory) {
            setOrder(
                productCategory.order
            );
            setIconPath(productCategory.iconPath);
            setTranslations(
                productCategory.translations
            );
        }
    }, [productCategory]);

    if (languagesLoading) {
        return (
            <Card style={{ padding: '1em' }}>
                <Spinner color="primary" size="sm" />
            </Card>
        );
    }

    const selectedLanguages = translations.map(
        (translation) => translation.language.id
    );
    
    const languageOptions = languagesData.languages
        .filter(
            (lang) => !selectedLanguages.includes(lang.id)
        )
        .map((item) => ({
            ...item,
            label: item.title,
            value: item.id,
        }));

    return (
        <Card style={{ padding: '1em' }}>
            <Row>
                <Form>
                    <ImageInput
                        iconPath={iconPath}
                        setIconPath={setIconPath}
                        image={image}
                        setImage={setImage}
                        size={'image'}
                    />
                    <FormGroup>
                        <Label htmlFor="product-category-title">
                            Názov
                        </Label>
                        <Table>
                            <thead>
                                <th>Jazyk</th>
                                <th>Preklad</th>
                                <th></th>
                            </thead>
                            <tbody>
                                <tr key={'new-translation'}>
                                    <td>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isSearchable={
                                                true
                                            }
                                            name="product-category-languages"
                                            options={
                                                languageOptions
                                            }
                                            value={
                                                newTranslation.language
                                            }
                                            onChange={(
                                                e
                                            ) => {
                                                setNewTranslation(
                                                    {
                                                        ...newTranslation,
                                                        language:
                                                            e,
                                                    }
                                                );
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <Input
                                            id="product-category-title"
                                            value={
                                                newTranslation.title
                                            }
                                            onChange={(
                                                e
                                            ) => {
                                                setNewTranslation(
                                                    {
                                                        ...newTranslation,
                                                        title: e
                                                            .target
                                                            .value,
                                                    }
                                                );
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <Button
                                            color="primary"
                                            onClick={() => {
                                                setTranslations(
                                                    [
                                                        ...translations,
                                                        {
                                                            ...newTranslation,
                                                            id: --fakeID,
                                                        },
                                                    ]
                                                );
                                                setNewTranslation(
                                                    {
                                                        title: '',
                                                        language:
                                                            null,
                                                    }
                                                );
                                            }}
                                        >
                                            +
                                        </Button>
                                    </td>
                                </tr>
                                {translations.map(
                                    (translation) => (
                                        <tr
                                            key={
                                                translation.language.id
                                            }
                                        >
                                            <td>
                                                {
                                                    translation
                                                        .language
                                                        .title
                                                }
                                            </td>
                                            <td>
                                                <Input
                                                    id="product-category-title"
                                                    value={
                                                        translation.title
                                                    }
                                                    onChange={(
                                                        e
                                                    ) => {
                                                        setTranslations(
                                                            translations.map(
                                                                (
                                                                    t
                                                                ) =>
                                                                    t.language.id ===
                                                                    translation.language.id
                                                                        ? {
                                                                              ...translation,
                                                                              title: e
                                                                                  .target
                                                                                  .value,
                                                                          }
                                                                        : t
                                                            )
                                                        );
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <Button
                                                    color="link"
                                                    onClick={() => {
                                                        setTranslations(
                                                            translations.filter(
                                                                (
                                                                    t
                                                                ) =>
                                                                    t.id !==
                                                                    translation.id
                                                            )
                                                        );
                                                    }}
                                                >
                                                    X
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </Table>
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="product-category-order">
                            Poradie
                        </Label>                        
                        <Input
                            id="product-category-order"
                            value={order}
                            type="number"
                            onChange={(e) => {
                                setOrder(e.target.value);
                            }}
                        />
                    </FormGroup>

                </Form>
            </Row>

            <Row xs="2">
                <Col>
                    <Button
                        color="secondary"
                        block
                        onClick={onClose}
                    >
                        Zrušiť
                    </Button>
                </Col>
                <Col>
                    <Button
                        color="primary"
                        block
                        disabled={translations.length === 0}
                        onClick={() => {
                            onSave({
                                id: productCategory ? productCategory.id : null,
                                order: parseInt(order),
                                icon: image,
                                superCategories: parentProductCategory ? [parentProductCategory] :  null,
                                translations,
                            });
                        }}
                    >
                        Uložiť
                    </Button>
                </Col>
            </Row>
        </Card>
    );
}
