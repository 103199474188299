import React, { useState, useRef, useEffect } from 'react';
import {
    FormFeedback,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';

export default function LocationInput(props) {
    const {
        location,
        setLocation,
        showLabel,
        showAddress,
    } = props;

    const uniqueID = 'x'
        .repeat(5)
        .replace(
            /./g,
            (c) =>
                'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'[
                    Math.floor(Math.random() * 62)
                ]
        );

    const [noStreetNumberError, setNoStreetNumberError] =
        useState(false);

    const autoCompleteRef = useRef();
    const inputRef = useRef();

    const options = {
        componentRestrictions: { country: 'sk' },
        fields: [
            'address_components',
            'geometry',
            'icon',
            'name',
        ],
    };

    useEffect(() => {
        autoCompleteRef.current =
            new window.google.maps.places.Autocomplete(
                inputRef.current,
                options
            );

        autoCompleteRef.current.addListener(
            'place_changed',
            async function () {
                const place =
                    await autoCompleteRef.current.getPlace();
                if (
                    !place.address_components[0].types.includes(
                        'street_number'
                    )
                ) {
                    setNoStreetNumberError(true);
                } else {
                    setNoStreetNumberError(false);
                    setLocation({
                        address: place.name,
                        latitude: parseFloat(
                            place.geometry.location.lat()
                        ),
                        longitude: parseFloat(
                            place.geometry.location.lng()
                        ),
                    });
                }
            }
        );
    }, []);

    return (
        <FormGroup>
            {showLabel && (
                <Label htmlFor={`address-${uniqueID}`}>
                    Adresa
                </Label>
            )}
            {showAddress && location.latitude && (
                <p>{`${location.address} ${location.latitude} ${location.longitude}`}</p>
            )}
            <input
                ref={inputRef}
                id={`address-${uniqueID}`}
                className="form-control"
                placeholder="Vyberte adresu"
            />

            {noStreetNumberError && (
                <>
                    <Input
                        invalid={noStreetNumberError}
                        style={{
                            display: 'none',
                        }}
                    />
                    <FormFeedback>
                        Vaša adresa musí obsahovať ulicu s
                        číslom.
                    </FormFeedback>
                </>
            )}
        </FormGroup>
    );
}
