import { gql } from '@apollo/client';

export const CATEGORIES_SUBSCRIPTION = gql`
    subscription productCategoriesSubscription {
        productCategoriesSubscription
    }
`;

export const CATEGORIES = gql`
    query productCategories($storeId: Int!) {
        productCategories(storeId: $storeId) {
            id
            order
            translations {
                id
                title
                language {
                    id
                    title
                    languageCode
                }
            }
        }
    }
`;

export const ADD_CATEGORY = gql`
    mutation addProductCategory(
        $order: Int!
        $translations: [ProductCategoryTranslationInput!]!
        $icon: String
        $subcategories: [Int!]
        $supercategories: [Int!]
    ) {
        addProductCategory(
            order: $order
            translations: $translations
            icon: $icon
            supercategories: $supercategories
            subcategories: $subcategories
        ) {
            id
        }
    }
`;

export const UPDATE_CATEGORY = gql`
    mutation updateProductCategory(
        $id: Int!
        $order: Int
        $translations: [ProductCategoryTranslationInput!]
        $icon: String
        $subcategories: [Int!]
        $supercategories: [Int!]
    ) {
        updateProductCategory(
            id: $id
            order: $order
            icon: $icon
            translations: $translations
            supercategories: $supercategories
            subcategories: $subcategories
        ) {
            id
        }
    }
`;

export const DELETE_CATEGORY = gql`
    mutation deleteProductCategory(
        $deleteProductCategoryId: Int!
        $newId: Int!
    ) {
        deleteProductCategory(
            id: $deleteProductCategoryId
            newId: $newId
        ) {
            id
        }
    }
`;
