import { setContext } from '@apollo/client/link/context';

import * as fns from 'date-fns';
import jwtDecode from 'jwt-decode';

import { setIsLoggedIn } from '../localSchema/actions';
import refreshToken from 'apollo/refreshToken';

const afterNow = (unix) => {
    return unix > fns.getUnixTime(new Date());
};

export const authLink = setContext(
    async (_, { headers }) => {
        let token = sessionStorage.getItem('acctok');
        if (!token) {
            return headers;
        }
        if (!afterNow(jwtDecode(token).exp)) {
            const { ok, accessToken } = (
                await refreshToken()
            ).data;
            if (ok) {
                token = accessToken;
                sessionStorage.setItem(
                    'acctok',
                    accessToken
                );
            } else {
                sessionStorage.removeItem('acctok');
                setIsLoggedIn(false);
            }
        }
        return {
            headers: {
                ...headers,
                authorization: token,
            },
        };
    }
);
