import React from 'react';
import { UPDATE_PROFILE, GET_LANGUAGES, LANGUAGES_SUBSCRIPTION } from 'queries';
import { useGetMyData } from 'utils';
import AdminForm from './form';
import { useQuery, useMutation, useSubscription } from '@apollo/client';

export default function AdminProfile(props) {
    const { onSave, onClose } = props;

    const currentAdmin = useGetMyData();

    const [updateProfile] = useMutation(UPDATE_PROFILE);

    const {
        data: languagesData,
        refetch: languagesRefetch,
    } = useQuery(GET_LANGUAGES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(LANGUAGES_SUBSCRIPTION, {
        onData: () => {
            languagesRefetch();
        },
    });

    const updateProfileFunc = (data) => {
        let dataToSave = {
            name: data.name,
            surname: data.surname,
            phoneNumber: data.phoneNumber,
            languageId: data.languageId,
        };
        if (data.icon) {
            dataToSave = {
                ...dataToSave,
                icon: data.icon,
            };
        }
        updateProfile({
            variables: dataToSave,
        }).then((response) => {
            onSave();
        });
    };

    const languageOptions = languagesData && languagesData.languages ?
        [...languagesData.languages]
            .sort((lang1, lang2) => lang1.languageCode > lang2.languageCode ? -1 : 1)
            .map((lang) => ({
                ...lang,
                value: lang.id,
                label: lang.languageCode
            })) : [];

    return (
        <AdminForm
            languages={languageOptions}
            adminId={currentAdmin.id}
            admin={currentAdmin}
            isProfile={true}
            onSave={updateProfileFunc}
            onClose={onClose}
        />
    );
}
