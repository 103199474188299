import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
    FormGroup,
    InputGroup,
    Label,
    Input,
    Form,
    Button,
    Card,
    Row,
    Col,
    Modal,
} from 'reactstrap';
import { ADMIN_CHANGE_EMAIL } from 'queries';
import { MaterialSymbol } from 'react-material-symbols';

export default function AdminChangeEmail() {
    const [adminChangeEmail] = useMutation(
        ADMIN_CHANGE_EMAIL
    );

    const isEmail = (email) =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            email
        );

    const [email, setEmail] = useState('');
    const [wrongEmailFormat, setWrongEmailFormat] =
        useState(false);

    const [password, setPassword] = useState('');
    const [passwordVisible, setPasswordVisible] =
        useState(false);

    const [modalOpen, setModalOpen] = useState(false);
    const [saveError, setSaveError] = useState(null);
    const [emailChanged, setEmailChanged] = useState(false);

    const toggle = () => {
        setModalOpen(!modalOpen);
    };

    const adminChangeEmailFunc = () => {
        adminChangeEmail({
            variables: {
                email,
                password,
            },
        })
            .then((response) => {
                setEmailChanged(true);
                setSaveError(null);
            })
            .catch((error) => {
                setSaveError(error.message);
            });
    };

    return (
        <>
            <Button
                color="link"
                onClick={() => {
                    setModalOpen(true);
                }}
            >
                Zmeniť email
            </Button>
            <Modal isOpen={modalOpen} toggle={toggle}>
                <Card style={{ padding: '1em' }}>
                    <Row>
                        <Form>
                            <FormGroup>
                                <Label htmlFor="admin-email">
                                    Email
                                </Label>
                                <Input
                                    id="admin-email"
                                    invalid={
                                        wrongEmailFormat
                                    }
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(
                                            e.target.value
                                        );
                                        setWrongEmailFormat(
                                            false
                                        );

                                        setEmailChanged(
                                            false
                                        );
                                        setSaveError(null);
                                    }}
                                    onBlur={() => {
                                        if (
                                            !isEmail(email)
                                        ) {
                                            setWrongEmailFormat(
                                                true
                                            );
                                        }
                                    }}
                                />
                            </FormGroup>

                            {wrongEmailFormat && (
                                <p>
                                    {
                                        'Email má nesprávny tvar!'
                                    }
                                </p>
                            )}

                            <FormGroup>
                                <Label htmlFor="admin-pass">
                                    Heslo
                                </Label>
                                <InputGroup>
                                    <Input
                                        id="admin-pass"
                                        value={password}
                                        type={
                                            passwordVisible
                                                ? 'text'
                                                : 'password'
                                        }
                                        onChange={(e) => {
                                            setPassword(
                                                e.target
                                                    .value
                                            );
                                            setEmailChanged(
                                                false
                                            );
                                            setSaveError(
                                                null
                                            );
                                        }}
                                    />
                                    <Button
                                        onClick={() => {
                                            setPasswordVisible(
                                                !passwordVisible
                                            );
                                        }}
                                    >
                                        <MaterialSymbol
                                            icon={
                                                passwordVisible
                                                    ? 'visibility_off'
                                                    : 'visibility'
                                            }
                                            size={24}
                                            fill
                                            grade={-25}
                                            color="white"
                                        />
                                    </Button>
                                </InputGroup>
                            </FormGroup>
                        </Form>
                    </Row>
                    {saveError && (
                        <Row>
                            <Col>
                                <p>{saveError}</p>
                            </Col>
                        </Row>
                    )}

                    {emailChanged && (
                        <Row>
                            <Col>
                                <p>
                                    Email bol úspešne
                                    zmenený!
                                </p>
                            </Col>
                        </Row>
                    )}

                    <Row xs="2">
                        <Col>
                            <Button
                                color="secondary"
                                block
                                onClick={() => {
                                    setEmail('');
                                    setWrongEmailFormat(
                                        false
                                    );
                                    setEmailChanged(false);
                                    setSaveError(null);
                                    setPasswordVisible(
                                        false
                                    );
                                    setModalOpen(false);
                                }}
                            >
                                Zavrieť
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                color="primary"
                                block
                                onClick={() => {
                                    adminChangeEmailFunc();
                                }}
                            >
                                Uložiť
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </Modal>
        </>
    );
}
