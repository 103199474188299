import React, { useState } from 'react';
import { Table, Button, Row, Col, Modal } from 'reactstrap';
import DietForm from './form';

export default function Diets(props) {
    const {
        diets,
        onCreateDiet,
        onUpdateDiet,
        onRemoveDiet,
    } = props;

    const [editDiet, setEditDiet] =
        useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const toggle = () => {
        setModalOpen(!modalOpen);
    };

    const sortedDiets = [...diets]
        .sort((i1, i2) => (i1.title < i2.title ? -1 : 1));

    return (
        <>
            <Table hover>
                <thead>
                    <tr>
                        <th width={'33%'}>Názov</th>
                        <th width={'33%'}>Poradie</th>
                        <th width={'33%'}>Akcia</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key="add-diet-btn">
                        <td
                            colSpan={3}
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={toggle}
                        >
                            + Diéta
                        </td>
                    </tr>
                    {sortedDiets.map((diet) => (
                        <tr
                            key={diet.id}
                            style={{
                                cursor: 'pointer',
                            }}
                        >
                            <td>{diet.title}</td>
                            <td>{diet.order}</td>

                            <td>
                                <Row xs="2">
                                    <Col>
                                        <Button
                                            color="warning"
                                            block
                                            onClick={() => {
                                                setEditDiet(
                                                    diet
                                                );
                                                toggle();
                                            }}
                                        >
                                            Upraviť
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button
                                            color="danger"
                                            block
                                            onClick={() => {
                                                if (
                                                    window.confirm(
                                                        'Ste si isty?'
                                                    )
                                                ) {
                                                    onRemoveDiet(
                                                        diet.id
                                                    );
                                                }
                                            }}
                                        >
                                            Odstrániť
                                        </Button>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Modal
                isOpen={modalOpen}
                toggle={() => {
                    if (modalOpen) {
                        setEditDiet(null);
                    }
                    toggle();
                }}
            >
                <DietForm
                    onClose={() => {
                        setEditDiet(null);
                        toggle();
                    }}
                    onSave={(data) => {
                        if (editDiet) {
                            onUpdateDiet(data);
                        } else {
                            onCreateDiet(data);
                        }
                        toggle();
                    }}
                    diet={editDiet}
                />
            </Modal>
        </>
    );
}
