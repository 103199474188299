import React, { useEffect, useState } from 'react';
import {
    FormGroup,
    Label,
    Input,
    Form,
    Button,
    Card,
    Row,
    Col,
} from 'reactstrap';

export default function DietForm(props) {
    const {
        onClose,
        onSave,
        diet,
    } = props;

    const [title, setTitle] = useState("");
    const [order, setOrder] = useState(0);
    const [available, setAvailable] = useState(true);

    useEffect(() => {
        if (diet) {
            setTitle(diet.title);
            setOrder(diet.order);
            setAvailable(diet.available);

        } else {
            setTitle("");
            setOrder(0);
            setAvailable(true);
        }
    }, [diet]);

    return (
        <Card style={{ padding: '1em' }}>
            <Row>
                <Form>
                    <FormGroup>
                        <Label htmlFor="diet_name">
                            Názov
                        </Label>
                        <Input
                            id={'diet_name'}
                            value={
                                title
                            }
                            onChange={(
                                e
                            ) => {
                                setTitle(e.target.value);
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="diet_order">
                            Poradie
                        </Label>
                        <Input
                            id={'diet_order'}
                            type='number'
                            value={
                                order
                            }
                            onChange={(
                                e
                            ) => {
                                setOrder(e.target.value);
                            }}
                        />
                    </FormGroup>

                    <FormGroup check>
                        <Input
                            id="diet_available"
                            type="checkbox"
                            checked={available}
                            onChange={() => {
                                setAvailable(!available)
                            }}
                        />
                        <Label
                            check
                            htmlFor="diet_available"
                        >
                            Zobrazovaný zákazníkom a kuchárom.
                        </Label>
                    </FormGroup>

                </Form>
            </Row>

            <Row xs="2">
                <Col>
                    <Button
                        color="secondary"
                        block
                        onClick={onClose}
                    >
                        Zrušiť
                    </Button>
                </Col>
                <Col>
                    <Button
                        color="primary"
                        block
                        disabled={title.length === 0}
                        onClick={() => {
                            onSave({
                                id: diet?.id,
                                title,
                                order: parseInt(order),
                                available,
                            });
                        }}
                    >
                        Uložiť
                    </Button>
                </Col>
            </Row>
        </Card>
    );
}
