import React, { useEffect } from 'react';
import {
    useMutation,
    useQuery,
    useSubscription,
} from '@apollo/client';
import Addresses from '../components/addresses';
import { useParams } from 'react-router-dom';
import { Col, Row, Spinner } from 'reactstrap';
import {
    ADDRESSES,
    ADDRESSES_SUBSCRIPTION,
    ADD_ADDRESS,
    UPDATE_ADDRESS,
    DELETE_ADDRESS,
} from 'queries';
import { useGetMyData } from 'utils';

export default function AddressesContainer() {
    const { customerID } = useParams();
    const currentAdmin = useGetMyData();

    const [addAddress] = useMutation(ADD_ADDRESS);
    const [updateAddress] = useMutation(UPDATE_ADDRESS);
    const [deleteAddress] = useMutation(DELETE_ADDRESS);

    const {
        data: addressesData,
        loading: addressesLoading,
        refetch: addressesRefetch,
    } = useQuery(ADDRESSES, {
        variables: {
            userId: parseInt(customerID),
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(ADDRESSES_SUBSCRIPTION, {
        onData: () => {
            addressesRefetch({
                userId: parseInt(customerID),
            });
        },
    });

    useEffect(() => {
        addressesRefetch({
            userId: parseInt(customerID),
        });
    }, [customerID]);

    const onCreateAddress = (data) => {
        addAddress({
            variables: {
                current: data.current,
                title: data.title,
                address: data.address,
                longitude: data.longitude,
                latitude: data.latitude,
                userId: parseInt(customerID),
            },
        });
    };

    const onUpdateAddress = (data) => {
        updateAddress({
            variables: {
                id: data.id,
                current: data.current,
                title: data.title,
                address: data.address,
                longitude: data.longitude,
                latitude: data.latitude,
            },
        });
    };
    const onRemoveAddress = (id, newId) => {
        deleteAddress({
            variables: {
                id: id,
            },
        });
    };

    if (addressesLoading) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    return (
        <Col>
            <Addresses
                addresses={
                    addressesData && addressesData.addresses
                        ? addressesData.addresses
                        : []
                }
                onCreateAddress={onCreateAddress}
                onUpdateAddress={onUpdateAddress}
                onRemoveAddress={onRemoveAddress}
                currentAdmin={currentAdmin}
                userId={parseInt(customerID)}
            />
        </Col>
    );
}
