import React, { useMemo, useState } from 'react';
import {
    useQuery,
    useSubscription,
} from '@apollo/client';
import { Col, Row, Spinner, FormGroup, Input, Label, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import Recipes from '../components/recipes';
import { useGetMyData } from 'utils';
import { useParams } from 'react-router-dom';
import Select from 'react-select';

import {
    GET_RECIPES,
    RECIPES_SUBSCRIPTION,
} from 'queries';
import { sortBasic, sortTitle, sortCreatedAt, sortUpdatedAt, sortStage } from 'configs/sortOptions';
import { getLabel } from 'utils';

const limit = 10;

export default function ChefRecipesContainer() {
    const { chefID } = useParams();

    const currentAdmin = useGetMyData();

    const [sortOption, setSortOption] = useState(sortBasic);
    const [page, setPage] = useState(0);
    const [visible, setVisible] = useState(true);

    const {
        data: recipesData,
        loading: recipesLoading,
        refetch: recipesRefetch,
    } = useQuery(GET_RECIPES, {
        variables: {
            visible,
            chefId: parseInt(chefID),
            offset: page * limit,
            limit,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(RECIPES_SUBSCRIPTION, {
        onData: () => {
            recipesRefetch({
                visible,
                chefId: parseInt(chefID),
                offset: page * limit,
                limit,
            });
        },
    });

    const sortedRecipes = useMemo(() => {
        return recipesData && recipesData.recipes && recipesData.recipes.recipes ? [...recipesData.recipes.recipes].sort((r1, r2) => {
            if (
                ['id', 'progress'].includes(sortOption.value)
            ) {
                return r1[
                    sortOption.value
                ] < r2[sortOption.value]
                    ? -1
                    : 1;
            }
            if (sortOption.value === "title") {
                return getLabel(r1.recipeTranslations, currentAdmin.siteLanguage).toLowerCase() <
                    getLabel(r2.recipeTranslations, currentAdmin.siteLanguage).toLowerCase()
                    ? -1
                    : 1;
            }
            return r1[
                sortOption.value
            ].toLowerCase() <
                r2[
                    sortOption.value
                ].toLowerCase()
                ? -1
                : 1;
        }) : [];
    }, [recipesData, sortOption]);


    const pages = useMemo(() => {
        if (!recipesData || !recipesData.recipes) {
            return []
        }
        let result = [];
        for (let index = 0; index < Math.ceil(recipesData.recipes.count / limit); index++) {
            result.push({
                label: index + 1,
                value: index,
            });
        }
        return result;
    }, [recipesData]);

    if (recipesLoading) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }



    return (
        <Col>
            <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={false}
                isSearchable={false}
                name="chef-sort"
                options={[sortBasic, sortTitle, sortCreatedAt, sortUpdatedAt, sortStage]}
                value={sortOption}
                onChange={(option) => {
                    setSortOption(option);
                }}
            />
            <FormGroup check>
                <Input
                    id="show_invisible_recipes"
                    type="checkbox"
                    checked={!visible}
                    onChange={() => {
                        setVisible(!visible);
                    }}
                />
                <Label
                    check
                    htmlFor="show_invisible_recipes"
                >
                    Ukázať schované recepty
                </Label>
            </FormGroup>
            <Recipes
                recipes={
                    sortedRecipes
                }
                chefID={currentAdmin.id}
                language={currentAdmin.siteLanguage}
                showVisible={!visible}
            />
            <Pagination>
                <PaginationItem>
                    <PaginationLink
                        first
                        onClick={() => { setPage(0) }}
                    />
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink
                        previous
                        onClick={() => { setPage(page - 1) }}
                    />
                </PaginationItem>

                {
                    pages.map((page) => (
                        <PaginationItem key={page.value} onClick={() => { setPage(page.value) }}>
                            <PaginationLink
                                tag={'button'}
                            >{page.label}</PaginationLink>
                        </PaginationItem>
                    ))
                }

                <PaginationItem>
                    <PaginationLink
                        onClick={() => { setPage(page + 1) }}
                        next
                    />
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink
                        last
                        onClick={() => { setPage(Math.ceil(recipesData.recipes.count / limit) - 1) }}
                    />
                </PaginationItem>
            </Pagination>
        </Col>
    );
}
