import React, { useState } from 'react';
import { Row, Col, Table, Spinner } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useQuery, useSubscription } from '@apollo/client';
import { STORES, STORES_SUBSCRIPTION } from 'queries';
import { MaterialSymbol } from 'react-material-symbols';
import Select from 'react-select';
import { sortBasic, sortTitle } from 'configs/sortOptions';

export default function ShopsTable() {
    const navigate = useNavigate();
    const [sortOption, setSortOption] = useState(sortBasic);

    const {
        data: storesData,
        loading: storesLoading,
        refetch: storesRefetch,
    } = useQuery(STORES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(STORES_SUBSCRIPTION, {
        onData: () => {
            storesRefetch();
        },
    });

    if (storesLoading) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    return (
        <Row
            style={{
                paddingTop: '1em',
            }}
        >
            <Col>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={false}
                    isSearchable={false}
                    name="store-sort"
                    options={[sortBasic, sortTitle]}
                    value={sortOption}
                    onChange={(option) => {
                        setSortOption(option);
                    }}
                />
                <Table hover>
                    <thead>
                        <tr>
                            <th width="10%">ID</th>
                            <th width="30%">Názov</th>
                            <th width="20%">Adresa</th>
                            <th width="20%">Kontakt</th>
                            <th
                                width="10%"
                                className="ta-center"
                            >
                                Aktívny
                            </th>
                            <th
                                width="10%"
                                className="ta-center"
                            >
                                Overený
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{ cursor: 'pointer' }}>
                            <th
                                colSpan={6}
                                onClick={() =>
                                    navigate('/stores/add')
                                }
                            >
                                + Obchod
                            </th>
                        </tr>
                        {[...storesData.stores]
                            .sort((s1, s2) => {
                                if (
                                    sortOption.value ===
                                    'id'
                                ) {
                                    return s1[
                                        sortOption.value
                                    ] < s2[sortOption.value]
                                        ? -1
                                        : 1;
                                }
                                return s1[
                                    sortOption.value
                                ].toLowerCase() <
                                    s2[
                                        sortOption.value
                                    ].toLowerCase()
                                    ? -1
                                    : 1;
                            })
                            .map((store) => (
                                <tr
                                    key={store.id}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                        navigate(
                                            `/stores/${store.id}/profile`
                                        )
                                    }
                                >
                                    <th scope="row">
                                        {store.id}
                                    </th>
                                    <td>{store.title}</td>
                                    <td>
                                        {
                                            store.location
                                                .address
                                        }
                                    </td>
                                    <td>{`${store.owner} (${store.ownerContact})`}</td>
                                    <td className="ta-center">
                                        <MaterialSymbol
                                            icon={
                                                store.active
                                                    ? 'check_circle'
                                                    : 'cancel'
                                            }
                                            size={24}
                                            grade={-25}
                                            color={
                                                store.active
                                                    ? 'green'
                                                    : 'red'
                                            }
                                        />
                                    </td>
                                    <td className="ta-center">
                                        <MaterialSymbol
                                            icon={
                                                store.verified
                                                    ? 'check_circle'
                                                    : 'cancel'
                                            }
                                            size={24}
                                            grade={-25}
                                            color={
                                                store.verified
                                                    ? 'green'
                                                    : 'red'
                                            }
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
}
