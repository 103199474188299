import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
    FormGroup,
    Label,
    Input,
    Button,
    InputGroup,
    Row,
    Col,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Modal,
    ModalHeader,
    ModalBody,
} from 'reactstrap';
import {
    NavLink as Link,
    useNavigate,
} from 'react-router-dom';
import Profile from '../components/profile';
import Addresses from '../components/addresses';
import { REGISTER_USER } from 'queries';
import { useGetMyData } from 'utils';
import { MaterialSymbol } from 'react-material-symbols';

const PROFILE = 'profile';
const ADDRESSES = 'addresses';

export default function UserAdd() {
    const navigate = useNavigate();

    const [registerUser] = useMutation(REGISTER_USER);

    const currentAdmin = useGetMyData();

    const isEmail = (email) =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            email
        );

    const [openTab, setOpenTab] = useState(PROFILE);

    const [modalOpen, setModalOpen] = useState(false);

    const [email, setEmail] = useState('');
    const [wrongEmailFormat, setWrongEmailFormat] =
        useState(false);

    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [passwordVisible, setPasswordVisible] =
        useState(false);
    const [password2Visible, setPassword2Visible] =
        useState(false);
    const [passwordError] = useState(false);

    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [siteLanguage, setSiteLanguage] = useState(null);
    const [
        notificationsThroughEmail,
        setNotificationsThroughEmail,
    ] = useState(true);
    const [
        notificationsThroughSms,
        setNotificationsThroughSms,
    ] = useState(true);
    const [image, setImage] = useState(null);

    const [addresses, setAddresses] = useState([]);

    useEffect(() => {
        let adminLanguage = [
            {
                value: 'sk',
                label: 'Slovak',
            },
            {
                value: 'cs',
                label: 'Czech',
            },
            {
                value: 'en',
                label: 'English',
            },
            {
                value: 'de',
                label: 'German',
            },
        ].find(
            (lang) =>
                currentAdmin.siteLanguage === lang.value
        );
        setSiteLanguage(
            adminLanguage
                ? {
                      ...adminLanguage,
                  }
                : {
                      value: 'sk',
                      label: 'Slovak',
                  }
        );
    }, [currentAdmin]);

    const toggle = () => {
        setModalOpen(!modalOpen);
    };

    const onCreateAddress = (data) => {
        setAddresses([...addresses, data]);
    };
    const onUpdateAddress = (updatedAddress) => {
        setAddresses(
            addresses.map((address) => {
                if (address.id === updatedAddress.id) {
                    return updatedAddress;
                }
                return address;
            })
        );
    };
    const onRemoveAddress = (id) => {
        setAddresses(
            addresses.filter((address) => address.id !== id)
        );
    };

    const registerUserFunc = () => {
        registerUser({
            variables: {
                email,
                password,
                name,
                surname,
                phoneNumber,
                siteLanguage: siteLanguage.value,
                notificationsThroughEmail,
                notificationsThroughSms,
                icon: image,
                addresses: addresses.map((address) => ({
                    current: address.current,
                    title: address.title,
                    address: address.address,
                    longitude: address.longitude,
                    latitude: address.latitude,
                })),
            },
        }).then((response) => {
            navigate(`/customers`);
        });
    };

    const cannotSave =
        name.length === 0 ||
        surname.length === 0 ||
        phoneNumber.length === 0 ||
        siteLanguage === null ||
        addresses.length === 0;

    return (
        <Row>
            <Col xs="2">
                <Nav tabs fill pills vertical>
                    <NavItem>
                        <NavLink
                            tag={Link}
                            to={`/customers`}
                            end
                        >
                            ← Naspäť na zoznam
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={
                                openTab === PROFILE
                                    ? 'active'
                                    : ''
                            }
                            onClick={() =>
                                setOpenTab(PROFILE)
                            }
                        >
                            Profil
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={
                                openTab === ADDRESSES
                                    ? 'active'
                                    : ''
                            }
                            onClick={() =>
                                setOpenTab(ADDRESSES)
                            }
                        >
                            Adresy
                        </NavLink>
                    </NavItem>
                </Nav>
            </Col>
            <Col>
                <TabContent activeTab={openTab}>
                    <TabPane tabId={PROFILE}>
                        <Profile
                            customerID={'add'}
                            name={name}
                            setName={setName}
                            surname={surname}
                            setSurname={setSurname}
                            phoneNumber={phoneNumber}
                            setPhoneNumber={setPhoneNumber}
                            siteLanguage={siteLanguage}
                            setSiteLanguage={
                                setSiteLanguage
                            }
                            notificationsThroughEmail={
                                notificationsThroughEmail
                            }
                            setNotificationsThroughEmail={
                                setNotificationsThroughEmail
                            }
                            notificationsThroughSms={
                                notificationsThroughSms
                            }
                            setNotificationsThroughSms={
                                setNotificationsThroughSms
                            }
                            image={image}
                            setImage={setImage}
                            addresses={addresses}
                            setAddresses={setAddresses}
                            siteLanguagesOptions={[
                                {
                                    value: 'sk',
                                    label: 'Slovak',
                                },
                                {
                                    value: 'cs',
                                    label: 'Czech',
                                },
                                {
                                    value: 'en',
                                    label: 'English',
                                },
                                {
                                    value: 'de',
                                    label: 'German',
                                },
                            ]}
                            currentAdmin={currentAdmin}
                        />
                    </TabPane>
                    <TabPane tabId={ADDRESSES}>
                        {
                            <Addresses
                                addresses={addresses}
                                onCreateAddress={
                                    onCreateAddress
                                }
                                onUpdateAddress={
                                    onUpdateAddress
                                }
                                onRemoveAddress={
                                    onRemoveAddress
                                }
                                currentAdmin={currentAdmin}
                            />
                        }
                    </TabPane>
                </TabContent>
                <Row>
                    <Col className="flex jc-flex-end">
                        <Button
                            color="danger"
                            disabled={cannotSave}
                            onClick={toggle}
                            style={{
                                marginBottom: '1em',
                            }}
                        >
                            Uložiť
                        </Button>
                    </Col>
                </Row>
                <Modal isOpen={modalOpen} toggle={toggle}>
                    <ModalHeader toggle={toggle}>
                        Prihlasovacie údaje
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label htmlFor="customer-email">
                                Email
                            </Label>
                            <Input
                                id="customer-email"
                                invalid={wrongEmailFormat}
                                value={email}
                                onChange={(e) => {
                                    setEmail(
                                        e.target.value
                                    );
                                    setWrongEmailFormat(
                                        false
                                    );
                                }}
                                onBlur={() => {
                                    if (!isEmail(email)) {
                                        setWrongEmailFormat(
                                            true
                                        );
                                    }
                                }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="customer-pass">
                                Heslo
                            </Label>
                            <InputGroup>
                                <Input
                                    id="customer-pass"
                                    invalid={passwordError}
                                    value={password}
                                    type={
                                        passwordVisible
                                            ? 'text'
                                            : 'password'
                                    }
                                    onChange={(e) => {
                                        setPassword(
                                            e.target.value
                                        );
                                    }}
                                />
                                <Button
                                    color="primary"
                                    className="flex"
                                    onClick={() => {
                                        setPasswordVisible(
                                            !passwordVisible
                                        );
                                    }}
                                >
                                    <MaterialSymbol
                                        icon={
                                            passwordVisible
                                                ? 'visibility_off'
                                                : 'visibility'
                                        }
                                        size={24}
                                        fill
                                        grade={-25}
                                        color="white"
                                    />
                                </Button>
                            </InputGroup>
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="customer-pass2">
                                Zopakujte heslo
                            </Label>
                            <InputGroup>
                                <Input
                                    id="customer-pass2"
                                    invalid={
                                        password.length >
                                            0 &&
                                        password2.length >
                                            0 &&
                                        password !==
                                            password2
                                    }
                                    value={password2}
                                    type={
                                        password2Visible
                                            ? 'text'
                                            : 'password'
                                    }
                                    onChange={(e) => {
                                        setPassword2(
                                            e.target.value
                                        );
                                    }}
                                />
                                <Button
                                    color="primary"
                                    className="flex"
                                    onClick={() => {
                                        setPassword2Visible(
                                            !password2Visible
                                        );
                                    }}
                                >
                                    <MaterialSymbol
                                        icon={
                                            password2Visible
                                                ? 'visibility_off'
                                                : 'visibility'
                                        }
                                        size={24}
                                        fill
                                        grade={-25}
                                        color="white"
                                    />
                                </Button>
                            </InputGroup>
                        </FormGroup>

                        <Row xs="2">
                            <Col>
                                <Button
                                    color="secondary"
                                    block
                                    onClick={toggle}
                                >
                                    Naspäť
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    color="primary"
                                    block
                                    onClick={
                                        registerUserFunc
                                    }
                                    disabled={
                                        !isEmail(email) ||
                                        (password.length >
                                            0 &&
                                            password2.length >
                                                0 &&
                                            password !==
                                                password2) ||
                                        password.length ===
                                            0 ||
                                        password2.length ===
                                            0
                                    }
                                >
                                    Uložiť
                                </Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </Col>
        </Row>
    );
}
