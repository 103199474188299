import { gql } from '@apollo/client';

export const ADMINS_SUBSCRIPTION = gql`
    subscription adminsSubscription {
        adminsSubscription
    }
`;

export const ADMINS = gql`
    query admins {
        admins {
            id
            email
            fullName
            settings
            language{
                id
                languageCode
            }
            active
            verified
        }
    }
`;

export const GET_ADMIN = gql`
    query admin($adminId: Int!) {
        admin(id: $adminId) {
            id
            active
            verified
            email
            name
            surname
            language{
                id
                languageCode
            }
            phoneNumber
            settings
            iconPath
        }
    }
`;

export const ADD_ADMIN = gql`
    mutation addAdmin(
        $active: Boolean!
        $email: String!
        $password: String!
        $name: String!
        $surname: String!
        $phoneNumber: String!
        $languageId: Int!
        $settings: Boolean
        $icon: String
    ) {
        addAdmin(
            active: $active
            email: $email
            password: $password
            name: $name
            surname: $surname
            phoneNumber: $phoneNumber
            languageId: $languageId
            settings: $settings
            icon: $icon
        ) {
            id
        }
    }
`;

export const UPDATE_ADMIN = gql`
    mutation updateAdmin(
        $id: Int!
        $active: Boolean
        $email: String
        $password: String
        $name: String
        $surname: String
        $phoneNumber: String
        $languageId: Int
        $settings: Boolean
        $icon: String
    ) {
        updateAdmin(
            id: $id
            active: $active
            email: $email
            password: $password
            name: $name
            surname: $surname
            phoneNumber: $phoneNumber
            languageId: $languageId
            settings: $settings
            icon: $icon
        ) {
            id
            language{
            id
            }
        }
    }
`;
