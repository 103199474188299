import React, { useState } from 'react';
import { Table, Modal, Button, Row, Col } from 'reactstrap';
import Form from './productsForm';
import EditForm from './productsEditForm';

export default function ShopProducts(props) {
    const {
        siteLanguage,
        categories,
        allIngredients,
        supportedLanguages,
        products,
        onCreateProduct,
        onUpdateProduct,
        onRemoveProduct,
        shopID,
    } = props;

    const [editProduct, setEditProduct] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const toggle = () => {
        setModalOpen(!modalOpen);
    };

    const getTitle = (product) => {
        return product.translations
            .map(
                (translation) =>
                    `${translation.title
                        ? translation.title
                        : 'Bez názvu'
                    } (${translation.language.title})`
            )
            .join(', ');
    };

    return (
        <>
            <Table hover>
                <thead>
                    <tr>
                        <th width={'33%'}>Názov</th>
                        <th width={'33%'}>Kategórie</th>
                        <th width={'33%'}>Akcia</th>
                    </tr>
                </thead>
                <tbody>
                    {supportedLanguages.length > 0 && (
                        <tr key="add-product-btn">
                            <td
                                colSpan={4}
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={toggle}
                            >
                                + Produkt
                            </td>
                        </tr>
                    )}

                    {products.map((product) => (
                        <tr
                            key={product.id}
                            style={{ cursor: 'pointer' }}
                        >
                            <td>
                                {
                                    getTitle(product)
                                }
                            </td>
                            <td>
                                {product.categories?.length > 0
                                    ? product.categories.map((category) => {
                                        return category.translations.find(
                                            (translation) =>
                                                translation
                                                    .language
                                                    .languageCode ===
                                                siteLanguage
                                        ).title
                                    }).join(', ')
                                    : 'Bez kategórií'}
                            </td>
                            <td>
                                <Row xs="2">
                                    <Col>
                                        <Button
                                            color="warning"
                                            block
                                            onClick={() => {
                                                setEditProduct(
                                                    product
                                                );
                                                toggle();
                                            }}
                                        >
                                            Upraviť
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button
                                            color="danger"
                                            block
                                            onClick={() => {
                                                if (
                                                    window.confirm(
                                                        'Ste si isty?'
                                                    )
                                                ) {
                                                    onRemoveProduct(
                                                        product.id
                                                    );
                                                }
                                            }}
                                        >
                                            Odstrániť
                                        </Button>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Modal
                isOpen={modalOpen}
                toggle={() => {
                    if (modalOpen) {
                        setEditProduct(null);
                    }
                    toggle();
                }}
            >
                {editProduct && (
                    <EditForm
                        supportedLanguages={
                            supportedLanguages
                        }
                        allCategories={categories}
                        allIngredients={allIngredients}
                        onClose={() => {
                            setEditProduct(null);
                            toggle();
                        }}
                        onSave={(data) => {
                            if (editProduct) {
                                onUpdateProduct(data);
                                toggle();
                            } else {
                                onCreateProduct(data);
                                toggle();
                            }
                        }}
                        productId={editProduct.id}
                        shopID={shopID}
                    />
                )}
                {!editProduct && (
                    <Form
                        supportedLanguages={
                            supportedLanguages
                        }
                        allCategories={categories}
                        allIngredients={allIngredients}
                        onClose={() => {
                            setEditProduct(null);
                            toggle();
                        }}
                        onSave={(data) => {
                            if (editProduct) {
                                onUpdateProduct(data);
                                toggle();
                            } else {
                                onCreateProduct(data);
                                toggle();
                            }
                        }}
                        shopID={shopID}
                    />
                )}
            </Modal>
        </>
    );
}
