import { gql } from '@apollo/client';

export const DISTANCE_PRICING_SUBSCRIPTION = gql`
    subscription distancePricingSubscription {
        distancePricingSubscription
    }
`;

export const GET_DISTANCE_PRICING = gql`
    query {
        distancePricing {
            km1
            km2
            km3
            km4
            km5
            km6
            km7
            km8
            km9
            km1Courier
            km2Courier
            km3Courier
            km4Courier
            km5Courier
            km6Courier
            km7Courier
            km8Courier
            km9Courier
        }
    }
`;

export const UPDATE_DISTANCE_PRICING = gql`
    mutation updateDistancePricing(
        $km1: Float
        $km2: Float
        $km3: Float
        $km4: Float
        $km5: Float
        $km6: Float
        $km7: Float
        $km8: Float
        $km9: Float
        $km1Courier: Float
        $km2Courier: Float
        $km3Courier: Float
        $km4Courier: Float
        $km5Courier: Float
        $km6Courier: Float
        $km7Courier: Float
        $km8Courier: Float
        $km9Courier: Float
    ) {
        updateDistancePricing(
            km1: $km1
            km2: $km2
            km3: $km3
            km4: $km4
            km5: $km5
            km6: $km6
            km7: $km7
            km8: $km8
            km9: $km9
            km1Courier: $km1Courier
            km2Courier: $km2Courier
            km3Courier: $km3Courier
            km4Courier: $km4Courier
            km5Courier: $km5Courier
            km6Courier: $km6Courier
            km7Courier: $km7Courier
            km8Courier: $km8Courier
            km9Courier: $km9Courier
        ) {
            km1
            km2
            km3
            km4
            km5
            km6
            km7
            km8
            km9
            km1Courier
            km2Courier
            km3Courier
            km4Courier
            km5Courier
            km6Courier
            km7Courier
            km8Courier
            km9Courier
        }
    }
`;
