export const getStageLabel = (stage) => {
    switch (stage) {
        case 0:
            return "Koncept 1/6: Médiá";
        case 1:
            return "Koncept 2/6: Recept";
        case 2:
            return "Koncept 3/6: Doplňujúce info";
        case 3:
            return "Koncept 4/6: Náhľad";
        case 4:
            return "Koncept 5/6: Spracúvanie tituliek";
        case 5:
            return "Koncept 6/6: Skontrolovať titulky";
        case 6:
            return "Publikovaný";
        default:
            return "Koncept ?/6";
    }
}
